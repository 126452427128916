import React, { Component } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Checkbox,
  Select,
  Tooltip,
  Radio,
  Spin,
} from "antd";
import { SvgIcon, Row, Col } from "../../../components/common";
import "./add-products.scss";

import ProductCard from "./product-card";

// import images

import {
  createLoadingSelector,
  resetStoreState,
  getItemFilters,
  getItemSearchResult,
  saveCollectionItems,
} from "../../../redux/action";
import { serverApis } from "../../../constants/server-apis";
import { connect } from "react-redux";
import { isObjectEmpty, isObjectNotEmpty } from "../../../common/utils";
import { loggedInUserDetail } from "../../../common/methods";
import { showAlert } from "../../../common/alert-messages";
import { baseServerAdress } from "../../../constants/server-address";

const { Option } = Select;

const filterKeys = {
  vendor: "vendors",
  categories: "catalog_item_categories",
};

class AddProductsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialRequestObjAndState(),
      visible: false,
    };
  }

  //formRef = React.createRef();

  initialRequestObjAndState = () => {
    return {
      requestObject: {
        is_dynamic_query: false,
        dynamic_query: {
          company_id: isObjectNotEmpty(loggedInUserDetail())
            ? loggedInUserDetail().company_id
            : "",
          price_from: "",
          price_to: "",
          vendors: "",
          catalog_item_categories: "",
          catalog_items: "",
        },
        collection_items: [],
      },
      alreadySelectedItems: [],
      checkedAlreadySelectedItems: [],
      checkedSearchResultItems: [],
    };
  };

  showModal = () => {
    this.props.getItemFilters(this.props.collectionId);

    this.setState({
      visible: true,
      collectionId: this.props.collectionId,
      ...this.initialRequestObjAndState(),
    });
  };

  handleChange = (value, category) => {
    let requestObject = this.state.requestObject;
    requestObject.dynamic_query[category] = value.join();
    this.setState({ requestObject });
  };

  handleOk = () => {
    // this.setState({ visible: false });
    this.resetAndClosePopup();
  };

  handleCancel = () => {
    this.resetAndClosePopup();
  };
  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.itemSelectedItems)) {
      let selectedItems = [];
      for (
        let index = 0;
        index < this.props.itemSelectedItems.length;
        index++
      ) {
        const item = this.props.itemSelectedItems[index];
        selectedItems.push({
          item_id: item.id,
          company_id: loggedInUserDetail().company_id,
        });
      }
      this.setState({
        checkedAlreadySelectedItems: selectedItems,
        alreadySelectedItems: [...this.props.itemSelectedItems],
      });
      this.props.resetStoreState(serverApis.getCollectionItems.actionName);
    }
    if (this.state.visible) {
      if (isObjectNotEmpty(this.props.saveCollectionItemsSuccessMessage)) {
        showAlert.success(this.props.saveCollectionItemsSuccessMessage);
        this.props.resetStoreState(serverApis.saveCollectionItems.actionName);
        this.resetAndClosePopup();
      }

      if (isObjectNotEmpty(this.props.itemsDynamicQuery)) {
        let query = this.props.itemsDynamicQuery;
        let requestObject = this.state.requestObject;
        requestObject.is_dynamic_query = true;
        requestObject.dynamic_query = query;

        this.setState({ requestObject });

        this.props.resetStoreState(serverApis.getCollectionItems.actionName);

        this.handleSearch();
      }
    }
  }
  onChange = (e) => {
    let requestObject = this.state.requestObject;
    let checkedAlreadySelectedItems = this.state.checkedAlreadySelectedItems;
    if (!requestObject.is_dynamic_query) {
      requestObject.collection_items = [];
      checkedAlreadySelectedItems = [];
    } else {
      let selectedItems = [];
      for (
        let index = 0;
        index < this.state.alreadySelectedItems.length;
        index++
      ) {
        const item = this.state.alreadySelectedItems[index];
        selectedItems.push({
          item_id: item.id,
          company_id: loggedInUserDetail().company_id,
        });
      }
      checkedAlreadySelectedItems = selectedItems;
    }
    requestObject.is_dynamic_query = !requestObject.is_dynamic_query;

    this.setState({
      requestObject,
      checkedAlreadySelectedItems,
    });
  };

  handleInputChange = (e) => {
    let requestObject = this.state.requestObject;
    requestObject.dynamic_query[e.target.id] = e.target.value;
    this.setState({ requestObject });
  };
  handleSearch = () => {
    let requestObject = this.state.requestObject;
    if (this.isZeroFiltersSelected()) {
      return;
    }

    this.props.resetStoreState(serverApis.getItemSearchResult.actionName);

    requestObject.collection_items = [];
    this.setState({ requestObject });
    this.props.getItemSearchResult(requestObject.dynamic_query);
  };
  onSelectAllSelectedItems = (e) => {
    let requestObject = this.state.requestObject;
    let selectedItems = [];
    if (e.target.checked) {
      for (
        let index = 0;
        index < this.state.alreadySelectedItems.length;
        index++
      ) {
        const item = this.state.alreadySelectedItems[index];
        selectedItems.push({
          item_id: item.id,
          company_id: loggedInUserDetail().company_id,
        });
      }
      requestObject.is_dynamic_query = false;
    }
    this.setState({
      requestObject,
      checkedAlreadySelectedItems: selectedItems,
    });
  };
  onSelectAllItemForSearchResults = (e) => {
    let requestObject = this.state.requestObject;
    let selectedItems = [];
    if (e.target.checked) {
      for (let index = 0; index < this.props.itemSearchResult.length; index++) {
        const item = this.props.itemSearchResult[index];
        selectedItems.push({
          item_id: item.id,
          company_id: loggedInUserDetail().company_id,
        });
      }
      requestObject.is_dynamic_query = false;
    }
    requestObject.collection_items = selectedItems;
    this.setState({ requestObject });
  };
  resetAndClosePopup = () => {
    this.setState({
      visible: false,
      ...this.initialRequestObjAndState(),
    });
    this.props.resetStoreState(serverApis.getItemFilters.actionName);
    this.props.resetStoreState(serverApis.getCollectionItems.actionName);
    this.props.resetStoreState(serverApis.getItemSearchResult.actionName);
  };
  onSelectItemSelectedItems = (checked, id) => {
    let requestObject = this.state.requestObject;
    let selectedItems = this.state.checkedAlreadySelectedItems;

    if (checked) {
      selectedItems.push({
        item_id: id,
        company_id: loggedInUserDetail().company_id,
      });
      requestObject.is_dynamic_query = false;
    } else {
      selectedItems = selectedItems.filter((item) => item.item_id !== id);
    }
    this.setState({
      requestObject,
      checkedAlreadySelectedItems: selectedItems,
    });
  };
  onSelectItemSearchResult = (checked, id) => {
    let requestObject = this.state.requestObject;
    let selectedItems = requestObject.collection_items;

    if (checked) {
      selectedItems.push({
        item_id: id,
        company_id: loggedInUserDetail().company_id,
      });
      requestObject.is_dynamic_query = false;
    } else {
      requestObject.collection_items = selectedItems.filter(
        (item) => item.item_id !== id
      );
    }
    this.setState({ requestObject });
  };

  isZeroFiltersSelected = () => {
    let requestObject = this.state.requestObject;

    if (
      isObjectEmpty(requestObject.dynamic_query.price_from) &&
      isObjectEmpty(requestObject.dynamic_query.price_to) &&
      isObjectEmpty(requestObject.dynamic_query.vendors) &&
      isObjectEmpty(requestObject.dynamic_query.catalog_item_categories) &&
      isObjectEmpty(requestObject.dynamic_query.catalog_items)
    ) {
      showAlert.error("Please select at least one filter option.");

      return true;
    } else {
      return false;
    }
  };

  saveCollectionItems = () => {
    let requestObject = this.state.requestObject;

    if (requestObject.is_dynamic_query) {
      if (this.isZeroFiltersSelected()) {
        return;
      }
    }

    requestObject.collection_items.push(
      ...this.state.checkedAlreadySelectedItems
    );

    this.props.saveCollectionItems(
      this.state.requestObject,
      this.props.collectionId
    );
  };

  render() {
    if (this.state.visible) {
      // console.log(userDetails);
      // console.log(loggedInUserDetail());
      // console.log(this.props.itemSelectedItems);
      // console.log(this.state.requestObject);
      // console.log(this.props.itemSearchResult);
    }
    const { visible } = this.state;
    if (this.state.visible) {
    }
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Tooltip
          overlayClassName="tooltip-cards"
          placement="bottomLeft"
          title="Manage Item"
        >
          <div
            onClick={this.showModal}
            key="add-item"
            className="cursor-pointer"
          >
            <SvgIcon width="20" name="item" viewbox="0 0 22.569 19.009" />
          </div>
        </Tooltip>
        <Modal
          visible={visible}
          title="Add Products"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          className="modal-cms add-product-modal"
          footer={false}
          width={1100}
        >
          {" "}
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="add-product-main">
              <Row>
                <Col sm="4" className="product-search">
                  <Form
                    initialValues={{ remember: false }}
                    onFinish={this.handleSearch}
                    layout="vertical"
                    // ref={this.formRef}
                  >
                    <Row className="mb-2">
                      <Col sm="12">
                        <Form.Item
                          className="mb-3"
                          id="catalog_item_categories"
                        >
                          <label htmlFor="product_category">
                            Product Category
                          </label>
                          <Select
                            mode="multiple"
                            placeholder="All Categories"
                            showArrow
                            allowClear
                            id="catalog_item_categories"
                            size="large"
                            onChange={(value) =>
                              this.handleChange(value, filterKeys.categories)
                            }
                            value={
                              isObjectEmpty(
                                this.state.requestObject.dynamic_query
                                  .catalog_item_categories
                              )
                                ? []
                                : this.state.requestObject.dynamic_query.catalog_item_categories.split(
                                    ","
                                  )
                            }
                            filterOption={(input, option) => {
                              return (
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.props.value
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {this.props.productFilters.categories.map(
                              (category, i) => (
                                <Option
                                  key={category.id}
                                  value={`${category.id}`}
                                >
                                  {category.code}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="6">
                        <Form.Item className="mb-3">
                          <label htmlFor="itemprice">Item Price</label>
                          <Input
                            id="price_from"
                            size="large"
                            placeholder="From"
                            value={
                              this.state.requestObject.dynamic_query.price_from
                            }
                            onChange={(value) => this.handleInputChange(value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm="6">
                        <label htmlFor="">&nbsp; </label>
                        <Form.Item className="mb-3">
                          <Input
                            id="price_to"
                            size="large"
                            placeholder="To"
                            value={
                              this.state.requestObject.dynamic_query.price_to
                            }
                            onChange={(value) => this.handleInputChange(value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="12">
                        <Form.Item className="mb-3">
                          <label htmlFor="vendors">Parent Vendor</label>
                          <Select
                            mode="multiple"
                            placeholder="All Vendors"
                            showArrow
                            allowClear
                            id="vendors"
                            size="large"
                            onChange={(value) =>
                              this.handleChange(value, filterKeys.vendor)
                            }
                            value={
                              isObjectEmpty(
                                this.state.requestObject.dynamic_query.vendors
                              )
                                ? []
                                : this.state.requestObject.dynamic_query.vendors.split(
                                    ","
                                  )
                            }
                            filterOption={(input, option) => {
                              return (
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.props.value
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {this.props.productFilters.vendors.map(
                              (vendor, i) => (
                                <Option key={i} value={`${vendor.id}`}>
                                  {vendor.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col sm="12">
                        <Form.Item className="mb-3">
                          <label htmlFor="catalog_items">Item #</label>
                          <Input
                            id="catalog_items"
                            size="large"
                            placeholder=""
                            onChange={(value) => this.handleInputChange(value)}
                            value={
                              this.state.requestObject.dynamic_query
                                .catalog_items
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Button htmlType="submit" type="primary" block>
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col sm="8" className="product-right-main">
                  <div className="product-right-inner">
                    {/* <div className="no-products">
                                        <div>
                                            <h1>Products will be shown here</h1>
                                        </div>
                                    </div> */}
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col sm="12">
                            <Radio.Group
                              onChange={this.onChange}
                              value={this.state.requestObject.is_dynamic_query}
                            >
                              <Radio value={true}>
                                Save dynamic query to fetch products for this
                                collection
                              </Radio>
                              <div className="my-2">OR</div>
                              <Radio className="mr-0" value={false}>
                                Select manually
                              </Radio>{" "}
                              {this.state.requestObject.collection_items
                                .length +
                                this.state.checkedAlreadySelectedItems.length >
                              0
                                ? `(${
                                    this.state.requestObject.collection_items
                                      .length +
                                    this.state.checkedAlreadySelectedItems
                                      .length
                                  } item${
                                    this.state.requestObject.collection_items
                                      .length +
                                      this.state.checkedAlreadySelectedItems
                                        .length ===
                                    1
                                      ? ""
                                      : "s"
                                  } selected)`
                                : ""}
                            </Radio.Group>
                          </Col>
                        </Row>
                        {isObjectNotEmpty(this.state.alreadySelectedItems) && (
                          <Row>
                            <Col sm="12">
                              <Checkbox
                                className="mt-n3"
                                style={{ float: "right" }}
                                onChange={(value) =>
                                  this.onSelectAllSelectedItems(value)
                                }
                                checked={
                                  !this.state.requestObject.is_dynamic_query &&
                                  this.state.alreadySelectedItems.length ===
                                    this.state.checkedAlreadySelectedItems
                                      .length &&
                                  isObjectNotEmpty(
                                    this.state.alreadySelectedItems
                                  )
                                }
                              >
                                Select All Items
                              </Checkbox>
                            </Col>
                          </Row>
                        )}
                        <Row className="product-lists mt-3">
                          {isObjectNotEmpty(
                            this.state.alreadySelectedItems
                          ) && (
                            <Col sm="12">
                              <h3>Selected Items</h3>
                            </Col>
                          )}
                          {this.state.alreadySelectedItems.map((item, i) => {
                            return (
                              <ProductCard
                                key={i}
                                isChecked={
                                  this.state.checkedAlreadySelectedItems.filter(
                                    (element) => element.item_id === item.id
                                  ).length !== 0
                                }
                                onSelectItem={this.onSelectItemSelectedItems}
                                prdoductImg={
                                  baseServerAdress.ITEM_IMAGE +
                                  item.image_thumnail
                                }
                                prdoductName={item.name}
                                productCode={item.web_code}
                                id={item.web_code}
                                productKey={item.id}
                              />
                            );
                          })}
                          <Col sm="6">
                            <h3>Search Results</h3>
                          </Col>{" "}
                          <Col sm="6">
                            <Checkbox
                              style={{ float: "right" }}
                              onChange={(value) =>
                                this.onSelectAllItemForSearchResults(value)
                              }
                              checked={
                                !this.state.requestObject.is_dynamic_query &&
                                this.props.itemSearchResult.length ===
                                  this.state.requestObject.collection_items
                                    .length &&
                                isObjectNotEmpty(
                                  this.state.requestObject.collection_items
                                )
                              }
                            >
                              Select All Items
                            </Checkbox>
                          </Col>
                          {this.props.itemSearchResult.map((item, i) => {
                            return (
                              <ProductCard
                                key={i}
                                isChecked={
                                  this.state.requestObject.collection_items.filter(
                                    (element) => element.item_id === item.id
                                  ).length !== 0
                                }
                                onSelectItem={this.onSelectItemSearchResult}
                                prdoductImg={
                                  baseServerAdress.ITEM_IMAGE +
                                  item.image_thumnail
                                }
                                prdoductName={item.name}
                                productCode={item.web_code}
                                id={item.web_code}
                                productKey={item.id}
                              />
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="product-right-action text-right">
                    <Button className="btn-md" onClick={this.handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      className="px-5 ml-3 btn-md"
                      onClick={this.saveCollectionItems}
                    >
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getItemFilters.actionName,
  serverApis.getCollectionItems.actionName,
  serverApis.getItemSearchResult.actionName,
  serverApis.saveCollectionItems.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  productFilters: state.productFilters,
  itemSearchResult: state.itemSearchResult,
  itemSelectedItems: state.itemSelectedItems,
  itemsDynamicQuery: state.itemsDynamicQuery,
  saveCollectionItemsSuccessMessage: state.saveCollectionItemsSuccessMessage,
});

export default connect(mapStateToProps, {
  getItemFilters,
  getItemSearchResult,
  resetStoreState,
  saveCollectionItems,
})(AddProductsModal);
