import React, { Component } from "react";
import { Modal, Button, Form, Input, Tooltip, Spin } from "antd";
import {
  SvgIcon,
  ImageLoader,
  RecommendedSize,
} from "../../../components/common";

import "./edit-collection.scss";

import { connect } from "react-redux";
import {
  createLoadingSelector,
  resetStoreState,
  updateCollection,
} from "../../../redux/action";
import { serverApis } from "../../../constants/server-apis";
import { isObjectNotEmpty } from "../../../common/utils";
import { showAlert } from "../../../common/alert-messages";
import { baseServerAdress } from "../../../constants/server-address";

const deleteImageStates = {
  delete_cover_image: false,
  delete_thumb_image: false,
  cover_image_file: null,
  thumb_image_file: null,
};

class EditCollectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      requestObject: { ...this.props.collection },
      file: null,
      file1: null,
    };
    this.onChangecover = this.onChangecover.bind(this);
    this.coverresetFile = this.coverresetFile.bind(this);

    this.onChangethumb = this.onChangethumb.bind(this);
    this.thumbresetFile = this.thumbresetFile.bind(this);
  }

  onChangecover(event) {
    let requestObject = this.state.requestObject;
    requestObject.cover_image_file = event.target.files[0];
    requestObject.delete_cover_image = false;

    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
      requestObject,
    });
  }

  coverresetFile(event) {
    event.preventDefault();
    let requestObject = this.state.requestObject;

    if (
      this.state.file === null &&
      isObjectNotEmpty(this.props.collection.cover_image)
    ) {
      requestObject.delete_cover_image = true;
    } else {
      requestObject.delete_cover_image = false;
    }

    requestObject.cover_image_file = null;
    this.setState({ file: null, requestObject });

    event.target.value = null;
  }

  onChangethumb(event) {
    let requestObject = this.state.requestObject;
    requestObject.thumb_image_file = event.target.files[0];
    requestObject.delete_thumb_image = false;

    this.setState({
      file1: URL.createObjectURL(event.target.files[0]),
      requestObject,
    });

    event.target.value = null;
  }

  thumbresetFile(event) {
    event.preventDefault();
    let requestObject = this.state.requestObject;

    if (
      this.state.file1 === null &&
      isObjectNotEmpty(this.props.collection.thumb_image)
    ) {
      requestObject.delete_thumb_image = true;
    } else {
      requestObject.delete_thumb_image = false;
    }

    requestObject.thumb_image_file = null;
    this.setState({ file1: null, requestObject });
  }

  showModal = () => {
    this.setState({
      visible: true,
      requestObject: { ...this.props.collection, ...deleteImageStates },
      file: null,
      file1: null,
    });
  };

  handleCancel = (event) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState({
      visible: false,
      requestObject: { ...this.props.collection, ...deleteImageStates },
      file: null,
      file1: null,
    });
  };

  onTextChange = (event) => {
    let requestObject = this.state.requestObject;
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value; //field value

    requestObject[name] = value;
    this.setState({ requestObject });
  };

  onFinish = () => {
    //showAlert.success("onFinish");
    this.props.updateCollection(this.state.requestObject);
  };

  componentDidUpdate() {
    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.updateCollectionSuccessMessage)
    ) {
      showAlert.success(this.props.updateCollectionSuccessMessage);
      this.props.resetStoreState(serverApis.updateCollection.actionName);
      this.handleCancel();

      this.props.refreshCollections();
    }
  }

  render() {
    const { visible } = this.state;

    const formId = this.state.requestObject.id + "a";

    return (
      <React.Fragment>
        <Tooltip
          overlayClassName="tooltip-cards"
          placement="bottomLeft"
          title="Edit Collection"
        >
          <div
            key="manageitems"
            onClick={(event) => {
              event.stopPropagation();
              this.showModal();
            }}
            className="cursor-pointer"
          >
            <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />
          </div>
        </Tooltip>
        <Modal
          visible={visible}
          title="Edit Collection"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <Button
              key="1"
              form={formId}
              type="primary"
              htmlType="submit"
              onClick={(event) => {
                event.stopPropagation();
                // this.onFinish();
              }}
              block
            >
              Update Collection
            </Button>,
          ]}
          className="modal-cms"
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div
              className="create-collection-modal"
              onClick={(event) => event.stopPropagation()}
            >
              <Form
                id={formId}
                initialValues={{ ...this.props.collection }}
                onFinish={this.onFinish}
                layout="vertical"
                //ref={this.formRef}
              >
                <Form.Item
                  className="mb-3"
                  label="Collection Name"
                  id="name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter collection name!",
                    },
                  ]}
                >
                  <Input
                    name="name"
                    size="large"
                    placeholder="Enter Collection Name"
                    value={this.state.requestObject.name}
                    onChange={this.onTextChange}
                  />
                </Form.Item>
                <label htmlFor="collection_url">Collection URL</label>
                <div className="d-flex custom-url align-items-center mb-3 w-100">
                  <div className="url">www.tekweld.com/</div>
                  <div className="sub-url w-100">
                    <Form.Item
                      className="m-0"
                      id="collection_url"
                      name="collection_url"
                    >
                      <Input
                        name="collection_url"
                        size="large"
                        placeholder="Enter Collection URL"
                        value={this.state.requestObject.collection_url}
                        onChange={this.onTextChange.bind(this)}
                      />
                    </Form.Item>
                  </div>
                </div>
                {/* <div className="d-flex">
                  <Upload
                    listType="picture"
                    beforeUpload={this.handleUploadCoverImage.bind(this)}
                    multiple={false}
                  >
                    <Button
                      className="d-flex align-items-center px-2 text-inherit mr-3"
                      size="small"
                      type="primary"
                      icon={
                        <SvgIcon
                          className="mr-2"
                          fill="#fff"
                          name="picture"
                          viewbox="0 0 28 28"
                        />
                      }
                    >
                      Upload Cover Image
                    </Button>
                  </Upload>
                  <Upload
                    listType="picture"
                    beforeUpload={this.handleUploadThumbnailImage.bind(this)}
                  >
                    <Button
                      className="btn-success d-flex align-items-center px-2 text-inherit"
                      size="small"
                      icon={
                        <SvgIcon
                          className="mr-2"
                          fill="#fff"
                          name="picture"
                          viewbox="0 0 28 28"
                        />
                      }
                    >
                      Upload Thumbnail
                    </Button>
                  </Upload>
                </div>
                <div className="mt-3 d-flex">
                  <div className="imgthumbnail-big">
                    <ImageLoader
                      className="editcollection-thumbs"
                      src={
                        baseServerAdress.COLLECTION_IMAGE +
                        this.props.collection.cover_image +
                        `?${Date()}`
                      }
                    />
                    <div className="editthumblink"><SvgIcon name="pencil" viewbox="0 0 16.19 16.19" /> Change Thumbnail</div>
                  </div>
                  <div className="imgthumbnail-small">
                    <ImageLoader
                      className="editcollection-thumbs"
                      src={
                        baseServerAdress.COLLECTION_IMAGE +
                        this.props.collection.thumb_image +
                        `?${Date()}`
                      }
                    />
                    <div className="editthumblink"><SvgIcon name="pencil" viewbox="0 0 16.19 16.19" /> Change Thumbnail</div>
                  </div>
                </div> */}

                <div className="mt-4 d-flex">
                  <div className="imgthumbnail-big">
                    <div className="imgthumbnail-big-inner">
                      {(this.state.file ||
                        (isObjectNotEmpty(this.props.collection.cover_image) &&
                          !this.state.requestObject.delete_cover_image)) && (
                        <div className="removethumb-coverimg">
                          <div
                            className="close-icon"
                            onClick={this.coverresetFile}
                          >
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </div>
                      )}
                      <div className="uploadcoverthumbnail">
                        <div className="uploadcoverthumbnail-link">
                          <input type="file" onChange={this.onChangecover} />
                          <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Add
                          Cover Image
                        </div>
                      </div>
                      {(this.state.file ||
                        (isObjectNotEmpty(this.props.collection.cover_image) &&
                          !this.state.requestObject.delete_cover_image)) && (
                        <div>
                          <div className="editcover-img">
                            <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />{" "}
                            Change Cover Image
                            <input type="file" onChange={this.onChangecover} />
                          </div>
                        </div>
                      )}
                      {(this.state.file ||
                        (isObjectNotEmpty(this.props.collection.cover_image) &&
                          !this.state.requestObject.delete_cover_image)) && (
                        <div className="uploadedthumbimg-wrape">
                          <ImageLoader
                            className="uploadedthumbimg-big"
                            alt={"Cover"}
                            src={
                              this.state.file
                                ? this.state.file
                                : baseServerAdress.COLLECTION_IMAGE +
                                  this.props.collection.cover_image +
                                  `?${Date()}`
                            }
                          />
                        </div>
                      )}
                    </div>
                    <RecommendedSize width="800" height="800" />
                  </div>

                  <div className="imgthumbnail-small mb-3">
                    <div className="imgthumbnail-big-inner">
                      {(this.state.file1 ||
                        (isObjectNotEmpty(this.props.collection.thumb_image) &&
                          !this.state.requestObject.delete_thumb_image)) && (
                        <div className="removethumb-coverimg">
                          <div
                            className="close-icon"
                            onClick={this.thumbresetFile}
                          >
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </div>
                      )}
                      <div className="uploadcoverthumbnail">
                        <div className="uploadcoverthumbnail-link">
                          <input type="file" onChange={this.onChangethumb} />
                          <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Add
                          Thumbnail
                        </div>
                      </div>
                      {(this.state.file1 ||
                        (isObjectNotEmpty(this.props.collection.thumb_image) &&
                          !this.state.requestObject.delete_thumb_image)) && (
                        <div>
                          <div className="editcover-img">
                            <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />{" "}
                            Change Thumbnail
                            <input type="file" onChange={this.onChangethumb} />
                          </div>
                        </div>
                      )}
                      {(this.state.file1 ||
                        (isObjectNotEmpty(this.props.collection.thumb_image) &&
                          !this.state.requestObject.delete_thumb_image)) && (
                        <div className="uploadedthumbimg-wrape">
                          <ImageLoader
                            alt={"Thumbnail"}
                            className="uploadedthumbimg-big"
                            src={
                              this.state.file1
                                ? this.state.file1
                                : baseServerAdress.COLLECTION_IMAGE +
                                  this.props.collection.thumb_image +
                                  `?${Date()}`
                            }
                          />
                        </div>
                      )}
                    </div>
                    <RecommendedSize width="800" height="800" />
                  </div>
                </div>
              </Form>
            </div>
          </Spin>{" "}
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.updateCollection.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  updateCollectionSuccessMessage: state.updateCollectionSuccessMessage,
});

export default connect(mapStateToProps, {
  updateCollection,
  resetStoreState,
})(EditCollectionModal);
