import {
  dispatchWithResponseAndType,
  getResetAction,
} from "../../constants/actions-methods";
import _ from "lodash";
import { baseServerAdress } from "../../constants/server-address";
import {
  saveLoginToken,
  clearLoginData,
  getJsonRequestHeader,
  getJsonRequestHeaderWithAuthentication,
  saveLoggedInUserDetails,
  getLoginToken,
  getRequestHeaderFormData,
} from "../../common/methods";
import { serverApis } from "../../constants/server-apis";
import { requestMethodType, requestCreatorKey } from "../../constants";
import { showAlert } from "../../common/alert-messages";
import { getActionNameWithStatus } from "../../constants/actions-methods";
import { requestStatus, resetActions } from "../../constants/request";
import history from "../../common/history";
import { isObjectNotEmpty } from "../../common/utils";
import { serialize } from "object-to-formdata";

const SERVER_ADDRESS = baseServerAdress.API;
export const ORDERS_PAGE_SIZE = 25;
const REQUEST_TIMEOUT = 120000;

const formDataSerializeOptions = {
  /**
   * include array indices in FormData keys
   * defaults to false
   */
  indices: false,

  /**
   * treat null values like undefined values and ignore them
   * defaults to false
   */
  nullsAsUndefineds: false,

  /**
   * convert true or false to 1 or 0 respectively
   * defaults to false
   */
  booleansAsIntegers: false,

  /**
   * store arrays even if they're empty
   * defaults to false
   */
  allowEmptyArrays: false,
};

//////////////  COMMON ACTIONS START ////////////////////

export function resetStoreState(stateName) {
  return async (dispatch) => {
    dispatch(dispatchWithResponseAndType(null, getResetAction(stateName)));
  };
}

export const createErrorMessageSelector = (actions) => (state) => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  return (
    _(actions)
      .map((action) => _.get(state, `errorMessages.${action}`))
      .compact()
      .first() || ""
  );
};

export const createLoadingSelector = (actions) => (state) => {
  // returns true only when all actions is not loading
  return _(actions).some((action) => {
    return _.get(state, `loadingApis.${action}`);
  });
};

export async function requestCreator(requestObject, dispatch) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);
  let responseData;
  dispatch(
    dispatchWithResponseAndType(
      null,
      getActionNameWithStatus(requestObject.actionType, requestStatus.REQUEST)
    )
  );
  let responseJson = await fetch(requestObject.requestURL, {
    method: requestObject.requestMethod,
    headers: requestObject.requestHeader,
    body: requestObject.requestPayload,
    signal: controller.signal,
  })
    .then((data) => {
      clearTimeout(timeoutId);
      responseData = data;
      return data.json();
    })
    .catch((error) => {
      if (error.name === "AbortError") {
        responseData = { status: 1001 }; ///server request time out error
      } else {
        responseData = {
          status: 1000, /////other errors
          error: error.toString(),
        };
      }
    });

  return { responseData, responseJson };
}

export async function actionCreator(requestObject, dispatch) {
  // if (requestObject.actionType !== Type.DASHBOARD_DETAILS) {
  //   dispatch(getDashboardDetails());
  // }
  const { responseData, responseJson } = await requestCreator(
    requestObject,
    dispatch
  );
  checkResponseStatusAndDispatchAction(
    dispatch,
    responseData,
    responseJson,
    requestObject.actionType
  );
}

export async function actionCreatorForPayment(requestObject, dispatch) {
  const responseObj = await requestCreator(requestObject, dispatch);
  const responseStatus = checkPaymentResponseStatusAndDispatchAction(
    dispatch,
    responseObj.showErrorMessageFromTransaction,
    responseObj.responseJson,
    requestObject.actionType
  );
  const response = responseObj.responseJson;
  return { responseStatus, response };
}

function checkPaymentResponseStatusAndDispatchAction(
  dispatch,
  showErrorMessageFromTransaction,
  response,
  actionType
) {
  if (response.messages.resultCode === "Ok") {
    return true;
  } else {
    if (showErrorMessageFromTransaction) {
      if (response.transactionResponse.errors.length > 0) {
        response.transactionResponse.errors.forEach((error) => {
          showAlert.error(error.errorText);
        });
      }
    } else {
      if (response.messages.message.length > 0) {
        response.messages.message.forEach((error) => {
          showAlert.error(error.text);
        });
      }
    }
    dispatch(
      dispatchWithResponseAndType(
        "",
        getActionNameWithStatus(actionType, requestStatus.FAILURE)
      )
    );
    return false;
  }
}

function checkResponseStatusAndDispatchAction(
  dispatch,
  data,
  response,
  actionType
) {
  let errorMessage;
  switch (data.status) {
    case 200:
      // if (actionType === Type.LOGIN) {
      //   history.push(componentPaths.dashboard);
      // }
      if (response) {
        // && response.message.toLowerCase() === "success") {
        dispatch(
          dispatchWithResponseAndType(
            response,
            getActionNameWithStatus(actionType, requestStatus.SUCCESS)
          )
        );
        return;
      } else {
        errorMessage = response.error;
      }

      break;
    case 422: //,400,404 405 422 authentication failed
      errorMessage = response.error;
      dispatch(
        dispatchWithResponseAndType(
          errorMessage,
          getActionNameWithStatus(actionType, requestStatus.FAILURE)
        )
      );
      if (history.location.pathname !== serverApis.login.componentPath) {
        showAlert.error(errorMessage);

        history.push({
          pathname: serverApis.login.componentPath,
          state: { NAVIGATION_REFERENCE: history.location.pathname },
        });
      }
      // history.push(componentPaths.login);
      return;
    // case 400:
    // case 405:
    // case 404:
    //   errorMessage = response.error;
    //   break;
    case 1000:
      errorMessage = data.error; //// error code
      break;

    case 1001:
      errorMessage = "Request Time Out!"; ///  request time out error code
      break;

    default:
      errorMessage = response ? response.error : data.statusText;
  }
  showAlert.error(errorMessage);

  dispatch(
    dispatchWithResponseAndType(
      errorMessage,
      getActionNameWithStatus(actionType, requestStatus.FAILURE)
    )
  );
}

//////////////  COMMON ACTIONS ENDS ////////////////////

export function loginAction(email, password) {
  return async (dispatch) => {
    let actionCreatorObject = {
      [requestCreatorKey.REQUEST_ACTION_TYPE]: serverApis.login.actionName,
      [requestCreatorKey.REQUEST_URL]:
        SERVER_ADDRESS + serverApis.login.serverUrl,
      [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
      [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
        user: {
          login: email,
          password: password,
          cms_user: true,
        },
      }),
      [requestCreatorKey.REQUEST_HEADER]: {
        ...getJsonRequestHeader(),
      },
    };
    const { responseData, responseJson } = await requestCreator(
      actionCreatorObject,
      dispatch
    );
    if (responseData.status === 200) {
      saveLoginToken(responseJson.data.auth_token);
      saveLoggedInUserDetails(responseJson.data.users);
    }

    checkResponseStatusAndDispatchAction(
      dispatch,
      responseData,
      responseJson,
      serverApis.login.actionName
    );
  };
}

export function getRootCollections() {
  const apiInfo = serverApis.rootCollections;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function deleteCollection(collectionId) {
  const apiInfo = serverApis.deleteCollection;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(collectionId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getSubCollections(parentId) {
  const apiInfo = serverApis.getSubCollections;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(parentId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getSubChildCollections(parentId) {
  const apiInfo = serverApis.getSubChildCollections;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(parentId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function createCollection(collection, parentId) {
  var apiInfo = serverApis.createCollection;

  let data = new FormData();
  data.append("name", collection.name);

  if (collection.cover_image_file !== null) {
    data.append("cover_image_file", collection.cover_image_file);
  }

  if (collection.thumb_image_file !== null) {
    data.append("thumb_image_file", collection.thumb_image_file);
  }

  if (isObjectNotEmpty(collection.collection_url)) {
    data.append("collection_url", collection.collection_url);
  }

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(parentId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
  };

  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function updateCollection(collection) {
  var apiInfo = serverApis.updateCollection;

  let data = new FormData();
  data.append("name", collection.name);

  data.append("delete_cover_image", collection.delete_cover_image);
  data.append("delete_thumb_image", collection.delete_thumb_image);

  if (collection.cover_image_file !== null) {
    data.append("cover_image_file", collection.cover_image_file);
  }

  if (collection.thumb_image_file !== null) {
    data.append("thumb_image_file", collection.thumb_image_file);
  }

  if (isObjectNotEmpty(collection.collection_url)) {
    data.append("collection_url", collection.collection_url);
  } else {
    data.append("collection_url", "");
  }

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(collection.id),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
  };

  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getItemFilters(collectionId) {
  const apiInfo = serverApis.getItemFilters;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return async (dispatch) => {
    const { responseData, responseJson } = await requestCreator(
      actionCreatorObject,
      dispatch
    );

    checkResponseStatusAndDispatchAction(
      dispatch,
      responseData,
      responseJson,
      apiInfo.actionName
    );

    if (responseData.status === 200) {
      // get collection Items
      dispatch(getCollectionItems(collectionId));
    }
  };
}

export function getCollectionItems(collectionId) {
  const apiInfo = serverApis.getCollectionItems;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(collectionId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return async (dispatch) => {
    const { responseData, responseJson } = await requestCreator(
      actionCreatorObject,
      dispatch
    );

    checkResponseStatusAndDispatchAction(
      dispatch,
      responseData,
      responseJson,
      apiInfo.actionName
    );

    if (responseData.status === 200) {
      // get searchItems Items
    }
  };
}

export function getItemSearchResult(filters) {
  const apiInfo = serverApis.getItemSearchResult;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...filters }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveCollectionState(requestObject) {
  const apiInfo = serverApis.saveCollectionState;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...requestObject }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveCollectionItems(requestObject, collectionId) {
  const apiInfo = serverApis.saveCollectionItems;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(collectionId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...requestObject }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveCollectionSequence(requestObject) {
  const apiInfo = serverApis.saveCollectionSequence;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...requestObject }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getCollectionsList() {
  const apiInfo = serverApis.getCollectionList;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function getFooterDetails() {
  const apiInfo = serverApis.getFooterDetails;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function getHomePageLists() {
  const apiInfo = serverApis.getHomePageLists;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function createHomeSlider(collection) {
  var apiInfo = serverApis.createHomeSlider;

  let data = new FormData();
  data.append("url", collection.url);

  if (collection.image_file !== null) {
    data.append("image_file", collection.image_file);
  }

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
  };

  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function deleteHomeSlider(id) {
  const apiInfo = serverApis.deleteHomeSlider;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(id),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function editHomeSlider(collection, id) {
  var apiInfo = serverApis.editHomeSlider;

  let data = new FormData();
  data.append("url", collection.url);

  if (collection.image_file !== null) {
    data.append("image_file", collection.image_file);
  }

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(id),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
  };

  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function addHomeCollection(collectionObjectsArry) {
  const apiInfo = serverApis.addHomeCollection;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      collections: [...collectionObjectsArry],
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function deleteHomeCollection(id) {
  const apiInfo = serverApis.deleteHomeCollection;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(id),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function activateInActivateSection(section) {
  const apiInfo = serverApis.activateInActivateSection;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      ...section,
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function updateFooterDetails(footerDetails) {
  const apiInfo = serverApis.updateFooterDetails;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      footer: {
        ...footerDetails,
      },
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function updateFooterActiveInactiveDetails(footerDetails) {
  const apiInfo = serverApis.updateFooterActiveInactiveDetails;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      footer: {
        ...footerDetails,
      },
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function updateFooterLinks(footerLinks, id) {
  var apiInfo = serverApis.updateFooterLinks;

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(id),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      ...footerLinks,
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };

  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function updateFooterDetailsForImage(imageFile) {
  let data = new FormData();

  data.append("logo_image_file", imageFile);

  const apiInfo = serverApis.updateFooterDetailsForImage;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function deleteFooterLinks(collectionId) {
  const apiInfo = serverApis.deleteFooterLinks;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(collectionId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function addFooterLogo(footerLogoDetails) {
  let data = new FormData();
  for (const [key, value] of Object.entries(footerLogoDetails)) {
    data.append(key, value);
  }

  const apiInfo = serverApis.addFooterLogo;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function deleteFooterLogo(logoId) {
  const apiInfo = serverApis.deleteFooterLogo;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(logoId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function updateFooterLogo(footerLogoDetails, id) {
  let data = new FormData();
  for (const [key, value] of Object.entries(footerLogoDetails)) {
    data.append(key, value);
  }
  var apiInfo = serverApis.updateFooterLogo;

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(id),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
  };

  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function updateHeaderLinks(headerLinks, id) {
  var apiInfo = serverApis.updateHeaderLink;
  const data = serialize({ ...headerLinks }, formDataSerializeOptions);

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(id),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getRequestHeaderFormData(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
  };

  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function createHeaderLinks(headerLinks) {
  const apiInfo = serverApis.createHeaderLinks;
  const data = serialize({ ...headerLinks }, formDataSerializeOptions);

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getRequestHeaderFormData(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
  };

  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function deleteHeaderSubLink(linkId) {
  const apiInfo = serverApis.deleteHeaderSubLink;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(linkId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function deleteHeaderLink(linkId) {
  const apiInfo = serverApis.deleteHeaderLink;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(linkId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function createFooterLinks(footerLinks) {
  const apiInfo = serverApis.createFooterLinks;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      ...footerLinks,
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function saveHomePageSectionsSequence(sectionsArry) {
  const apiInfo = serverApis.saveHomePageSectionsSequence;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      sections: [...sectionsArry],
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveHomeSliderSequence(sectionsArry) {
  const apiInfo = serverApis.saveHomeSliderSequence;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      slides: [...sectionsArry],
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveHomeCollageSequence(sectionsArry) {
  const apiInfo = serverApis.saveHomeCollageSequence;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      collage: [...sectionsArry],
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveHomeCollectionSequence(sectionsArry) {
  const apiInfo = serverApis.saveHomeCollectionSequence;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      collections: [...sectionsArry],
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function addHomeCollage(collectionObjectsArry) {
  const apiInfo = serverApis.addHomeCollage;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      collections: [...collectionObjectsArry],
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function deleteHomeCollage(id) {
  const apiInfo = serverApis.deleteHomeCollage;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(id),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getHeaderDetails() {
  const apiInfo = serverApis.getHeaderDetails;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function uploadHeaderBrandlogo(imageFile) {
  let data = new FormData();

  data.append("logo_image_file", imageFile);

  const apiInfo = serverApis.uploadHeaderBrandlogo;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function updateHeaderInfo(info) {
  const apiInfo = serverApis.updateHeaderInfo;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      ...info,
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function manageSequenceHeaderLink(info) {
  const apiInfo = serverApis.manageSequenceHeaderLink;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      ...info,
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function manageStateHeaderLink(info) {
  const apiInfo = serverApis.manageStateHeaderLink;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      ...info,
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function getProductDetails(productId) {
  const apiInfo = serverApis.getProductDetails;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(productId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function getSearchProducts(productCode) {
  const apiInfo = serverApis.getSearchProducts;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(productCode),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function getSearchProductsListAutocomplete(productCode) {
  const apiInfo = serverApis.getSearchProductsListAutocomplete;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(productCode),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function lookupOptions(info) {
  const apiInfo = serverApis.lookupOptions;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      ...info,
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function getSearchCategories(id) {
  const apiInfo = serverApis.getSearchCategories;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl(id),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function saveAndPublishProductDetails(requestObject) {
  const apiInfo = serverApis.saveAndPublishProductDetails;
  //let data = new FormData();
  // for (const [key, value] of Object.entries(requestObject)) {
  //   data.append(key, JSON.stringify(value));
  //   // data.append(key, value);
  // }
  const options = {
    /**
     * include array indices in FormData keys
     * defaults to false
     */
    indices: false,

    /**
     * treat null values like undefined values and ignore them
     * defaults to false
     */
    nullsAsUndefineds: false,

    /**
     * convert true or false to 1 or 0 respectively
     * defaults to false
     */
    booleansAsIntegers: false,

    /**
     * store arrays even if they're empty
     * defaults to false
     */
    allowEmptyArrays: true,
  };

  const data = serialize(requestObject, options);

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + apiInfo.serverUrl,
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function logoutAction() {
  // let actionCreatorObject = {
  //   [requestCreatorKey.REQUEST_ACTION_TYPE]: serverApis.logout.actionName,
  //   [requestCreatorKey.REQUEST_URL]:
  //     SERVER_ADDRESS + serverApis.logout.serverUrl,
  //   [requestCreatorKey.REQUEST_METHOD]: requestMethodType.DELETE,

  //   [requestCreatorKey.REQUEST_HEADER]: {
  //     ...getJsonRequestHeaderWithAuthentication(),
  //   },
  // };
  return (dispatch) => {
    //actionCreator(actionCreatorObject, dispatch);
    dispatch(resetLoginDataAndStore());
  };
}

export function resetLoginDataAndStore() {
  clearLoginData();
  return async (dispatch) => {
    dispatch(dispatchWithResponseAndType(null, resetActions.RESET_ALL_STATES));
  };
}

export function createCollection1(collection, parentId) {
  var apiInfo = serverApis.createCollection;

  let data = new FormData();
  data.append("name", collection.name);

  if (collection.cover_image_file !== null) {
    data.append("cover_image_file", collection.cover_image_file);
  }

  if (collection.thumb_image_file !== null) {
    data.append("thumb_image_file", collection.thumb_image_file);
  }

  if (isObjectNotEmpty(collection.collection_url)) {
    data.append("collection_url", collection.collection_url);
  }

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + apiInfo.serverUrl(parentId),
    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
  };

  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
