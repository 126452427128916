import React, { Component } from "react";
import { Form, Input, Modal, Button, Switch, List, Spin } from "antd";
import { SvgIcon, Row, Col } from "../../../common";
import ReactDragListView from "react-drag-listview";
import { connect } from "react-redux";
import {
  resetStoreState,
  createLoadingSelector,
  deleteHeaderSubLink,
  createHeaderLinks,
  getHeaderDetails,
  updateHeaderLinks,
} from "../../../../redux/action/index";
import "./edit-links.scss";
import { isObjectEmpty, isObjectNotEmpty } from "../../../../common/utils";
import { serverApis } from "../../../../constants/server-apis";
import { showAlert } from "../../../../common/alert-messages";
import confirm from "antd/lib/modal/confirm";

class EditSublinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isActive: false,
      inputFields: { ...this.initialRequestSubLinkObject() },
      addUpdateRequestObject: { ...this.initialRequestObject() },
    };
  }
  formRef = React.createRef();

  initialRequestObject = () => {
    return isObjectEmpty(this.props.headerLink)
      ? {
          title: "",
          url: "",
          sublink_flag: false,
          sub_links: [],
        }
      : { ...this.props.headerLink };
  };

  initialRequestSubLinkObject = () => {
    return { id: null, sub_link_title: "", sub_link_url: "" };
  };

  showModal = () => {
    this.setState({
      visible: true,
      addUpdateRequestObject: {
        ...this.initialRequestObject(),
      },
    });

    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        ...this.initialRequestObject(),
      });
    }
  };
  componentDidUpdate(prevProps) {
    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.deleteHeaderSublinkSuccessMessage)
    ) {
      this.setState({ inputFields: {} });
      showAlert.success(this.props.deleteHeaderSublinkSuccessMessage);
      this.props.resetStoreState(serverApis.deleteHeaderSubLink.actionName);
      this.props.resetStoreState(serverApis.getHeaderDetails.actionName);
      this.props.refreshHeaderState();
      this.props.getHeaderDetails();

      this.handleCancel();
    }
    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.createHeaderLinksSuccessMessage)
    ) {
      this.setState({
        inputFields: {},
        addUpdateRequestObject: {},
      });
      showAlert.success(this.props.createHeaderLinksSuccessMessage);
      this.props.resetStoreState(serverApis.createHeaderLinks.actionName);
      this.props.resetStoreState(serverApis.getHeaderDetails.actionName);
      this.props.refreshHeaderState();
      this.props.getHeaderDetails();

      this.handleCancel();
    }
    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.updateHeaderLinksSuccessMessage)
    ) {
      this.setState({ inputFields: {}, addUpdateRequestObject: {} });
      showAlert.success(this.props.updateHeaderLinksSuccessMessage);
      this.props.resetStoreState(serverApis.updateHeaderLink.actionName);
      this.props.resetStoreState(serverApis.getHeaderDetails.actionName);
      this.props.refreshHeaderState();
      this.props.getHeaderDetails();

      this.handleCancel();
    }
  }
  onChange = (checked) => {
    const addUpdateRequestObject = this.state.addUpdateRequestObject;
    addUpdateRequestObject.sublink_flag = checked;

    this.setState({
      addUpdateRequestObject,
    });
  };
  handleOk = () => {
    this.setState({
      visible: false,
    });
    this.props.deleteCollection();
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area

    let addUpdateRequestObject = this.state.addUpdateRequestObject;
    const items = [...addUpdateRequestObject.sub_links];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    addUpdateRequestObject.sub_links = items;
    this.setState({ addUpdateRequestObject });
  };

  handleShow = (sublink) => {
    let inputFields = this.initialRequestSubLinkObject();

    if (isObjectNotEmpty(sublink)) {
      inputFields = {
        id: sublink.id,
        sub_link_title: sublink.title,
        sub_link_url: sublink.url,
        file_data: sublink.file_data,
        file_name: sublink.file_name,
      };
    }

    this.setState({ isActive: true, inputFields });
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        ...inputFields,
      });
    }
  };

  editLinks = (event) => {
    let addUpdateRequestObject = this.state.addUpdateRequestObject;
    let value = event.target.value; //field value
    let name = event.target.getAttribute("name"); //field name
    addUpdateRequestObject[name] = value;
    this.setState({ addUpdateRequestObject });
  };

  addFileObject = (event) => {
    let addUpdateRequestObject = this.state.addUpdateRequestObject;

    addUpdateRequestObject.file_data = event.target.files[0];
    addUpdateRequestObject.file_name = addUpdateRequestObject.file_data.name;

    this.setState({ addUpdateRequestObject });
    event.target.value = null;
  };

  addSubLinks = (event) => {
    let inputFields = this.state.inputFields;
    let value = event.target.value; //field value
    let name = event.target.getAttribute("name"); //field name
    inputFields[name] = value;
    this.setState({ inputFields });
  };

  addSubLinksFile = (event) => {
    let inputFields = this.state.inputFields;

    inputFields.file_data = event.target.files[0];
    inputFields.file_name = inputFields.file_data.name;

    this.setState({ inputFields });
  };

  handleAddSublinkButton = () => {
    let addUpdateRequestObject = this.state.addUpdateRequestObject;
    let inputFields = this.state.inputFields;
    let sublinks = this.state.addUpdateRequestObject.sub_links;
    let sublinkArray = [];
    const subLinkItem = {
      title: inputFields.sub_link_title,
      url: inputFields.sub_link_url,
      file_data: inputFields.file_data,
      file_name: inputFields.file_name,
    };

    if (this.state.inputFields.id === null) {
      sublinkArray = [...sublinks, subLinkItem];
    } else {
      sublinkArray = sublinks.map((obj, index) => {
        return obj.id === inputFields.id ? { ...obj, ...subLinkItem } : obj;
      });
    }

    addUpdateRequestObject.sub_links = sublinkArray;

    // if (isObjectEmpty(this.props.headerLink)) {
    // } else {
    //   this.props.updateHeaderLinks(
    //     addUpdateRequestObject,
    //     this.props.headerLink.id
    //   );
    // }

    inputFields = { ...this.initialRequestSubLinkObject() };

    this.setState({
      addUpdateRequestObject,
      inputFields,
    });

    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        ...inputFields,
      });
    }
  };
  handleModalButton = () => {
    let addUpdateRequestObject = this.state.addUpdateRequestObject;

    if (isObjectEmpty(this.props.headerLink)) {
      this.props.createHeaderLinks({
        ...addUpdateRequestObject,
        file_name: addUpdateRequestObject.file_data
          ? addUpdateRequestObject.file_data.name
          : "",
      });
    } else {
      this.props.updateHeaderLinks(
        addUpdateRequestObject,
        this.props.headerLink.id
      );
    }
  };
  handleHeaderSubLink = (id) => {
    confirm({
      title: "Do you really want to Delete?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        this.props.deleteHeaderSubLink(id);
      },
    });
  };
  render() {
    const { visible } = this.state;
    return (
      <React.Fragment>
        {isObjectEmpty(this.props.headerLink) && (
          <div
            className="sublinks-box"
            onClick={(event) => {
              event.stopPropagation();
              this.showModal();
            }}
          >
            <div className="sublinks-add-button">
              <SvgIcon name="plus-circle" viewbox="0 0 19 19" />
              ADD LINK
            </div>
          </div>
        )}
        {!isObjectEmpty(this.props.headerLink) && (
          <div
            onClick={(event) => {
              event.stopPropagation();
              this.showModal();
            }}
            className="sublink-edit-button"
          >
            <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />
          </div>
        )}
        <Modal
          visible={visible}
          title={
            isObjectEmpty(this.props.headerLink) ? "Create Link" : "Edit Link"
          }
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <Button
              key="2"
              type="primary"
              block
              htmlType="submit"
              form={this.props.formId}
            >
              {" "}
              {isObjectEmpty(this.props.headerLink)
                ? "Create Link"
                : "Update Link"}
            </Button>,
          ]}
          className="modal-cms footeredit-modal"
        >
          {" "}
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="create-collection-modal">
              <Form
                layout="vertical"
                id={this.props.formId}
                onFinish={this.handleModalButton}
                initialValues={{ ...this.state.addUpdateRequestObject }}
                ref={this.formRef}
              >
                <React.Fragment>
                  <Row className="mb-3">
                    <Col>
                      Multi-level Menu{" "}
                      <Switch
                        className="ml-2 switch-cms"
                        checked={this.state.addUpdateRequestObject.sublink_flag}
                        onChange={this.onChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" className="pr-2">
                      <label htmlFor="title">Link Title </label>
                      <Form.Item className="m-0" id="title" name="title">
                        <Input
                          name="title"
                          size="large"
                          placeholder="Enter link Title"
                          value={
                            isObjectNotEmpty(this.props.headerLink) &&
                            isObjectNotEmpty(this.props.headerLink.title)
                              ? this.props.headerLink.title
                              : ""
                          }
                          onChange={this.editLinks}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm="6" className="pl-2">
                      <label htmlFor="url">URL</label>
                      <Form.Item className="m-0" id="url" name="url">
                        <Input
                          name="url"
                          size="large"
                          placeholder="Enter link URL"
                          value={
                            isObjectNotEmpty(this.props.headerLink) &&
                            isObjectNotEmpty(this.props.headerLink.url)
                              ? this.props.headerLink.url
                              : ""
                          }
                          onChange={this.editLinks}
                          disabled={
                            (isObjectNotEmpty(
                              this.state.addUpdateRequestObject.file_name
                            ) &&
                              this.state.addUpdateRequestObject.file_data !==
                                null) ||
                            this.state.addUpdateRequestObject.file_data
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" className='mt-3'>
                      <div>
                        <label htmlFor="url">File</label>
                      </div>
                      {/* <Form.Item className="m-0" id="file" name="file"> */}
                      {this.state.addUpdateRequestObject.file_data ? (
                        <div>
                            <div className="editlinkfile-preview">
                              <Button
                                className="remove-file"
                                onClick={() => {
                                  let addUpdateRequestObject = this.state
                                    .addUpdateRequestObject;

                                  addUpdateRequestObject.file_data = null;
                                  addUpdateRequestObject.file_name = "";

                                  this.setState({ addUpdateRequestObject });
                                }}
                              >
                                <SvgIcon
                                    name="close"
                                    viewbox="0 0 298.667 298.667"
                                  />
                              </Button>
                              <div
                                onClick={() =>
                                  window.open(
                                    URL.createObjectURL(
                                      this.state.addUpdateRequestObject.file_data
                                    ),
                                    "_blank"
                                  )
                                }
                              > 
                              {this.state.addUpdateRequestObject.file_data.name}
                              </div>
                            </div>
                        </div>
                      ) : isObjectNotEmpty(
                          this.state.addUpdateRequestObject.file_name
                        ) &&
                        this.state.addUpdateRequestObject.file_data !== null ? (
                        <div>
                            {/* {this.state.addUpdateRequestObject.file_name} */}
                            <div className="editlinkfile-preview">
                              <Button
                                className="remove-file"
                                onClick={() => {
                                  let addUpdateRequestObject = this.state
                                    .addUpdateRequestObject;

                                  addUpdateRequestObject.file_data = null;
                                  addUpdateRequestObject.file_name = "";

                                  this.setState({ addUpdateRequestObject });
                                }}
                              >
                                <SvgIcon
                                    name="close"
                                    viewbox="0 0 298.667 298.667"
                                  />
                              </Button>
                              <div
                                onClick={() =>
                                  window.open(
                                    this.state.addUpdateRequestObject.url,
                                    "_blank"
                                  )
                                }
                              >
                              {this.state.addUpdateRequestObject.file_name}
                              </div>
                            </div>
                        </div>
                      ) : (
                        <div className="uploadeditlinkhumbnail-link">
                          <div className="link-inner">
                            <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Choose file
                            <input type="file" onChange={this.addFileObject} />
                            </div>
                          {/* <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Add
                          Image */}
                        </div>
                      )}
                      {/* </Form.Item> */}
                    </Col>
                  </Row>

                  {this.state.addUpdateRequestObject.sublink_flag && (
                    <Row className="mt-3">
                      <Col>
                        <ReactDragListView
                          nodeSelector=".ant-list-item.draggble2"
                          onDragEnd={this.onDragEnd}
                        >
                          <List
                            size="small"
                            dataSource={
                              this.state.addUpdateRequestObject.sub_links
                            }
                            className="sublinks-link-list"
                            renderItem={(item) => {
                              const draggble2 = item !== " ";
                              return (
                                <List.Item
                                  className={draggble2 ? "draggble2" : ""}
                                >
                                  <div
                                    className="sublinks-link"
                                    onClick={() => this.handleShow(item)}
                                  >
                                    <div
                                      className="remove-card"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <div
                                        className="close-icon"
                                        onClick={() =>
                                          this.handleHeaderSubLink(item.id)
                                        }
                                      >
                                        <SvgIcon
                                          name="close"
                                          viewbox="0 0 298.667 298.667"
                                        />
                                      </div>
                                    </div>
                                    <div className="sublinks-linkinner">
                                      <div className="right">{item.title}</div>
                                    </div>
                                  </div>
                                </List.Item>
                              );
                            }}
                          />
                        </ReactDragListView>
                        <div
                          className="addsublink-menu"
                          onClick={() => this.handleShow({})}
                        >
                          <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Add
                          Sub Link
                        </div>
                      </Col>
                    </Row>
                  )}

                  {this.state.isActive &&
                    this.state.addUpdateRequestObject.sublink_flag && (
                      <div className="sublinkcard">
                        <Row>
                          <Col sm="6" className="pr-2">
                            <label htmlFor="sub_link_title">
                              Sub Link Title{" "}
                            </label>
                            <Form.Item
                              className="m-0"
                              id="sub_link_title"
                              name="sub_link_title"
                            >
                              <Input
                                name="sub_link_title"
                                size="large"
                                placeholder="Enter link Title"
                                onChange={this.addSubLinks}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm="6" className="pl-2">
                            <label htmlFor="sub_url">URL</label>
                            <Form.Item
                              className="m-0"
                              id="sub_link_url"
                              name="sub_link_url"
                            >
                              <Input
                                name="sub_link_url"
                                size="large"
                                placeholder="Enter link URL"
                                onChange={this.addSubLinks}
                                disabled={isObjectNotEmpty(
                                  this.state.inputFields.file_name
                                )}
                              />
                            </Form.Item>
                          </Col>

                          <Col sm="12" className='mt-3'>
                            <div>
                              <label htmlFor="url">File</label>
                            </div>
                            {/* <Form.Item className="m-0" id="file" name="file"> */}
                            {this.state.inputFields.file_data ? (
                              <div>
                                <div className="editlinkfile-preview">
                                  <Button
                                    className="remove-file"
                                    onClick={() => {
                                      let inputFields = this.state.inputFields;

                                      inputFields.file_data = null;
                                      inputFields.file_name = "";

                                      this.setState({ inputFields });
                                    }}
                                  >
                                    <SvgIcon
                                      name="close"
                                      viewbox="0 0 298.667 298.667"
                                    />
                                  </Button>
                                  <div
                                    onClick={() =>
                                      window.open(
                                        URL.createObjectURL(
                                          this.state.inputFields.file_data
                                        ),
                                        "_blank"
                                      )
                                    }
                                  >
                                    {this.state.inputFields.file_data.name}
                                  </div>
                                </div>
                              </div>
                            ) : isObjectNotEmpty(
                                this.state.inputFields.file_name
                              ) ? (
                              <div>
                                  <div className="editlinkfile-preview">
                                    <Button
                                      className="remove-file"
                                      onClick={() => {
                                        let inputFields = this.state.inputFields;

                                        inputFields.file_data = null;
                                        inputFields.file_name = "";

                                        this.setState({ inputFields });
                                      }}
                                    >
                                      <SvgIcon
                                        name="close"
                                        viewbox="0 0 298.667 298.667"
                                      />
                                      </Button>
                                    <div
                                      onClick={() =>
                                        window.open(
                                          this.state.inputFields.sub_link_url,
                                          "_blank"
                                        )
                                      }
                                    >
                                    {this.state.inputFields.file_name}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="uploadeditlinkhumbnail-link">
                                <div className="link-inner">
                                  <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Choose file
                                  <input
                                    type="file"
                                    onChange={this.addSubLinksFile}
                                  />
                                </div>
                                {/* <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Add
                          Image */}
                              </div>
                            )}
                            {/* </Form.Item> */}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button
                              className="btn-success btn-md radius"
                              onClick={this.handleAddSublinkButton}
                            >
                              {this.state.inputFields.id === null
                                ? "Add Sub Link"
                                : "Update Sub Link"}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                </React.Fragment>
              </Form>
            </div>
          </Spin>{" "}
        </Modal>
      </React.Fragment>
    );
  }
}
const loadingSelector = createLoadingSelector([
  serverApis.deleteHeaderSubLink.actionName,
  serverApis.updateHeaderLink.actionName,
  serverApis.createHeaderLinks.actionName,
]);
const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),

  deleteHeaderSublinkSuccessMessage: state.deleteHeaderSublinkSuccessMessage,
  updateHeaderLinksSuccessMessage: state.updateHeaderLinksSuccessMessage,
  createHeaderLinksSuccessMessage: state.createHeaderLinksSuccessMessage,
});

export default connect(mapStateToProps, {
  deleteHeaderSubLink,
  getHeaderDetails,
  resetStoreState,
  createHeaderLinks,
  updateHeaderLinks,
})(EditSublinks);
