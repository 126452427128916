import React, { Component } from "react";
import { Button } from "antd";
import { SvgIcon, Row, Col } from "../../common";
import "./product-details-card.scss";

//import images
import { baseServerAdress } from "../../../constants/server-address";

class ProductDetailsCard extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className={"card-box product-info-topcard " + this.props.activeClass}
        >
          <Row className="align-items-center">
            <Col sm="8">
              <div className="product-upper-card">
                <div className="img-box">
                  {this.props.productDetails.default_image_name && (
                    <img
                      alt={this.props.productDetails.default_image_name}
                      src={
                        baseServerAdress.ITEM_IMAGE +
                        this.props.productDetails.default_image_name
                      }
                    />
                  )}
                </div>
                <div>
                  <p>{this.props.productDetails.product_code}</p>
                  <h4>{this.props.productDetails.product_name}</h4>
                </div>
              </div>
            </Col>
            <Col sm="4" align="right" className="pr-4">
              <Button
                size={this.props.activeClass === "fixTop" ? "small" : "middle"}
                className="btn-md plus-btn radius with-icon"
                type="primary"
                icon={
                  <SvgIcon
                    name="view-website"
                    width={this.props.activeClass === "fixTop" ? "15" : "20"}
                    style={{ transform: "translateY(-2px)" }}
                    viewbox="0 0 17 17"
                  />
                }
                onClick={() => {
                  window.open(
                    baseServerAdress.WEBSITE_URL +
                      `products/${this.props.productDetails.catalog_item_id}`,
                    "_blank"
                  );
                }}
              >
                View on website
              </Button>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductDetailsCard;
