import React, { Component } from "react";
import { Form, Input } from "antd";
import { Row, Col } from "../../common";

class HeaderSearch extends Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col sm="6">
            <Form layout="vertical">
              <Form.Item className="mb-3" id="title_text" name="title_text">
                <label className="mt-2">Search label</label>

                <Input
                  name="title"
                  size="large"
                  placeholder="Enter search Title"
                  defaultValue={this.props.searchInput}
                  onChange={this.props.onChangeHeaderInfo}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default HeaderSearch;
