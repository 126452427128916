export const LOGIN_TOKEN_KEY = "TOKEN_CMS";
export const USER_DETAIL_KEY = "USERDETAIL_CMS";
export const DATE_FORMAT = "MM-DD-YYYY";
export const NAVIGATION_REFERENCE = "NAVIGATION_REFERENCE";

export const requestCreatorKey = {
  REQUEST_ACTION_TYPE: "actionType",
  REQUEST_URL: "requestURL",
  REQUEST_HEADER: "requestHeader",
  REQUEST_PAYLOAD: "requestPayload",
  REQUEST_METHOD: "requestMethod",
  SHOW_ERROR_FROM_TRANSACTION: "showErrorFromTransaction",
};
export const requestContentType = {
  APPLICATION_JSON: "application/json",
};
export const requestMethodType = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

