import React, { Component } from "react";
import { Checkbox, List, Spin } from "antd";
import ReactDragListView from "react-drag-listview";
import { connect } from "react-redux";

import EditSublinks from "./edit-links/edit-links";
import { isObjectNotEmpty } from "../../../common/utils";
import { SvgIcon } from "../../common";
import {
  deleteHeaderLink,
  resetStoreState,
  createLoadingSelector,
  getHeaderDetails,
  manageStateHeaderLink,
} from "../../../redux/action";
import { serverApis } from "../../../constants/server-apis";
import { showAlert } from "../../../common/alert-messages";
import confirm from "antd/lib/modal/confirm";

class HeaderSublinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerLinksStatus: {},
    };
  }
  componentDidUpdate(prevProps) {
    if (isObjectNotEmpty(this.props.deleteHeaderlinkSuccessMessage)) {
      showAlert.success(this.props.deleteHeaderlinkSuccessMessage);
      this.props.resetStoreState(serverApis.deleteHeaderLink.actionName);
      this.props.resetStoreState(serverApis.getHeaderDetails.actionName);
      this.props.refreshHeaderState();
      this.props.getHeaderDetails();
    }
    if (isObjectNotEmpty(this.props.manageStateHeaderLinkSuccessMessage)) {
      this.setState({ inputFields: {} });
      showAlert.success(this.props.manageStateHeaderLinkSuccessMessage);
      this.props.resetStoreState(serverApis.manageStateHeaderLink.actionName);
      this.props.resetStoreState(serverApis.getHeaderDetails.actionName);
      this.props.refreshHeaderState();
      this.props.getHeaderDetails();
    }
  }
  headerLinkStatusChange = (event) => {
    let headerLinksStatus = {};
    headerLinksStatus["id"] = event.target.name;
    headerLinksStatus["trans_flag"] = event.target.checked ? "A" : "D";
    this.setState({ headerLinksStatus });
    this.props.manageStateHeaderLink(headerLinksStatus);
  };
  handleDeleteHeaderLink = (id) => {
    confirm({
      title: "Do you really want to Delete?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        this.props.deleteHeaderLink(id);
      },
    });
  };
  render() {
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <React.Fragment>
          <ReactDragListView
            nodeSelector=".ant-list-item.draggble"
            onDragEnd={this.props.onDragEnd}
          >
            <List
              size="small"
              dataSource={this.props.data}
              className="sublinks-section"
              renderItem={(item, index) => {
                const draggble = item !== " ";
                return (
                  <List.Item className={draggble ? "draggble" : ""}>
                    <div className="sublinks-box">
                      <div className="sublinks-box-inner" key="2">
                        <div
                          className="remove-card"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div
                            className="close-icon"
                            onClick={() => this.handleDeleteHeaderLink(item.id)}
                          >
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </div>
                        <div>
                          <Checkbox
                            key={item.id}
                            name={item.id}
                            checked={item.trans_flag === "A" ? true : false}
                            onChange={this.headerLinkStatusChange}
                          ></Checkbox>{" "}
                          {item.title}
                        </div>

                        <EditSublinks
                          refreshHeaderState={this.props.refreshHeaderState}
                          formId={`${index}`}
                          headerLink={item}
                        />
                      </div>
                    </div>
                  </List.Item>
                );
              }}
            />
          </ReactDragListView>
          <ul className="sublinks-section w-100">
            <li className="w-100">
              <EditSublinks
                formId={"qwer"}
                refreshHeaderState={this.props.refreshHeaderState}
              />
            </li>
          </ul>
        </React.Fragment>
      </Spin>
    );
  }
}
const loadingSelector = createLoadingSelector([
  serverApis.deleteHeaderSubLink.actionName,
]);
const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  headerDetails: state.headerDetails,
  deleteHeaderlinkSuccessMessage: state.deleteHeaderlinkSuccessMessage,
  manageStateHeaderLinkSuccessMessage:
    state.manageStateHeaderLinkSuccessMessage,
});
export default connect(mapStateToProps, {
  deleteHeaderLink,
  resetStoreState,
  getHeaderDetails,
  manageStateHeaderLink,
})(HeaderSublinks);
