import React, { Component } from "react";
import { Collapse, Switch, List, Button, Modal } from "antd";
import { sortableHandle } from "react-sortable-hoc";
import { SvgIcon, Row, Col, ImageLoader } from "../../common";
import ReactDragListView from "react-drag-listview";
import "./slider-banner.scss";

import AddSliderPopup from "./add-slider-popup/add-slider-popup";
import EditSliderPopup from "./edit-slider-popup/edit-slider-popup";
import { baseServerAdress } from "../../../constants/server-address";
import {
  createLoadingSelector,
  deleteHomeSlider,
  getHomePageLists,
  resetStoreState,
  activateInActivateSection,
  saveHomeSliderSequence,
} from "../../../redux/action";
import { serverApis } from "../../../constants/server-apis";
import { connect } from "react-redux";
import { showAlert } from "../../../common/alert-messages";
import { isObjectNotEmpty } from "../../../common/utils";
import confirm from "antd/lib/modal/confirm";

const { Panel } = Collapse;
const DragHandle = sortableHandle(() => (
  <SvgIcon className="move-icon-card" name="move-dotes" viewbox="0 0 20 32" />
));

const panelHeader = (
  disableButton,
  activateInActivateSection,
  sectionDetails,
  saveSequence
) => (
  <React.Fragment>
    <React.Fragment>
      <div className="panel-header">
        <div className="title-left d-flex align-items-center">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DragHandle />
          </div>
          Slider Banner
        </div>
        <div
          className="title-switch"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Switch
            className="switch-cms"
            defaultChecked
            size="large"
            onChange={(checked) => {
              let requestObject = {
                trans_flag: checked ? "A" : "D",
                id: sectionDetails.id,
              };
              activateInActivateSection(requestObject);
            }}
            checked={sectionDetails.trans_flag === "A"}
          />
        </div>
        <div
          className="save-btn"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Button
            size="small"
            className="btn-success btn-md radius px-3"
            disabled={disableButton}
            onClick={saveSequence}
          >
            Save
          </Button>
        </div>
      </div>
    </React.Fragment>
  </React.Fragment>
);

class HomeSlider extends Component {
  state = {
    data: this.props.contentList,
    unUsedData: this.props.contentList,
  };

  onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area

    const items = [...this.state.data];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    this.setState({ data: items });
    this.props.setSectionsSequenceChanged(true);
  };

  onChangeCollapse = (value) => {
    this.props.onChangeCollapse(value);
  };

  handleOk = () => {
    this.props.setSectionsSequenceChanged(false);
    this.saveSequence();
  };

  handleCancel = () => {
    this.props.setShowSectionSequenceAlert(false);
  };

  resetSequence = () => {
    this.setState({
      data: this.props.contentList,
    });
    this.props.setShowSectionSequenceAlert(false);
    this.props.setSectionsSequenceChanged(false);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.contentList !== state.unUsedData) {
      return { data: props.contentList, unUsedData: props.contentList };
    }
    return null;
  }

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.deleteHomeSliderSuccessMessage)) {
      showAlert.success(this.props.deleteHomeSliderSuccessMessage);
      this.props.resetStoreState(serverApis.deleteHomeSlider.actionName);

      this.props.getHomePageLists();
    }

    if (isObjectNotEmpty(this.props.activateInActivateSectionSuccessMessage)) {
      showAlert.success(this.props.activateInActivateSectionSuccessMessage);
      this.props.resetStoreState(
        serverApis.activateInActivateSection.actionName
      );

      this.props.getHomePageLists();
    }
  }

  saveSequence = () => {
    let items = this.state.data.map((item) => {
      return { id: item.id };
    });
    this.props.saveHomeSliderSequence(items);
  };
  handleDeleteHomeSlider = (id) => {
    confirm({
      title: "Do you really want to Delete?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        this.props.deleteHomeSlider(id);
      },
    });
  };
  render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        <Modal
          className="modal-cms alert-modal"
          visible={
            this.props.showSectionSequenceAlert &&
            this.props.activeKeys.toString() === ["1"].toString()
          }
          title="Alert"
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <Button key="back" size="small" onClick={this.resetSequence}>
              No
            </Button>,
            <Button
              key="submit1"
              size="small"
              type="primary"
              loading={loading}
              onClick={this.handleOk}
            >
              Yes
            </Button>,
            <Button
              key="submit2"
              size="small"
              type="primary"
              onClick={this.handleCancel}
            >
              keep editing
            </Button>,
          ]}
        >
          <p className="alret-modal-text">
            <SvgIcon
              className="mb-n1 mr-1"
              name="info-icon"
              viewbox="0 0 35.446 35.446"
            />{" "}
            Would you like to save your changes ?
          </p>
        </Modal>
        <Collapse
          defaultActiveKey={[]}
          accordion
          expandIconPosition="right"
          activeKey={this.props.activeKeys}
          onChange={this.onChangeCollapse}
        >
          <Panel
            header={panelHeader(
              !this.props.isSectionsSequenceChanged,
              this.props.activateInActivateSection,
              this.props.sectionDetails,
              this.saveSequence
            )}
            key="1"
          >
            <ReactDragListView
              nodeSelector=".ant-list-item.draggble"
              onDragEnd={this.onDragEnd}
            >
              <List
                size="small"
                bordered
                dataSource={this.state.data}
                className="card-listslider-banner"
                renderItem={(item) => {
                  const draggble = item !== " ";
                  return (
                    <List.Item className={draggble ? "draggble" : ""}>
                      <div className="EditImage">
                        <div
                          className="removethumb-img"
                          onClick={() => this.handleDeleteHomeSlider(item.id)}
                        >
                          <div className="close-icon">
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </div>
                        <EditSliderPopup itemDetails={item} />
                        <div className="sliderimg-wrape">
                          <ImageLoader
                            className="imagemain"
                            alt="imagemain"
                            src={baseServerAdress.COLLECTION_IMAGE + item.image}
                          />
                        </div>
                      </div>
                    </List.Item>
                  );
                }}
              />
            </ReactDragListView>
            <Row className="mt-3">
              <Col>
                <AddSliderPopup />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  }
}
const loadingSelector = createLoadingSelector([
  serverApis.deleteHomeSlider.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  deleteHomeSliderSuccessMessage: state.deleteHomeSliderSuccessMessage,
  activateInActivateSectionSuccessMessage:
    state.activateInActivateSectionSuccessMessage,
});

export default connect(mapStateToProps, {
  resetStoreState,
  deleteHomeSlider,
  getHomePageLists,
  activateInActivateSection,
  saveHomeSliderSequence,
})(HomeSlider);
