import React from "react";
import { Form, Input, Button, Checkbox, Spin } from "antd";
import { SvgIcon } from "../../common";

//Style Test
import "./login.scss";
import { serverApis } from "../../../constants/server-apis";
import {
  createLoadingSelector,
  loginAction,
  resetLoginDataAndStore,
  resetStoreState,
} from "../../../redux/action";

import { connect } from "react-redux";
import { isObjectNotEmpty } from "../../../common/utils";
import { NAVIGATION_REFERENCE } from "../../../constants";
import history from "../../../common/history";

class LoginView extends React.Component {
  onFinish = (values) => {
    this.props.loginAction(values["email"], values["password"]);
  };

  componentDidMount() {
    this.props.resetLoginDataAndStore();
  }

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.userDetails)) {
      var referer = serverApis.rootCollections.componentPath;
      if (
        isObjectNotEmpty(this.props.location.state) &&
        isObjectNotEmpty(this.props.location.state[NAVIGATION_REFERENCE])
      ) {
        referer = this.props.location.state[NAVIGATION_REFERENCE];
      }
      history.replace(referer);
    }
  }

  render() {
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <div className="login-wrapper">
          <div className="login-left">
            <div>
              <SvgIcon name="logo" viewbox="0 0 235.976 25.128" width="340px" />
              <div className="center-content mt-5">
                <h1>Welcome Back!! </h1>
                <p>Please login to access your account</p>
              </div>
            </div>
          </div>
          <div className="login-right">
            <div className="login-form-wrapper">
              <div className="login-form-inner">
                <h1>Sign in</h1>
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: false }}
                  onFinish={this.onFinish}
                  layout="vertical"
                >
                  <Form.Item
                    name="email"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your User name!",
                      },
                    ]}
                    label="User Name"
                  >
                    <Input size="large" placeholder="Enter Username" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Password!",
                      },
                    ]}
                    label="Password"
                  >
                    <Input.Password size="large" placeholder="Enter Password" />
                  </Form.Item>
                  <div className="mb-3 d-flex justify-content-between">
                    <Form.Item
                      className="mb-0 d-flex align-items-start"
                      name="remember"
                      valuePropName="checked"
                    >
                      <Checkbox className="fs-12">Keep me signed in</Checkbox>
                    </Form.Item>
                  </div>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([serverApis.login.actionName]);

const mapStateToProps = (state) => ({
  userDetails: state.loginDetails,
  isFetchingData: loadingSelector(state),
  redirectURL: state.redirectURL,
});

export default connect(mapStateToProps, {
  loginAction,
  resetLoginDataAndStore,
  resetStoreState,
})(LoginView);
