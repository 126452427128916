import { combineReducers } from "redux";
import { requestStatus, resetActions } from "../../constants/request";
import {
  getActionNameWithStatus,
  getResetAction,
} from "../../constants/actions-methods";
import { serverApis } from "../../constants/server-apis";
import { isObjectNotEmpty } from "../../common/utils";

//////////  COMMON REDUCERS   /////////////

const errorReducer = (state = {}, action) => {
  const { type, json } = action;
  if (type === resetActions.ERROR_MESSAGES_RESET_ALL) {
    return {};
  }

  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);
  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
    //      else clear errorMessage when receiving GET_TODOS_REQUEST
    [requestName.split("_")[1]]:
      requestState === requestStatus.FAILURE ? json : "",
  };
};

const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName.split("_")[1]]: requestState === requestStatus.REQUEST,
  };
};

//////////  COMMON REDUCERS   /////////////

const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case getActionNameWithStatus(
      serverApis.login.actionName,
      requestStatus.SUCCESS
    ):
      return action.json.data.users;

    default:
      return state;
  }
};

const rootCollectionsReducer = (state = [], action) => {
  switch (action.type) {
    case getActionNameWithStatus(
      serverApis.rootCollections.actionName,
      requestStatus.SUCCESS
    ):
      return action.json.data.collections;

    case getResetAction(serverApis.rootCollections.actionName):
      return [];

    default:
      return state;
  }
};

const subCollectionsReducer = (state = [], action) => {
  switch (action.type) {
    case getActionNameWithStatus(
      serverApis.getSubCollections.actionName,
      requestStatus.SUCCESS
    ):
      return action.json.data.collections;

    case getResetAction(serverApis.getSubCollections.actionName):
      return [];

    default:
      return state;
  }
};

const subChildCollectionsReducer = (state = [], action) => {
  let actionName = serverApis.getSubChildCollections.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.collections;

    case getResetAction(actionName):
      return [];

    default:
      return state;
  }
};

const createCollectionSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.createCollection.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const deleteCollectionSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.deleteCollection.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const updateCollectionSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.updateCollection.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const saveCollectionStateSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.saveCollectionState.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const saveCollectionItemsSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.saveCollectionItems.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const saveCollectionSequenceSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.saveCollectionSequence.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const emptyFiltersObject = { categories: [], item_types: [], vendors: [] };

const productFiltersReducer = (state = emptyFiltersObject, action) => {
  let actionName = serverApis.getItemFilters.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return { ...action.json.data };

    case getResetAction(actionName):
      return emptyFiltersObject;

    default:
      return state;
  }
};

const itemsDynamicQueryReducer = (state = {}, action) => {
  let actionName = serverApis.getCollectionItems.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      if (isObjectNotEmpty(action.json.data.dynamic_query)) {
        return { ...action.json.data.dynamic_query };
      }
      return {};

    case getResetAction(actionName):
      return {};

    default:
      return state;
  }
};

const itemSelectedItemsReducer = (state = [], action) => {
  let actionName = serverApis.getCollectionItems.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      if (isObjectNotEmpty(action.json.data.catalog_items)) {
        return action.json.data.catalog_items;
      }
      return [];

    case getResetAction(actionName):
      return [];

    default:
      return state;
  }
};
const itemsSearchResultsReducer = (state = [], action) => {
  let actionName = serverApis.getItemSearchResult.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      if (isObjectNotEmpty(action.json.data.catalog_items)) {
        return action.json.data.catalog_items;
      }
      return [];

    case getResetAction(actionName):
      return [];

    default:
      return state;
  }
};

const homePageListsReducer = (state = [], action) => {
  let actionName = serverApis.getHomePageLists.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.home_sections;

    case getResetAction(actionName):
      return [];

    default:
      return state;
  }
};
const footerDetailsReducer = (state = {}, action) => {
  let actionName = serverApis.getFooterDetails.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.footers;

    case getResetAction(actionName):
      return {};

    default:
      return state;
  }
};

const createHomeSliderSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.createHomeSlider.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const deleteHomeSliderSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.deleteHomeSlider.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const editHomeSliderSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.editHomeSlider.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const addHomeCollectionSuccessMessageReducer = (state = "", action) => {
  let actionName1 = serverApis.addHomeCollection.actionName;
  let actionName2 = serverApis.addHomeCollage.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName1, requestStatus.SUCCESS):
    case getActionNameWithStatus(actionName2, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName1):
    case getResetAction(actionName2):
      return "";

    default:
      return state;
  }
};

const deleteHomeCollectionSuccessMessageReducer = (state = "", action) => {
  let actionName1 = serverApis.deleteHomeCollection.actionName;
  let actionName2 = serverApis.deleteHomeCollage.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName1, requestStatus.SUCCESS):
    case getActionNameWithStatus(actionName2, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName1):
    case getResetAction(actionName2):
      return "";

    default:
      return state;
  }
};

const activateInActivateSectionSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.activateInActivateSection.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const updateFooterDetailsSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.updateFooterDetails.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const updateFooterActiveInactiveDetailsSuccessMessageReducer = (
  state = "",
  action
) => {
  let actionName = serverApis.updateFooterActiveInactiveDetails.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const updateFooterDetailsForImageSuccessMessageReducer = (
  state = "",
  action
) => {
  let actionName = serverApis.updateFooterDetailsForImage.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const updateFooterLinksSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.updateFooterLinks.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const addFooterLogoSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.addFooterLogo.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const deleteFooterLogoSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.deleteFooterLogo.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const updateFooterLogoSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.updateFooterLogo.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const updateHeaderLinksSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.updateHeaderLink.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const deleteHeaderSublinkSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.deleteHeaderSubLink.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const deleteHeaderlinkSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.deleteHeaderLink.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const createHeaderLinksSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.createHeaderLinks.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const createFooterLinksSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.createFooterLinks.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const deleteFooterLinksSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.deleteFooterLinks.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const saveHomePageSectionsSequenceSuccessMessageReducer = (
  state = "",
  action
) => {
  let actionName = serverApis.saveHomePageSectionsSequence.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};

const saveSectionItemsSequenceSuccessMessageReducer = (state = "", action) => {
  let actionName1 = serverApis.saveHomeSliderSequence.actionName;
  let actionName2 = serverApis.saveHomeCollageSequence.actionName;
  let actionName3 = serverApis.saveHomeCollectionSequence.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName1, requestStatus.SUCCESS):
    case getActionNameWithStatus(actionName2, requestStatus.SUCCESS):
    case getActionNameWithStatus(actionName3, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName1):
    case getResetAction(actionName2):
    case getResetAction(actionName3):
      return "";

    default:
      return state;
  }
};

const emptyCollections = { collections: [], allCollections: [] };

const allCollectionsDetailsReducer = (state = emptyCollections, action) => {
  let actionName = serverApis.getCollectionList.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      var arrayCollections = [];

      action.json.data.collections.forEach((parent) => {
        arrayCollections.push(parent);

        if (isObjectNotEmpty(parent.sub_collections)) {
          arrayCollections = arrayCollections.concat(parent.sub_collections);
        }
      });

      return {
        collections: action.json.data.collections,
        allCollections: arrayCollections,
      };

    case getResetAction(actionName):
      return emptyCollections;

    default:
      return state;
  }
};

const headerDetailsReducer = (state = {}, action) => {
  let actionName = serverApis.getHeaderDetails.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.headers;

    case getResetAction(actionName):
      return {};

    default:
      return state;
  }
};
const uploadHeaderBrandlogoSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.uploadHeaderBrandlogo.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const updateHeaderInfoSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.updateHeaderInfo.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const manageSequenceHeaderLinkSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.manageSequenceHeaderLink.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const manageStateHeaderLinkSuccessMessageReducer = (state = "", action) => {
  let actionName = serverApis.manageStateHeaderLink.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
const productDetailsReducer = (state = {}, action) => {
  let actionName = serverApis.getProductDetails.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.catalog_item_webdata;

    case getResetAction(actionName):
      return {};

    default:
      return state;
  }
};
const searchProductsReducer = (state = [], action) => {
  let actionName = serverApis.getSearchProducts.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.products;

    case getResetAction(actionName):
      return [];

    default:
      return state;
  }
};
const searchProductsListAutocompleteReducer = (state = [], action) => {
  let actionName = serverApis.getSearchProductsListAutocomplete.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.products;

    case getResetAction(actionName):
      return [];

    default:
      return state;
  }
};
const lookupOptionsReducer = (state = [], action) => {
  let actionName = serverApis.lookupOptions.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.lookup_options;

    case getResetAction(actionName):
      return [];

    default:
      return state;
  }
};
const searchCategoriesReducer = (state = [], action) => {
  let actionName = serverApis.getSearchCategories.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.collections;

    case getResetAction(actionName):
      return [];

    default:
      return state;
  }
};
const saveAndPublishProductDetailsReducer = (state = "", action) => {
  let actionName = serverApis.saveAndPublishProductDetails.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.data.message;

    case getResetAction(actionName):
      return "";

    default:
      return state;
  }
};
//////// Combine all reducers ////////////

const appReducer = combineReducers({
  loginDetails: loginReducer,
  loadingApis: loadingReducer,
  errorMessages: errorReducer,
  rootCollections: rootCollectionsReducer,
  createCollectionSuccessMessage: createCollectionSuccessMessageReducer,
  subCollections: subCollectionsReducer,
  subChildCollections: subChildCollectionsReducer,
  deleteCollectionSuccessMessage: deleteCollectionSuccessMessageReducer,
  updateCollectionSuccessMessage: updateCollectionSuccessMessageReducer,
  productFilters: productFiltersReducer,
  itemSearchResult: itemsSearchResultsReducer,
  itemSelectedItems: itemSelectedItemsReducer,
  itemsDynamicQuery: itemsDynamicQueryReducer,
  saveCollectionStateSuccessMessage: saveCollectionStateSuccessMessageReducer,
  saveCollectionSequenceSuccessMessage: saveCollectionSequenceSuccessMessageReducer,
  saveCollectionItemsSuccessMessage: saveCollectionItemsSuccessMessageReducer,
  allCollectionsDetails: allCollectionsDetailsReducer,
  homePageLists: homePageListsReducer,
  createHomeSliderSuccessMessage: createHomeSliderSuccessMessageReducer,
  deleteHomeSliderSuccessMessage: deleteHomeSliderSuccessMessageReducer,
  editHomeSliderSuccessMessage: editHomeSliderSuccessMessageReducer,
  addHomeCollectionSuccessMessage: addHomeCollectionSuccessMessageReducer,
  deleteHomeCollectionSuccessMessage: deleteHomeCollectionSuccessMessageReducer,
  activateInActivateSectionSuccessMessage: activateInActivateSectionSuccessMessageReducer,
  saveHomePageSectionsSequenceSuccessMessage: saveHomePageSectionsSequenceSuccessMessageReducer,
  saveSectionItemsSequenceSuccessMessage: saveSectionItemsSequenceSuccessMessageReducer,
  footerDetails: footerDetailsReducer,
  updateFooterDetailsSuccessMessage: updateFooterDetailsSuccessMessageReducer,
  createFooterLinksSuccessMessage: createFooterLinksSuccessMessageReducer,
  deleteFooterLinksSuccessMessage: deleteFooterLinksSuccessMessageReducer,
  updateFooterLinksSuccessMessage: updateFooterLinksSuccessMessageReducer,
  updateFooterDetailsForImageSuccessMessage: updateFooterDetailsForImageSuccessMessageReducer,
  updateFooterActiveInactiveDetailsSuccessMessage: updateFooterActiveInactiveDetailsSuccessMessageReducer,
  headerDetails: headerDetailsReducer,
  addFooterLogoSuccessMessage: addFooterLogoSuccessMessageReducer,
  deleteFooterLogoSuccessMessage: deleteFooterLogoSuccessMessageReducer,
  updateFooterLogoSuccessMessage: updateFooterLogoSuccessMessageReducer,
  deleteHeaderSublinkSuccessMessage: deleteHeaderSublinkSuccessMessageReducer,
  updateHeaderLinksSuccessMessage: updateHeaderLinksSuccessMessageReducer,
  createHeaderLinksSuccessMessage: createHeaderLinksSuccessMessageReducer,
  uploadHeaderBrandlogoSuccessMessage: uploadHeaderBrandlogoSuccessMessageReducer,
  updateHeaderInfoSuccessMessage: updateHeaderInfoSuccessMessageReducer,
  manageSequenceHeaderLinkSuccessMessage: manageSequenceHeaderLinkSuccessMessageReducer,
  manageStateHeaderLinkSuccessMessage: manageStateHeaderLinkSuccessMessageReducer,
  deleteHeaderlinkSuccessMessage: deleteHeaderlinkSuccessMessageReducer,
  productDetails: productDetailsReducer,
  searchProducts: searchProductsReducer,
  searchProductsListAutocomplete: searchProductsListAutocompleteReducer,
  lookupOptionsDetails: lookupOptionsReducer,
  searchCategoriesDetails: searchCategoriesReducer,
  saveAndPublishProductDetails: saveAndPublishProductDetailsReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === resetActions.RESET_ALL_STATES) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
