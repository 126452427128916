import React, { Component } from 'react';
import { SvgIcon } from '../common';

import BrowserLeftImg from "../../assets/images/unsupport-img.png";
import BrowserChromeImg from "../../assets/images/ico-chrome.png";
import BrowserMozillaImg from "../../assets/images/ico-mozilla.png";
import BrowserSafariImg from "../../assets/images/ico-safari.png";
import BrowserOperaImg from "../../assets/images/ico-opera.png";
import BrowserMsEdgeImg from "../../assets/images/ico-edge.png";

class UnsupportedBrowserView extends Component {
    render() {
        return (
            <React.Fragment>
                <div style={{ backgroundImage: 'linear-gradient(#009FDD,#0072B2)', minHeight: '100%', minWidth: '100vh', position: 'relative' }}>
                    <div style={{ width: '450px', height: '450px', borderRadius: '450px', position: 'absolute', left: '-8%', top: '-8%', background: '#33B2E3' }}></div>
                    <div style={{ maxWidth: 1440, margin: 'auto', width: '90%', paddingLeft: '7px', position: 'relative' }}>
                        <div style={{ paddingTop: '5%' }}>
                            <SvgIcon name="logo" viewbox="0 0 235.976 25.128" width="200px" />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10%' }}>
                            <div style={{ width: '45%', position: 'relative', textAlign: 'center' }}>
                                <img style={{ width: '80%' }} src={BrowserLeftImg} alt={BrowserLeftImg} />
                            </div>
                            <div style={{ width: '55%' }}>
                                <p style={{ color: '#ffffff', fontSize: '1.2rem' }}>
                                    We build our product using the latest techniques and
                                    technology to make them faster and easier to use.
                                    Unfortunately, your browser doesn't support those
                                    technologies.
                                </p>
                                <p style={{ color: '#ffffff', fontSize: '1.55rem', marginTop: '40px' }}>
                                    Use one of these browsers and you'll be on your way.
                                </p>
                                <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                                    <li style={{ width: '30%', display: 'inline-block', marginBottom: '2%', marginRight: '2%' }}>
                                        <div style={{ background: '#ffffff', borderRadius: '7px', display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', paddingTop: '8px', paddingBottom: '8px' }}>
                                            <img style={{ height: '50px', marginRight: '8px' }} alt={BrowserChromeImg} src={BrowserChromeImg} />
                                            <div>
                                                <h4 style={{ fontWeight: '600', fontSize: '16px', marginBottom: '0' }}>Google Chrome</h4>
                                                <p style={{ marginBottom: '0' }}>Ver 83+</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li style={{ width: '30%', display: 'inline-block', marginBottom: '2%', marginRight: '2%' }}>
                                        <div style={{ background: '#ffffff', borderRadius: '7px', display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', paddingTop: '8px', paddingBottom: '8px' }}>
                                            <img style={{ height: '50px', marginRight: '8px' }} alt={BrowserMozillaImg} src={BrowserMozillaImg} />
                                            <div>
                                                <h4 style={{ fontWeight: '600', fontSize: '16px', marginBottom: '0' }}>Mozilla Firefox</h4>
                                                <p style={{ marginBottom: '0' }}>Ver 79+</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li style={{ width: '30%', display: 'inline-block', marginBottom: '2%', marginRight: '2%' }}>
                                        <div style={{ background: '#ffffff', borderRadius: '7px', display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', paddingTop: '8px', paddingBottom: '8px' }}>
                                            <img style={{ height: '50px', marginRight: '8px' }} alt={BrowserSafariImg} src={BrowserSafariImg} />
                                            <div>
                                                <h4 style={{ fontWeight: '600', fontSize: '16px', marginBottom: '0' }}>Safari</h4>
                                                <p style={{ marginBottom: '0' }}>Ver 10.1+</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li style={{ width: '30%', display: 'inline-block', marginBottom: '2%', marginRight: '2%' }}>
                                        <div style={{ background: '#ffffff', borderRadius: '7px', display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', paddingTop: '8px', paddingBottom: '8px' }}>
                                            <img style={{ height: '50px', marginRight: '8px' }} alt={BrowserOperaImg} src={BrowserOperaImg} />
                                            <div>
                                                <h4 style={{ fontWeight: '600', fontSize: '16px', marginBottom: '0' }}>Opera</h4>
                                                <p style={{ marginBottom: '0' }}>Ver 70+</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li style={{ width: '30%', display: 'inline-block', marginBottom: '2%', marginRight: '2%' }}>
                                        <div style={{ background: '#ffffff', borderRadius: '7px', display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', paddingTop: '8px', paddingBottom: '8px' }}>
                                            <img style={{ height: '50px', marginRight: '8px' }} alt={BrowserMsEdgeImg} src={BrowserMsEdgeImg} />
                                            <div>
                                                <h4 style={{ fontWeight: '600', fontSize: '16px', marginBottom: '0' }}>Microsoft Edge</h4>
                                                <p style={{ marginBottom: '0' }}>Ver 84+</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default UnsupportedBrowserView
