import React, { Component } from "react";
import { Button, Modal, Form, Input, Switch, Spin } from "antd";
import { RecommendedSize, SvgIcon } from "../../common";
import "./edit-footer-popup.scss";
import { connect } from "react-redux";
import {
  updateFooterDetails,
  createFooterLinks,
  resetStoreState,
  getFooterDetails,
  createLoadingSelector,
  updateFooterDetailsForImage,
  updateFooterLinks,
} from "../../../redux/action/index";
import { showAlert } from "../../../common/alert-messages";
import { isObjectEmpty, isObjectNotEmpty } from "../../../common/utils";
import { serverApis } from "../../../constants/server-apis";
const { TextArea } = Input;

class EditFooterPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      file: null,
      footer: {
        ...this.props.footerDetails,
      },
      logo_image_file: null,
    };
  }

  showModal = (event) => {
    this.setState({
      footer: { ...this.props.footerDetails },
      visible: true,
    });
  };
  componentDidUpdate() {
    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.updateFooterDetailsSuccessMessage)
    ) {
      this.setState({ footer: {} });
      showAlert.success(this.props.updateFooterDetailsSuccessMessage);
      this.props.resetStoreState(serverApis.updateFooterDetails.actionName);
      this.props.resetStoreState(serverApis.getFooterDetails.actionName);
      this.props.getFooterDetails();

      this.handleCancel();
    }
    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.updateFooterDetailsForImageSuccessMessage)
    ) {
      this.setState({ footer: {} });
      showAlert.success(this.props.updateFooterDetailsForImageSuccessMessage);
      this.props.resetStoreState(
        serverApis.updateFooterDetailsForImage.actionName
      );
      this.props.resetStoreState(serverApis.getFooterDetails.actionName);
      this.props.getFooterDetails();
      this.handleCancel();
    }

    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.createFooterLinksSuccessMessage)
    ) {
      this.setState({ footer: {} });
      showAlert.success(this.props.createFooterLinksSuccessMessage);
      this.props.resetStoreState(serverApis.createFooterLinks.actionName);
      this.props.resetStoreState(serverApis.getFooterDetails.actionName);
      this.props.getFooterDetails();
      this.handleCancel();
    }
    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.updateFooterLinksSuccessMessage)
    ) {
      this.setState({ footer: {} });
      showAlert.success(this.props.updateFooterLinksSuccessMessage);
      this.props.resetStoreState(serverApis.updateFooterLinks.actionName);
      this.props.resetStoreState(serverApis.getFooterDetails.actionName);
      this.props.getFooterDetails();
      this.handleCancel();
    }
  }
  handleOk = () => {
    this.setState({
      visible: false,
    });
    this.props.deleteCollection();
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleAboutText = (event) => {
    let footer = this.state.footer;

    let value = event.target.value; //field value
    footer["about_text"] = value;
    this.setState(footer);
  };
  handleSingleInput = (event) => {
    let footer = this.state.footer;
    let value = event.target.value; //field value
    let name = event.target.getAttribute("name"); //field name
    footer[name] = value;
    this.setState({ footer });
  };
  onChangeImage = (event) => {
    let footer = this.state.footer;
    // logo_image_file = event.target.files[0];
    this.setState({
      logo_image_file: event.target.files[0],

      file: URL.createObjectURL(event.target.files[0]),
      footer,
    });
    event.target.value = null;
  };

  coverresetFile = (event) => {
    event.preventDefault();

    let footer = this.state.footer;
    // this.state.logo_image_file = null;
    this.setState({
      file: null,
      logo_image_file: null,
      footer,
    });
  };

  handleUpdateAndCreate = () => {
    if (this.props.modalButton === "Create link") {
      let requestObject = {
        title: isObjectEmpty(this.state.footer.title)
          ? this.props.values
          : this.state.footer.title,
        url: isObjectEmpty(this.state.footer.url)
          ? this.props.url
          : this.state.footer.url,
        link_parent_type: this.props.link_parent_type,
      };
      this.props.createFooterLinks(requestObject);
    } else if (
      this.props.editlinkmodal &&
      isObjectNotEmpty(this.props.url) &&
      isObjectNotEmpty(this.props.values)
    ) {
      let requestObject = {
        title: isObjectEmpty(this.state.footer.title)
          ? this.props.values
          : this.state.footer.title,
        url: isObjectEmpty(this.state.footer.url)
          ? this.props.url
          : this.state.footer.url,
        link_parent_type: this.props.link_parent_type,
      };
      this.props.updateFooterLinks(requestObject, this.props.linkId);
    } else if (this.props.modaltitle === "Upload Logo") {
      if (isObjectEmpty(this.state.file)) {
        showAlert.error("Please add an image.");
      } else {
        this.props.updateFooterDetailsForImage(this.state.logo_image_file);
      }
    } else {
      if (this.props.modalButton === "save link") {
        let requestObject = {};
        if (this.props.labelName === "terms_of_use_title") {
          requestObject = {
            terms_of_use_title: isObjectEmpty(this.state.footer.title)
              ? this.props.values
              : this.state.footer.title,
            terms_of_use_url: isObjectEmpty(this.state.footer.url)
              ? this.props.url
              : this.state.footer.url,
          };
        } else {
          requestObject = {
            privacy_policy_title: isObjectEmpty(this.state.footer.title)
              ? this.props.values
              : this.state.footer.title,
            privacy_policy_url: isObjectEmpty(this.state.footer.url)
              ? this.props.url
              : this.state.footer.url,
          };
        }

        this.props.updateFooterDetails(requestObject);
      } else {
        console.log("updateFooterDetails");
        this.props.updateFooterDetails(this.state.footer);
      }
    }
  };

  handleLinkTitle = (event) => {
    if (this.props.editlinkmodal) {
      let footer = this.state.footer;
      let value = event.target.value; //field value
      let name = event.target.getAttribute("name"); //field name
      footer[name] = value;
      footer["link_parent_type"] = this.props.link_parent_type;
      this.setState({ footer });
    } else {
      let footer = this.state.footer;
      let value = event.target.value; //field value
      let name = event.target.getAttribute("name"); //field name
      footer[name] = value;
      footer["link_parent_type"] = this.props.link_parent_type;
      this.setState({ footer });
    }
  };

  socialLinkTextChanged = (event) => {
    let footer = this.state.footer;

    let value = event.target.value; //field value
    let name = event.target.name;
    footer[name] = value;
    this.setState(footer);
  };

  socialLinkStatusChange = (checked, event) => {
    let footer = this.state.footer;

    let name = event.target.name;
    footer[name] = checked;

    this.setState(footer);
  };

  render() {
    const { visible } = this.state;

    let initialValues = {};

    if (this.props.singleinput) {
      initialValues = { single_input: this.props.values };
    } else if (this.props.sociallinks) {
      initialValues = { ...this.state.footer };
    } else if (this.props.abouttext) {
      initialValues = { about_text: this.props.values };
    } else if (this.props.linkurl && this.props.urlPolicyOrTerm) {
      initialValues = {
        title: this.props.values,
        url: this.props.urlPolicyOrTerm,
      };
    } else if (this.props.linkurl) {
      initialValues = {
        title: this.props.values,
        url: this.props.url,
      };
    }
    return (
      <React.Fragment>
        {this.props.editlinkmodal && (
          <div
            onClick={(event) => {
              event.stopPropagation();
              this.showModal();
            }}
            className="footer-edit-button"
          >
            <SvgIcon
              name="pencil"
              viewbox="0 0 16.19 16.19"
              onClick={this.handleLinkEdit}
            />
          </div>
        )}
        {this.props.addlinkmodal && (
          <div
            className="add-link-footer"
            onClick={(event) => {
              event.stopPropagation();
              this.showModal();
            }}
          >
            <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Create Link
          </div>
        )}
        <Modal
          visible={visible}
          title={this.props.modaltitle}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <Button
              key="id"
              type="primary"
              form={this.props.formId}
              htmlType="submit"
              name={this.props.modaltitle}
              block
            >
              {" "}
              {this.props.modalButton}{" "}
            </Button>,
          ]}
          className="modal-cms footeredit-modal"
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="create-collection-modal">
              <Form
                layout="vertical"
                id={this.props.formId}
                initialValues={{ ...initialValues }}
                onFinish={this.handleUpdateAndCreate}
              >
                {this.props.abouttext && (
                  <React.Fragment>
                    <div>
                      <label htmlFor="about_text">About Text </label>
                      <Form.Item
                        className="m-0"
                        id="about_text"
                        name="about_text"
                      >
                        <TextArea
                          rows="4"
                          name="about_text"
                          size="large"
                          placeholder="Enter"
                          defaultValue={this.props.value}
                          onChange={this.handleAboutText}
                        />
                      </Form.Item>
                    </div>
                  </React.Fragment>
                )}
                {this.props.singleinput && (
                  <React.Fragment>
                    <div>
                      <label htmlFor="single_input">{this.props.label} </label>
                      <Form.Item
                        className="m-0"
                        id="single_input"
                        name="single_input"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Title!",
                          },
                        ]}
                      >
                        <Input
                          name={this.props.labelName}
                          size="large"
                          placeholder="Enter Title"
                          value={this.props.values}
                          onChange={this.handleSingleInput}
                        />
                      </Form.Item>
                    </div>
                  </React.Fragment>
                )}
                {this.props.linkurl && (
                  <React.Fragment>
                    <div>
                      <label htmlFor="link_title">Link Title </label>
                      <Form.Item
                        className="m-0"
                        id="title"
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Title!",
                          },
                        ]}
                      >
                        <Input
                          name="title"
                          size="large"
                          placeholder="Enter Title"
                          defaultValue={this.props.values}
                          onChange={this.handleLinkTitle}
                        />
                      </Form.Item>
                    </div>
                    <div className="mt-4">
                      <label htmlFor="staticbanner_url">URL</label>
                      <Form.Item
                        className="m-0"
                        id="url"
                        name="url"
                        rules={[
                          {
                            required: true,
                            message: "Please enter URL!",
                          },
                        ]}
                      >
                        <Input
                          name="url"
                          size="large"
                          placeholder="Enter URL"
                          defaultValue={
                            isObjectNotEmpty(this.props.urlPolicyOrTerm)
                              ? this.props.urlPolicyOrTerm
                              : this.props.url
                          }
                          onChange={this.handleLinkTitle}
                        />
                      </Form.Item>
                    </div>
                  </React.Fragment>
                )}
                {this.props.uploadImage && (
                  <>
                    <div className="mb-2 d-flex">
                      <div className="imgthumbnail-big w-100 mr-0">
                        <div className="imgthumbnail-big-inner">
                          <div className="uploadcoverthumbnail">
                            {this.state.file && (
                              <div className="removethumb-coverimg">
                                <div
                                  className="close-icon"
                                  onClick={this.coverresetFile}
                                >
                                  <SvgIcon
                                    name="close"
                                    viewbox="0 0 298.667 298.667"
                                  />
                                </div>
                              </div>
                            )}
                            {this.state.file === null && (
                              <div className="uploadcoverthumbnail-link">
                                <input
                                  type="file"
                                  onChange={this.onChangeImage}
                                />
                                <SvgIcon name="plus-circle" viewbox="0 0 19 19" />{" "}
                                Add Image
                              </div>
                            )}
                            {this.state.file && (
                              <div className="uploadedthumbimg-wrape">
                                <img
                                  alt={this.state.file}
                                  className="uploadedthumbimg-big"
                                  src={this.state.file}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <RecommendedSize width='300' height='140' />
                  </>
                )}
                {this.props.sociallinks && (
                  <React.Fragment>
                    <div className="mb-2">
                      <label htmlFor="facebook_link">Facebook</label>
                      <div className="d-flex align-items-center w-100">
                        <Form.Item
                          className="m-0 social-link-input w-100 mr-2"
                          id="facebook_url"
                          name="facebook_url"
                        >
                          <Input
                            name="facebook_url"
                            size="large"
                            addonBefore={
                              <SvgIcon
                                name="facebook"
                                viewbox="0 0 23.101 23.101"
                              />
                            }
                            placeholder="Enter Facebook Link"
                            value={this.state.footer.facebook_url}
                            onChange={this.socialLinkTextChanged}
                          />
                        </Form.Item>
                        <Switch
                          name={"facebook_active"}
                          checked={this.state.footer.facebook_active}
                          onChange={this.socialLinkStatusChange}
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <label htmlFor="twitter_url">Twitter</label>
                      <div className="d-flex align-items-center w-100">
                        <Form.Item
                          className="m-0 social-link-input w-100 mr-2"
                          id="twitter_url"
                          name="twitter_url"
                        >
                          <Input
                            name="twitter_url"
                            size="large"
                            addonBefore={
                              <SvgIcon name="twitter" viewbox="0 0 512 512" />
                            }
                            placeholder="Enter Twitter Link"
                            value={this.state.footer.twitter_url}
                            onChange={this.socialLinkTextChanged}
                          />
                        </Form.Item>
                        <Switch
                          name={"twitter_active"}
                          checked={this.state.footer.twitter_active}
                          onChange={this.socialLinkStatusChange}
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <label htmlFor="linkedin_url">Linkedin</label>
                      <div className="d-flex align-items-center w-100">
                        <Form.Item
                          className="m-0 social-link-input w-100 mr-2"
                          id="linkedin_url"
                          name="linkedin_url"
                        >
                          <Input
                            name="linkedin_url"
                            size="large"
                            addonBefore={
                              <SvgIcon name="linkedin" viewbox="0 0 24 24" />
                            }
                            placeholder="Enter Linkedin Link"
                            value={this.state.footer.linkedin_url}
                            onChange={this.socialLinkTextChanged}
                          />
                        </Form.Item>
                        <Switch
                          name={"linkedin_active"}
                          checked={this.state.footer.linkedin_active}
                          onChange={this.socialLinkStatusChange}
                        />
                      </div>
                    </div>
                    {/* <div className="mb-2">
                      <label htmlFor="instagram_url">Instagram</label>
                      <div className="d-flex align-items-center w-100">
                        <Form.Item
                          className="m-0 social-link-input w-100 mr-2"
                          id="instagram_url"
                          name="instagram_url"
                        >
                          <Input
                            name="instagram_url"
                            size="large"
                            addonBefore={
                              <SvgIcon
                                name="instagram"
                                viewbox="0 0 169.063 169.063"
                              />
                            }
                            placeholder="Enter Instagram Link"
                            value={this.state.footer.instagram_url}
                            onChange={this.socialLinkTextChanged}
                          />
                        </Form.Item>
                        <Switch
                          name={"instagram_active"}
                          checked={this.state.footer.instagram_active}
                          onChange={this.socialLinkStatusChange}
                        />
                      </div>
                    </div> */}
                    <div className="mb-2">
                      <label htmlFor="pinterest_url">Tiktok</label>
                      <div className="d-flex align-items-center w-100">
                        <Form.Item
                          className="m-0 social-link-input w-100 mr-2"
                          id="tiktok_url"
                          name="tiktok_url"
                        >
                          <Input
                            name="tiktok_url"
                            size="large"
                            addonBefore={
                              <SvgIcon name="tiktok" viewbox="-32 0 512 512" />
                            }
                            placeholder="Enter Tiktok Link"
                            value={this.state.footer.tiktok_url}
                            onChange={this.socialLinkTextChanged}
                          />
                        </Form.Item>
                        <Switch
                          name={"tiktok_active"}
                          checked={this.state.footer.tiktok_active}
                          onChange={this.socialLinkStatusChange}
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <label htmlFor="pinterest_url">Youtube</label>
                      <div className="d-flex align-items-center w-100">
                        <Form.Item
                          className="m-0 social-link-input w-100 mr-2"
                          id="youtube_url"
                          name="youtube_url"
                        >
                          <Input
                            name="youtube_url"
                            size="large"
                            addonBefore={
                              <SvgIcon name="youtube" viewbox="0 0 20 14" />
                            }
                            placeholder="Enter Youtube Link"
                            value={this.state.footer.youtube_url}
                            onChange={this.socialLinkTextChanged}
                          />
                        </Form.Item>
                        <Switch
                          name={"youtube_active"}
                          checked={this.state.footer.youtube_active}
                          onChange={this.socialLinkStatusChange}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Form>
            </div>
          </Spin>{" "}
        </Modal>
      </React.Fragment>
    );
  }
}
const loadingSelector = createLoadingSelector([
  serverApis.getFooterDetails.actionName,
  serverApis.createFooterLinks.actionName,

  serverApis.updateFooterDetailsForImage.actionName,
  serverApis.updateFooterDetails.actionName,
  serverApis.updateFooterLinks.actionName,
]);
const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),

  createFooterLinksSuccessMessage: state.createFooterLinksSuccessMessage,
  footerDetails: state.footerDetails,
  updateFooterDetailsSuccessMessage: state.updateFooterDetailsSuccessMessage,
  updateFooterLinksSuccessMessage: state.updateFooterLinksSuccessMessage,
  updateFooterDetailsForImageSuccessMessage:
    state.updateFooterDetailsForImageSuccessMessage,
});

export default connect(mapStateToProps, {
  updateFooterDetails,
  updateFooterDetailsForImage,
  createFooterLinks,
  resetStoreState,
  getFooterDetails,
  updateFooterLinks,
})(EditFooterPopup);
