import React, { Component } from "react";
import { Button, Modal, Form, Input, Spin } from "antd";
import { ImageLoader, RecommendedSize, SvgIcon } from "../../common";
import "./footer-logos-popup.scss";
import { connect } from "react-redux";
import {
  resetStoreState,
  createLoadingSelector,
  addFooterLogo,
  updateFooterLogo,
  getFooterDetails,
} from "../../../redux/action";
import { serverApis } from "../../../constants/server-apis";
import { isObjectEmpty, isObjectNotEmpty } from "../../../common/utils";
import { showAlert } from "../../../common/alert-messages";
import { baseServerAdress } from "../../../constants/server-address";

class FooterLogosPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      inputFields: this.getInitialInputFields(),
      file: null,
      logo_image_file: null,
    };
  }

  formRef = React.createRef();

  getInitialInputFields = () => {
    return isObjectEmpty(this.props.logoDetails)
      ? { membership_name: "", membership_number: "", image_file: null }
      : { ...this.props.logoDetails, image_file: null };
  };

  showModal = (event) => {
    this.setState({
      visible: true,
      inputFields: this.getInitialInputFields(),
      file: null,
      logo_image_file: null,
    });

    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        ...this.getInitialInputFields(),
      });
    }
  };

  componentDidUpdate() {
    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.addFooterLogoSuccessMessage)
    ) {
      this.setState({ inputFields: {} });
      showAlert.success(this.props.addFooterLogoSuccessMessage);
      this.props.resetStoreState(serverApis.addFooterLogo.actionName);
      this.props.resetStoreState(serverApis.getFooterDetails.actionName);
      this.props.getFooterDetails();

      this.handleCancel();
    }
    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.updateFooterLogoSuccessMessage)
    ) {
      this.setState({ inputFields: {} });
      showAlert.success(this.props.updateFooterLogoSuccessMessage);
      this.props.resetStoreState(serverApis.updateFooterLogo.actionName);
      this.props.resetStoreState(serverApis.getFooterDetails.actionName);
      this.props.getFooterDetails();

      this.handleCancel();
    }
  }
  handleOk = () => {
    this.setState({
      visible: false,
    });
    this.props.deleteCollection();
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  coverresetFile = (event) => {
    event.preventDefault();

    let inputFields = this.state.inputFields;
    inputFields.image_file = null;
    this.setState({
      file: null,
      logo_image_file: null,
      inputFields,
    });
  };
  handleInputFields = (event) => {
    let inputFields = this.state.inputFields;
    let value = event.target.value; //field value
    let name = event.target.getAttribute("name"); //field name
    inputFields[name] = value;
    this.setState({ inputFields });
  };
  handleAddUpdateLogo = () => {
    let inputFields = this.state.inputFields;
    let imgFields = {};
    if (inputFields.image_file !== null) {
      imgFields = { image_file: inputFields.image_file };
    }

    let requestObject = {
      ...imgFields,
      membership_name: inputFields.membership_name,
      membership_number: inputFields.membership_number,
    };

    if (isObjectNotEmpty(this.props.logoDetails)) {
      this.props.updateFooterLogo(requestObject, this.props.logoDetails.id);
    } else {
      this.props.addFooterLogo(requestObject);
    }
  };
  onChangeImage = (event) => {
    let inputFields = this.state.inputFields;
    let image_file = event.target.files[0];
    inputFields["image_file"] = image_file;

    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
      inputFields,
    });
    event.target.value = null;
  };

  render() {
    const { visible } = this.state;
    if (visible) {
      console.log(this.state.inputFields);
      console.log(this.props);
    }
    return (
      <React.Fragment>
        {isObjectNotEmpty(this.props.logoDetails) && (
          <Button
            onClick={(event) => {
              event.stopPropagation();
              this.showModal();
            }}
            className="edit-button"
            type="primary"
          >
            <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />
          </Button>
        )}
        {isObjectEmpty(this.props.logoDetails) && (
          <div
            className="add-more-logo"
            onClick={(event) => {
              event.stopPropagation();
              this.showModal();
            }}
          >
            <div>
              <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Add More Logo
            </div>
          </div>
        )}
        <Modal
          visible={visible}
          title={this.props.modaltitle}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <Button
              key="1"
              type="primary"
              block
              htmlType="submit"
              form={this.props.formId}
            >
              {" "}
              {this.props.modalButton}{" "}
            </Button>,
          ]}
          className="modal-cms footeredit-modal"
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="create-collection-modal">
              <Form
                layout="vertical"
                id={this.props.formId}
                onFinish={this.handleAddUpdateLogo}
                initialValues={{ ...this.state.inputFields }}
                ref={this.formRef}
              >
                <div className="mb-3">
                  <label htmlFor="link_title">Membership name</label>
                  <Form.Item
                    className="m-0"
                    id="membership_name"
                    name="membership_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter membership name!",
                      },
                    ]}
                  >
                    <Input
                      name="membership_name"
                      size="large"
                      placeholder="Enter membership name"
                      value={this.state.inputFields.membership_name}
                      onChange={this.handleInputFields}
                    />
                  </Form.Item>
                </div>
                <div className="mb-4">
                  <label htmlFor="link_title">Membership number</label>
                  <Form.Item
                    className="m-0"
                    id="membership_number"
                    name="membership_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter membership number!",
                      },
                    ]}
                  >
                    <Input
                      name="membership_number"
                      size="large"
                      placeholder="Enter membership number"
                      value={this.state.inputFields.membership_number}
                      onChange={this.handleInputFields}
                    />
                  </Form.Item>
                </div>
                <div className="mb-2 d-flex">
                  <div className="imgthumbnail-big w-100 mr-0">
                    <div className="imgthumbnail-big-inner">
                      <div className="uploadcoverthumbnail">
                        {this.state.file && (
                          <div className="removethumb-coverimg">
                            <div
                              className="close-icon"
                              onClick={this.coverresetFile}
                            >
                              <SvgIcon
                                name="close"
                                viewbox="0 0 298.667 298.667"
                              />
                            </div>
                          </div>
                        )}
                        {this.state.file === null &&
                          isObjectEmpty(this.props.logoDetails) && (
                            <div className="uploadcoverthumbnail-link">
                              <input
                                type="file"
                                onChange={this.onChangeImage}
                              />
                              <SvgIcon name="plus-circle" viewbox="0 0 19 19" />{" "}
                              Add Image
                            </div>
                          )}
                        {isObjectNotEmpty(this.props.logoDetails) &&
                          isObjectEmpty(
                            this.props.logoDetails.membership_logo
                          ) && (
                            <div className="uploadcoverthumbnail-link">
                              <input
                                type="file"
                                onChange={this.onChangeImage}
                              />
                              <SvgIcon name="plus-circle" viewbox="0 0 19 19" />{" "}
                              Add Image
                            </div>
                          )}
                        {(this.state.file ||
                          (isObjectNotEmpty(this.props.logoDetails) &&
                            isObjectNotEmpty(
                              this.props.logoDetails.membership_logo
                            ))) && (
                          <div>
                            <div className="editcover-img">
                              <SvgIcon
                                name="pencil"
                                viewbox="0 0 16.19 16.19"
                              />{" "}
                              Change Cover Image
                              <input
                                type="file"
                                onChange={this.onChangeImage}
                              />
                            </div>
                          </div>
                        )}
                        {(this.state.file ||
                          (isObjectNotEmpty(this.props.logoDetails) &&
                            isObjectNotEmpty(
                              this.props.logoDetails.membership_logo
                            ))) && (
                          <div className="uploadedthumbimg-wrape">
                            <ImageLoader
                              className="uploadedthumbimg-big"
                              alt={"Cover"}
                              src={
                                this.state.file
                                  ? this.state.file
                                  : baseServerAdress.IMAGE +
                                    this.props.logoDetails.membership_logo
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <RecommendedSize width='140' height='72' />
              </Form>
            </div>
          </Spin>{" "}
        </Modal>
      </React.Fragment>
    );
  }
}
const loadingSelector = createLoadingSelector([
  serverApis.addFooterLogo.actionName,
  serverApis.updateFooterLogo.actionName,
]);
const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),

  addFooterLogoSuccessMessage: state.addFooterLogoSuccessMessage,
  updateFooterLogoSuccessMessage: state.updateFooterLogoSuccessMessage,
});

export default connect(mapStateToProps, {
  addFooterLogo,
  updateFooterLogo,
  getFooterDetails,
  resetStoreState,
})(FooterLogosPopup);
