import React, { Component } from "react";
import { Button } from "antd";
import { Row, Col, SvgIcon, ImageLoader } from "../../common";
import "./brand-logo.scss";

import { isObjectNotEmpty } from "../../../common/utils";
import { baseServerAdress } from "../../../constants/server-address";

class BrandLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <div className="position-relative brandlogo-upload">
              {/* {this.state.file && ( */}
              <div className="uploadedthumbimg-wrape">
                <Button
                  title="Edit Static Banner"
                  type="primary"
                  size="small"
                  className="image-edit-button live-edit"
                >
                  <input
                    type="file"
                    onChange={this.props.onChangeBrandlogoImage}
                  />
                  <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />
                </Button>
                {/* <img className="uploadedthumbimg-big" src={this.state.file} /> */}
                <ImageLoader
                  alt="logo"
                  className="uploadedthumbimg-big w-100"
                  src={
                    isObjectNotEmpty(this.props.brandlogoFile)
                      ? this.props.brandlogoFile
                      : baseServerAdress.IMAGE +
                        this.props.logoImage +
                        `?${Date()}`
                  }
                />
              </div>
              {/* )} */}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default BrandLogo;
