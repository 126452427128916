import React, { Component } from "react";
import { Modal, Button, Select, Spin } from "antd";
import { SvgIcon, Row, Col } from "../../../common";
import "./add-related-products-code.scss";
import { connect } from "react-redux";
import {
  createLoadingSelector,
  getSearchProductsListAutocomplete,
  resetStoreState,
} from "../../../../redux/action";
import { serverApis } from "../../../../constants/server-apis";
import { isObjectEmpty, isObjectNotEmpty } from "../../../../common/utils";

class AddRelatedProductsCodeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedItemsForAddItem: [],
      selectedItemsObjects: [],
    };
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
      selectedItemsObjects: [],
    });
  };

  handleSearch = (value) => {
    this.props.resetStoreState(
      serverApis.getSearchProductsListAutocomplete.actionName
    );
    this.setState({ productCode: value });
    if (isObjectNotEmpty(value) && value.length > 2) {
      this.props.getSearchProductsListAutocomplete(value);
    }
  };
  handleSave = (key) => {
    this.props.handleSave(key, this.state.selectedItemsObjects);
    this.hideModal();
  };
  handleChangeForAdditem = (selectedItemsForAddItem, option) => {
    const selectedItemsObjects = this.state.selectedItemsObjects;
    selectedItemsObjects.push(option.object);
    this.setState({ selectedItemsForAddItem, selectedItemsObjects });
  };
  handleDeselect = (deselectedItem, option) => {
    let selectedItemsObjects = this.state.selectedItemsObjects;
    let removedProduct = selectedItemsObjects.filter(
      (product) => product.web_code === deselectedItem
    );
    const index = selectedItemsObjects.indexOf(removedProduct[0]);
    if (index > -1) {
      selectedItemsObjects.splice(index, 1);
    }
    this.setState({ selectedItemsObjects });
  };

  render() {
    return (
      <React.Fragment>
        <li className="add-related-code" onClick={this.showModal}>
          <div className="add-related-code-inner">
            <SvgIcon name="plus" viewbox="0 0 492 492" /> Add New
          </div>
        </li>
        <Modal
          title="Add New Code"
          visible={this.state.visible}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          okText="ok"
          cancelText="Cancel"
          className="modal-cms addrelated-productcode"
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <div>
              <Button onClick={this.hideModal}>Cancel</Button>
              <Button
                type="primary"
                onClick={() => this.handleSave(this.props.apiKey)}
                disabled={
                  isObjectEmpty(this.state.selectedItemsObjects) ? true : false
                }
              >
                Save
              </Button>
            </div>,
          ]}
        >
          {" "}
          <Spin spinning={this.props.isFetchingDataSuggestion} size="large">
            <Row>
              <Col>
                <Select
                  mode="multiple"
                  placeholder="Input first 3 characters"
                  value={this.state.selectedItemsObjects.map((item) => {
                    return item.web_code;
                  })}
                  style={{ width: "100%" }}
                  size="large"
                  onBlur={() => {
                    this.props.resetStoreState(
                      serverApis.getSearchProductsListAutocomplete.actionName
                    );
                  }}
                  showArrow
                  showSearch
                  className="select-onsearch"
                  onSearch={this.handleSearch}
                  onSelect={this.handleChangeForAdditem}
                  onDeselect={this.handleDeselect}
                >
                  {this.props.searchProductsListAutocomplete.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.web_code}
                      object={item}
                    >
                      {item.web_code}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            {/* <Row className="mt-3">
            <Col>
              <div className="related-product-code">
                <ul>
                  <li>
                    <div className="remove-codeicon">
                      <div className="close-icon">
                        <SvgIcon name="close" viewbox="0 0 298.667 298.667" />
                      </div>
                    </div>
                    CO020
                  </li>
                  <li>
                    <div className="remove-codeicon">
                      <div className="close-icon">
                        <SvgIcon name="close" viewbox="0 0 298.667 298.667" />
                      </div>
                    </div>
                    CO020
                  </li>
                  <li>
                    <div className="remove-codeicon">
                      <div className="close-icon">
                        <SvgIcon name="close" viewbox="0 0 298.667 298.667" />
                      </div>
                    </div>
                    CO020
                  </li>
                  <li>
                    <div className="remove-codeicon">
                      <div className="close-icon">
                        <SvgIcon name="close" viewbox="0 0 298.667 298.667" />
                      </div>
                    </div>
                    CO020
                  </li>
                  <li>
                    <div className="remove-codeicon">
                      <div className="close-icon">
                        <SvgIcon name="close" viewbox="0 0 298.667 298.667" />
                      </div>
                    </div>
                    CO020
                  </li>
                  <li>
                    <div className="remove-codeicon">
                      <div className="close-icon">
                        <SvgIcon name="close" viewbox="0 0 298.667 298.667" />
                      </div>
                    </div>
                    CO020
                  </li>
                </ul>
              </div>
            </Col>
          </Row>*/}
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}
const loadingSelectorSuggestion = createLoadingSelector([
  serverApis.getSearchProductsListAutocomplete.actionName,
]);
const mapStateToProps = (state) => ({
  isFetchingDataSuggestion: loadingSelectorSuggestion(state),

  searchProductsListAutocomplete: state.searchProductsListAutocomplete,
});

export default connect(mapStateToProps, {
  getSearchProductsListAutocomplete,
  resetStoreState,
})(AddRelatedProductsCodeView);
