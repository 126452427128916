import React, { Component } from "react";
import { Checkbox } from "antd";
import LinesEllipsis from "react-lines-ellipsis";
import { Col, ImageLoader } from "../../../components/common";

import "./product-card.scss";

class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col sm="4" className="mb-3 pr-0">
        <div className="product-card-main">
          <div className="product-card-img">
            <div className="img-wrape">
              <div className="image-thumb">
                <div className="imagethumb-inner">
                  <ImageLoader
                    alt={this.props.prdoductName}
                    title={this.props.prdoductName}
                    src={this.props.prdoductImg}
                    className=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="product-card-right">
            <div className="code">#{this.props.productCode}</div>
            <div className="product-name">
              {/* {this.props.prdoductName} */}
              <LinesEllipsis
                text={this.props.prdoductName}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
            {
              <div className="check-product">
                {" "}
                <Checkbox
                  checked={this.props.isChecked}
                  onChange={(e) =>
                    this.props.onSelectItem(
                      e.target.checked,
                      this.props.productKey
                    )
                  }
                ></Checkbox>{" "}
              </div>
            }
          </div>
        </div>
      </Col>
    );
  }
}

export default ProductCard;
