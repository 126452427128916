import React, { Component } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../common";
import "./product-info-menu.scss";
import { connect } from "react-redux";
import { serverApis } from "../../../constants/server-apis";
import { createLoadingSelector } from "../../../redux/action";
import { isObjectNotEmpty } from "../../../common/utils";

const { SubMenu } = Menu;

class ProductInfoMenu extends Component {
  componentDidMount() {
    document.getElementById("product_info_link").classList.add("active");
  }

  componentWillUnmount() {
    document.getElementById("product_info_link").classList.remove("active");
  }

  render() {
    return (
      <React.Fragment>
        <div className="productinfo-menu">
          <div className="productinfo-menu-inner">
            <div className="mb-1 back-produclist">
              <Link to="/product-list">
                <SvgIcon
                  name="left-arrow"
                  width="6"
                  className="mr-1"
                  fill="#0075B6"
                  viewbox="0 0 13.503 23.616"
                />{" "}
                Product List
              </Link>
            </div>
            <Menu
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["0", "1", "2", "3", "4", "5"]}
              mode="inline"
              forceSubMenuRender={true}
              selectedKeys={this.props.selectedMenuKey}
            >
              {isObjectNotEmpty(this.props.productDetails.product_menu_details)
                ? this.props.productDetails.product_menu_details.map(
                    (item, i) => {
                      return (
                        <SubMenu key={i} title={item.menu}>
                          {item.sub_menu.map((submenu, index) => {
                            return (
                              <Menu.Item
                                onClick={() => {
                                  this.props.onClickLeftMenu(submenu, i, index);
                                }}
                                key={`${i}${index}`}
                              >
                                {submenu.name}
                              </Menu.Item>
                            );
                          })}
                        </SubMenu>
                      );
                    }
                  )
                : ""}
            </Menu>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getProductDetails.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  productDetails: state.productDetails,
});

export default connect(mapStateToProps, {})(ProductInfoMenu);
