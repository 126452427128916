import React, { Component } from "react";
import { Collapse, Button, Switch, Spin } from "antd";
import { Row, Col, RecommendedSize } from "../common";
import "./header.scss";

import BrandLogo from "./brand-logo/brand-logo";
import HeaderSearch from "./header-search/header-search";
import Headerlinks from "./header-links/header-links";
import { connect } from "react-redux";
import { serverApis } from "../../constants/server-apis";
import {
  createLoadingSelector,
  getHeaderDetails,
  uploadHeaderBrandlogo,
  resetStoreState,
  updateHeaderInfo,
  manageSequenceHeaderLink,
} from "../../redux/action";
import { isObjectEmpty, isObjectNotEmpty } from "../../common/utils";
import { showAlert } from "../../common/alert-messages";

const { Panel } = Collapse;

class HeaderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
      disableLinkSave: false,
      disableSearchSave: false,
    };
  }
  getInitialState = () => {
    return {
      brandlogoFile: null,
      logo_image_file: null,
      requestObject: {},
    };
  };
  refreshHeaderState = () => {
    let initialObj = { ...this.getInitialState() };
    this.setState({
      ...initialObj,
    });
  };
  componentDidMount() {
    this.props.getHeaderDetails();
  }
  componentDidUpdate() {
    if (
      isObjectNotEmpty(this.props.headerDetails) &&
      isObjectEmpty(this.state.requestObject)
    ) {
      this.setState({
        requestObject: this.props.headerDetails,
      });
    }
    if (isObjectNotEmpty(this.props.manageSequenceHeaderLinkSuccessMessage)) {
      this.setState({ disableLinkSave: false });

      showAlert.success(this.props.manageSequenceHeaderLinkSuccessMessage);
      this.props.resetStoreState(
        serverApis.manageSequenceHeaderLink.actionName
      );
      this.props.resetStoreState(serverApis.getHeaderDetails.actionName);
      this.props.getHeaderDetails();
    }

    if (isObjectNotEmpty(this.props.uploadHeaderBrandlogoSuccessMessage)) {
      this.setState({ brandlogoFile: null, logo_image_file: null });
      showAlert.success(this.props.uploadHeaderBrandlogoSuccessMessage);
      this.props.resetStoreState(serverApis.uploadHeaderBrandlogo.actionName);
      this.props.resetStoreState(serverApis.getHeaderDetails.actionName);
      this.props.getHeaderDetails();
    }
    if (isObjectNotEmpty(this.props.updateHeaderInfoSuccessMessage)) {
      this.setState({ requestObject: {}, disableSearchSave: false });
      showAlert.success(this.props.updateHeaderInfoSuccessMessage);
      this.props.resetStoreState(serverApis.updateHeaderInfo.actionName);
      this.props.resetStoreState(serverApis.getHeaderDetails.actionName);
      this.props.getHeaderDetails();
    }
  }
  onChangeBrandlogoImage = (event) => {
    this.setState({
      brandlogoFile: URL.createObjectURL(event.target.files[0]),
      logo_image_file: event.target.files[0],
    });
  };
  onChangeHeaderInfo = (event) => {
    let requestObject = this.state.requestObject;
    requestObject.search_label = event.target.value;
    this.setState({
      requestObject,
      disableSearchSave: true,
    });
  };
  handleSaveForSearch = () => {
    let requestObject = this.state.requestObject;
    let objPayload = {};
    objPayload = {
      search_label: requestObject.search_label,
    };
    this.props.updateHeaderInfo(objPayload);
  };
  onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area
    let requestObject = this.state.requestObject;

    const items = [...requestObject.links];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    requestObject.links = items;
    this.setState({ requestObject, disableLinkSave: true });
  };
  handleSaveForLink = () => {
    let reqObj = {
      header_links: this.state.requestObject.links,
    };
    this.props.manageSequenceHeaderLink(reqObj);
  };
  panelHeaderBrandLogo = () => (
    <React.Fragment>
      <React.Fragment>
        <div className="panel-header">
          <div className="title-left d-flex align-items-center">Brand Logo</div>
          <div
            className="save-btn"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button
              disabled={this.state.logo_image_file === null ? true : false}
              size="small"
              className="btn-success btn-md radius px-3"
              onClick={() => {
                this.props.uploadHeaderBrandlogo(this.state.logo_image_file);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
  panelHeaderSearch = () => (
    <React.Fragment>
      <React.Fragment>
        <div className="panel-header">
          <div className="title-left d-flex align-items-center">Search</div>
          <div
            className="save-btn"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button
              disabled={!this.state.disableSearchSave}
              size="small"
              className="btn-success btn-md radius px-3"
              onClick={this.handleSaveForSearch}
            >
              Save
            </Button>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
  panelHeaderlinks = () => {
    return (
      <React.Fragment>
        <React.Fragment>
          <div className="panel-header">
            <div className="title-left d-flex align-items-center">Links</div>
            <div
              className="title-switch"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Switch
                checked={this.props.headerDetails.links_active}
                className="switch-cms"
                size="large"
                onChange={(checked) => {
                  this.props.updateHeaderInfo({ links_active: checked });
                }}
              />
            </div>
            <div
              className="save-btn"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Button
                disabled={!this.state.disableLinkSave}
                size="small"
                className="btn-success btn-md radius px-3"
                onClick={this.handleSaveForLink}
              >
                Save
              </Button>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  };
  render() {
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <React.Fragment>
          <div className="right-wrapper">
            <div className="right-wrapper-inner">
              <Row>
                <Col sm="6"></Col>
                <Col sm="6" className="text-right">
                  <Button className="btn-white btn-md radius mr-3">
                    Reset
                  </Button>
                  <Button className="btn-success btn-md radius">
                    Save sequence
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="card-lists-items">
                    <Collapse
                      className="header-collapse-cards"
                      accordion
                      expandIconPosition="right"
                      defaultActiveKey={["1"]}
                    >
                      <Panel header={this.panelHeaderBrandLogo()} key="1">
                        <BrandLogo
                          logoImage={
                            isObjectEmpty(this.props.headerDetails)
                              ? ""
                              : this.props.headerDetails.logo_image
                          }
                          brandlogoFile={this.state.brandlogoFile}
                          onChangeBrandlogoImage={this.onChangeBrandlogoImage}
                        />
                        <RecommendedSize width='300' height='140' />
                      </Panel>
                      <Panel header={this.panelHeaderSearch()} key="2">
                        <HeaderSearch
                          searchInput={
                            isObjectEmpty(this.props.headerDetails)
                              ? ""
                              : this.props.headerDetails.search_label
                          }
                          onChangeHeaderInfo={this.onChangeHeaderInfo}
                        />
                      </Panel>
                      <Panel header={this.panelHeaderlinks()} key="3">
                        <Headerlinks
                          links={
                            isObjectEmpty(this.props.headerDetails)
                              ? ""
                              : this.props.headerDetails.links
                          }
                          onDragEnd={this.onDragEnd}
                          data={this.state.requestObject.links}
                          refreshHeaderState={this.refreshHeaderState}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getHeaderDetails.actionName,
  serverApis.uploadHeaderBrandlogo.actionName,
  serverApis.updateHeaderInfo.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  headerDetails: state.headerDetails,
  uploadHeaderBrandlogoSuccessMessage:
    state.uploadHeaderBrandlogoSuccessMessage,
  updateHeaderInfoSuccessMessage: state.updateHeaderInfoSuccessMessage,
  manageSequenceHeaderLinkSuccessMessage:
    state.manageSequenceHeaderLinkSuccessMessage,
});

export default connect(mapStateToProps, {
  resetStoreState,
  getHeaderDetails,
  uploadHeaderBrandlogo,
  updateHeaderInfo,
  manageSequenceHeaderLink,
})(HeaderView);
