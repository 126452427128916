import React, { Component } from "react";
import { Button, Spin } from "antd";
import { Row, Col, SubscriptionCard } from "../common";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import "./homepage.scss";

import HomeSlider from "./slider-banner/slider-banner";
import HomeCollage from "./collage/collage";
import HomeCollection from "./collections/collections";
import { serverApis } from "../../constants/server-apis";
import { connect } from "react-redux";
import {
  createLoadingSelector,
  getHomePageLists,
  resetStoreState,
  saveHomePageSectionsSequence,
} from "../../redux/action";
import { isObjectEmpty, isObjectNotEmpty } from "../../common/utils";
import { showAlert } from "../../common/alert-messages";

const SortableItem = sortableElement(({ value }) => (
  <div className="card-lists-items">{value}</div>
));
const SortableContainer = sortableContainer(({ children }) => {
  return <div className="card-lists">{children}</div>;
});

class HomePageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKeys: [],

      items: [
        // <HomeSlider onChangeCollapse={this.onChangeCollapse} />,
        // <HomeCollage onChangeCollapse={this.onChangeCollapse} />,
        // <HomeCollection onChangeCollapse={this.onChangeCollapse} />,
        // <SubscriptionCard onChangeCollapse={this.onChangeCollapse} />,
      ],
      unchangedItems: [],
      isSequenceChanged: false,
      isSectionsSequenceChanged: false,
      showSectionSequenceAlert: false,
    };
  }

  setSectionsSequenceChanged = (bool) => {
    this.setState({ isSectionsSequenceChanged: bool });
  };

  setShowSectionSequenceAlert = (bool) => {
    this.setState({ showSectionSequenceAlert: bool });
  };

  onChangeCollapse = (value) => {
    var keys = [];
    if (value === undefined) {
    } else {
      keys = [value];
    }

    if (this.state.isSectionsSequenceChanged) {
      this.setState({ showSectionSequenceAlert: true });
    } else {
      this.setState({ activeKeys: keys });
    }
  };

  cc;

  componentDidMount() {
    this.props.getHomePageLists();
  }

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.deleteHomeCollectionSuccessMessage)) {
      showAlert.success(this.props.deleteHomeCollectionSuccessMessage);
      this.props.resetStoreState(serverApis.deleteHomeCollection.actionName);

      this.props.getHomePageLists();
    }

    if (
      isObjectNotEmpty(this.props.saveHomePageSectionsSequenceSuccessMessage)
    ) {
      showAlert.success(this.props.saveHomePageSectionsSequenceSuccessMessage);
      this.props.resetStoreState(
        serverApis.saveHomePageSectionsSequence.actionName
      );

      this.props.getHomePageLists();
    }

    if (isObjectNotEmpty(this.props.saveSectionItemsSequenceSuccessMessage)) {
      showAlert.success(this.props.saveSectionItemsSequenceSuccessMessage);
      this.props.resetStoreState(serverApis.saveHomeSliderSequence.actionName);

      this.props.getHomePageLists();
    }

    if (isObjectNotEmpty(this.props.homePageLists)) {
      var homeLists = [];
      var activeKeys = this.state.activeKeys;

      for (let index = 0; index < this.props.homePageLists.length; index++) {
        const list = this.props.homePageLists[index];
        switch (list.section_type) {
          case "banner":
            if (index === 0 && isObjectEmpty(this.state.activeKeys)) {
              activeKeys = ["1"];
            }

            homeLists.push(
              <HomeSlider
                contentList={list.content}
                onChangeCollapse={this.onChangeCollapse}
                isEnabled={list.trans_flag === "A"}
                sectionDetails={list}
                setSectionsSequenceChanged={this.setSectionsSequenceChanged}
                setShowSectionSequenceAlert={this.setShowSectionSequenceAlert}
              />
            );
            break;
          case "collage":
            if (index === 0 && isObjectEmpty(this.state.activeKeys)) {
              activeKeys = ["2"];
            }
            homeLists.push(
              <HomeCollage
                contentList={list.content}
                onChangeCollapse={this.onChangeCollapse}
                sectionDetails={list}
                setSectionsSequenceChanged={this.setSectionsSequenceChanged}
                setShowSectionSequenceAlert={this.setShowSectionSequenceAlert}
              />
            );
            break;
          case "collection":
            if (index === 0 && isObjectEmpty(this.state.activeKeys)) {
              activeKeys = ["3"];
            }
            homeLists.push(
              <HomeCollection
                contentList={list.content}
                onChangeCollapse={this.onChangeCollapse}
                sectionDetails={list}
                setSectionsSequenceChanged={this.setSectionsSequenceChanged}
                setShowSectionSequenceAlert={this.setShowSectionSequenceAlert}
              />
            );
            break;
          case "subscription":
            if (index === 0 && isObjectEmpty(this.state.activeKeys)) {
              activeKeys = ["4"];
            }
            homeLists.push(
              <SubscriptionCard
                onChangeCollapse={this.onChangeCollapse}
                sectionDetails={list}
                setSectionsSequenceChanged={this.setSectionsSequenceChanged}
                setShowSectionSequenceAlert={this.setShowSectionSequenceAlert}
              />
            );
            break;
          default:
            break;
        }
      }

      this.setState({
        items: homeLists,
        isSequenceChanged: false,
        activeKeys: activeKeys,
        unchangedItems: [...homeLists],
        isSectionsSequenceChanged: false,
        showSectionSequenceAlert: false,
      });

      this.props.resetStoreState(serverApis.getHomePageLists.actionName);
    }
  }

  saveHomePageSectionsSequence = () => {
    let arrySections = this.state.items.map((section) => {
      return { id: section.props.sectionDetails.id };
    });

    this.props.saveHomePageSectionsSequence(arrySections);
  };

  render() {
    const itemsL = this.state.items;

    const items = itemsL.map((ViewCollapse) =>
      React.cloneElement(ViewCollapse, {
        activeKeys: this.state.activeKeys,
        isSectionsSequenceChanged: this.state.isSectionsSequenceChanged,
        showSectionSequenceAlert: this.state.showSectionSequenceAlert,
      })
    );

    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <React.Fragment>
          <div className="right-wrapper">
            <div className="right-wrapper-inner">
              <Row>
                <Col sm="6"></Col>
                <Col sm="6" className="text-right">
                  <Button
                    className="btn-white btn-md radius mr-3"
                    onClick={() => {
                      const unchangedItems = this.state.unchangedItems;
                      this.setState({ items: [...unchangedItems] });
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    className="btn-success btn-md radius"
                    disabled={!this.state.isSequenceChanged}
                    onClick={this.saveHomePageSectionsSequence}
                  >
                    Save sequence
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                    {items.map((value, index) => {
                      return (
                        <SortableItem
                          key={`item-${index}`}
                          index={index}
                          value={value}
                        />
                      );
                    })}
                  </SortableContainer>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getHomePageLists.actionName,
  serverApis.deleteHomeSlider.actionName,
  serverApis.editHomeSlider.actionName,
  serverApis.deleteHomeCollection.actionName,
  serverApis.activateInActivateSection.actionName,
  serverApis.saveHomePageSectionsSequence.actionName,
  serverApis.saveHomeSliderSequence.actionName,
  serverApis.saveHomeCollageSequence.actionName,
  serverApis.saveHomeCollectionSequence.actionName,
  serverApis.deleteHomeCollage.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  homePageLists: state.homePageLists,
  saveHomePageSectionsSequenceSuccessMessage:
    state.saveHomePageSectionsSequenceSuccessMessage,
  saveSectionItemsSequenceSuccessMessage:
    state.saveSectionItemsSequenceSuccessMessage,
  deleteHomeCollectionSuccessMessage: state.deleteHomeCollectionSuccessMessage,
});

export default connect(mapStateToProps, {
  resetStoreState,
  getHomePageLists,
  saveHomePageSectionsSequence,
})(HomePageView);
