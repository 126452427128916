import React, { Component } from "react";
import { List, Button, Switch, Spin } from "antd";
import { Col, Row } from "../../components/common/grid";
import { SvgIcon } from "../../components/common";
import ReactDragListView from "react-drag-listview";

import CreateCollectionModal from "./create-collection/create-collection";
import AddProductsModal from "./add-products/add-products";
import EditCollectionModal from "./edit-collection/edit-collection";
import DeleteCollectionModal from "./delete-collection/delete-collection";

import { serverApis } from "../../constants/server-apis";
import {
  deleteCollection,
  getSubChildCollections,
  getSubCollections,
  createLoadingSelector,
  getRootCollections,
  resetStoreState,
  saveCollectionState,
  saveCollectionSequence,
} from "../../redux/action";

import "./collection.scss";
import { connect } from "react-redux";
import { isObjectEmpty, isObjectNotEmpty } from "../../common/utils";
import { showAlert } from "../../common/alert-messages";

const collectionType = {
  PARENT: "PARENT",
  CHILD: "CHILD",
  SUB_CHILD: "SUBCHILD",
};

class CollectionView extends Component {
  state = {
    collectiondata: [],
    subcollectiondata: [],
    itemcollectiondata: [],
    selectedParentCollectionId: "",
    selectedChildCollectionId: "",
    deleteCollectionType: "",
  };

  onChange = (checked, collectionId, collectionTypeName) => {
    var collectionArry = [];

    switch (collectionTypeName) {
      case collectionType.PARENT:
        collectionArry = this.state.collectiondata;
        break;

      case collectionType.CHILD:
        collectionArry = this.state.subcollectiondata;
        break;

      case collectionType.SUB_CHILD:
        collectionArry = this.state.itemcollectiondata;
        break;

      default:
        break;
    }

    const filteredArry = collectionArry.filter(
      (collection) => collection.id === collectionId
    );

    if (filteredArry.length > 0) {
      let collection = filteredArry[0];

      collection.trans_flag = checked ? "A" : "D";

      let collectiondata = this.state.collectiondata;
      let subcollectiondata = this.state.subcollectiondata;
      let itemcollectiondata = this.state.itemcollectiondata;
      this.setState({ collectiondata, subcollectiondata, itemcollectiondata });

      this.props.saveCollectionState({
        collection_id: collectionId,
        trans_flag: collection.trans_flag, //Use 'A' for Active & 'D' for In-Active
      });
    }
  };

  onDragEndcollectiondata = (fromIndex, toIndex) => {
    if (toIndex < 0) return;
    const items = [...this.state.collectiondata];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    this.setState({ collectiondata: items });
  };
  onDragEndsubcollectiondata = (fromIndex, toIndex) => {
    if (toIndex < 0) return;
    const items = [...this.state.subcollectiondata];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    this.setState({ subcollectiondata: items });
  };
  onDragEnditemcollectiondata = (fromIndex, toIndex) => {
    if (toIndex < 0) return;
    const items = [...this.state.itemcollectiondata];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    this.setState({ itemcollectiondata: items });
  };

  onSelectParentCollection = (selectedParentId) => {
    //this.props.resetStoreState(serverApis.getSubCollections.actionName);
    this.setState({
      selectedParentCollectionId: selectedParentId,
      selectedChildCollectionId: "",
      subcollectiondata: [],
    });
    this.refreshSubCollections(selectedParentId);
    this.resetSubChildCollections();
  };

  onSelectChildCollection = (selectedParentId) => {
    // this.props.resetStoreState(serverApis.getSubCollections.actionName);
    this.setState({
      selectedChildCollectionId: selectedParentId,
      itemcollectiondata: [],
    });
    this.refreshSubChildCollections(selectedParentId);
  };

  componentDidMount() {
    this.refreshRootCollections();
  }

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.saveCollectionSequenceSuccessMessage)) {
      showAlert.success(this.props.saveCollectionSequenceSuccessMessage);
      this.props.resetStoreState(serverApis.saveCollectionSequence.actionName);
      this.refreshAllCollectionsLists();
    }

    if (isObjectNotEmpty(this.props.saveCollectionStateSuccessMessage)) {
      showAlert.success(this.props.saveCollectionStateSuccessMessage);
      this.props.resetStoreState(serverApis.saveCollectionState.actionName);
    }

    if (
      isObjectNotEmpty(this.props.rootCollections) &&
      isObjectEmpty(this.state.collectiondata)
    ) {
      this.setState({ collectiondata: this.props.rootCollections });
    }

    if (
      isObjectNotEmpty(this.props.subCollections) &&
      isObjectEmpty(this.state.subcollectiondata)
    ) {
      this.setState({ subcollectiondata: this.props.subCollections });
    }

    if (
      isObjectNotEmpty(this.props.subChildCollections) &&
      isObjectEmpty(this.state.itemcollectiondata)
    ) {
      this.setState({ itemcollectiondata: this.props.subChildCollections });
    }

    if (isObjectNotEmpty(this.props.deleteCollectionSuccessMessage)) {
      showAlert.success(this.props.deleteCollectionSuccessMessage);
      this.props.resetStoreState(serverApis.deleteCollection.actionName);

      switch (this.state.deleteCollectionType) {
        case collectionType.PARENT:
          if (isObjectEmpty(this.state.selectedParentCollectionId)) {
            this.resetSubCollections();
            this.resetSubChildCollections();
          }

          this.refreshRootCollections(this.state.selectedParentCollectionId);

          break;
        case collectionType.CHILD:
          if (isObjectEmpty(this.state.selectedChildCollectionId)) {
            this.resetSubChildCollections();
          }
          this.refreshSubCollections(this.state.selectedParentCollectionId);

          break;
        case collectionType.SUB_CHILD:
          this.refreshSubChildCollections(this.state.selectedChildCollectionId);

          break;

        default:
          break;
      }
    }
  }

  refreshRootCollections = () => {
    this.props.resetStoreState(serverApis.rootCollections.actionName);
    this.setState({ collectiondata: [] });
    this.props.getRootCollections();
  };

  refreshSubCollections = (parentId) => {
    this.resetSubCollections();
    this.props.getSubCollections(parentId);
  };

  resetSubCollections = () => {
    this.props.resetStoreState(serverApis.getSubCollections.actionName);
    this.setState({ subcollectiondata: [] });
  };

  refreshSubChildCollections = (parentId) => {
    this.resetSubChildCollections();
    this.props.getSubChildCollections(parentId);
  };

  resetSubChildCollections = () => {
    this.props.resetStoreState(serverApis.getSubChildCollections.actionName);
    this.setState({ itemcollectiondata: [] });
  };

  onClickDeleteCollection = (collectionId, deleteCollectionType) => {
    var stateItems = {};
    switch (deleteCollectionType) {
      case collectionType.PARENT:
        if (this.state.selectedParentCollectionId === collectionId) {
          stateItems = {
            selectedParentCollectionId: "",
            selectedChildCollectionId: "",
          };
        }
        break;
      case collectionType.CHILD:
        if (this.state.selectedChildCollectionId === collectionId) {
          stateItems = {
            selectedChildCollectionId: "",
          };
        }
        break;
      case collectionType.SUB_CHILD:
        break;

      default:
        break;
    }

    this.setState({
      ...stateItems,
      deleteCollectionType: deleteCollectionType,
    });
    this.props.deleteCollection(collectionId);
  };

  resetView = () => {
    this.setState({
      collectiondata: this.props.rootCollections,
      subcollectiondata: this.props.subCollections,
      itemcollectiondata: this.props.subChildCollections,
    });

    // this.props.resetStoreState(serverApis.getSubCollections.actionName);
    // this.props.resetStoreState(serverApis.getSubCollections.actionName);
  };

  saveCollectionSequenceTapped = () => {
    const requestObject = {
      collections: this.state.collectiondata,
      sub_collections: this.state.subcollectiondata,
      sub_sub_collections: this.state.itemcollectiondata,
    };

    this.props.saveCollectionSequence(requestObject);
  };

  refreshAllCollectionsLists = () => {
    this.refreshRootCollections();

    if (isObjectNotEmpty(this.state.selectedParentCollectionId)) {
      this.refreshSubCollections(this.state.selectedParentCollectionId);

      if (isObjectNotEmpty(this.state.selectedChildCollectionId)) {
        this.refreshSubChildCollections(this.state.selectedChildCollectionId);
      }
    }
  };

  render() {
    // console.log(this.props.rootCollections);
    // console.log(this.props.subCollections);
    // console.log(this.props.subChildCollections);
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <div className="right-wrapper">
          <div className="right-wrapper-inner">
            <Row>
              <Col sm="6">
                <h3 className="panel-title">Manage Website Category</h3>
              </Col>
              <Col sm="6" className="text-right">
                <Button
                  className="btn-white btn-md radius mr-3"
                  onClick={this.resetView}
                >
                  Reset
                </Button>
                <Button
                  className="btn-success btn-md radius"
                  onClick={this.saveCollectionSequenceTapped}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
            <Row className="mt-2 three-cards m-0">
              <Col sm="4" className="collection-card cards-list">
                <div className="collection-addbtn">
                  <CreateCollectionModal
                    type="collectionType1"
                    title={"Create Parent Category"}
                    refreshCollections={this.refreshRootCollections.bind(this)}
                  />
                </div>
                <ReactDragListView
                  nodeSelector=".ant-list-item.draggble"
                  onDragEnd={this.onDragEndcollectiondata}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.collectiondata}
                    renderItem={(item) => {
                      const draggble = item !== "";
                      return (
                        <List.Item
                          id={item.id}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.onSelectParentCollection(`${item.id}`);
                          }}
                          className={`${draggble ? "draggble" : ""}${
                            this.state.selectedParentCollectionId ===
                            `${item.id}`
                              ? " selected"
                              : ""
                          }`}
                          actions={[
                            <EditCollectionModal
                              collection={{ ...item }}
                              refreshCollections={this.refreshRootCollections}
                            />,
                            <AddProductsModal collectionId={item.id} />,
                            <DeleteCollectionModal
                              collectionName={item.name}
                              deleteCollection={() =>
                                this.onClickDeleteCollection(
                                  `${item.id}`,
                                  collectionType.PARENT
                                )
                              }
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            title={
                              <div className="d-flex align-items-center">
                                <div
                                  className="title-main d-flex align-items-center"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <SvgIcon
                                    name="move-dotes"
                                    viewbox="0 0 20 32"
                                  />{" "}
                                  <Switch
                                    onClick={(value, e) => {
                                      e.stopPropagation();
                                      this.onChange(
                                        value,
                                        item.id,
                                        collectionType.PARENT
                                      );
                                    }}
                                    size="small"
                                    checked={
                                      item.trans_flag === "A" ? true : false
                                    }
                                  />{" "}
                                </div>
                                {item.name}{" "}
                              </div>
                            }
                          />
                        </List.Item>
                      );
                    }}
                  />
                </ReactDragListView>
              </Col>
              <Col sm="4" className="subcollection-card cards-list">
                <div className="collection-addbtn">
                  {isObjectNotEmpty(this.state.selectedParentCollectionId) && (
                    <CreateCollectionModal
                      type="collectionType2"
                      title={"Create Child Category"}
                      refreshCollections={this.refreshSubCollections.bind(this)}
                      parentId={this.state.selectedParentCollectionId}
                    />
                  )}
                </div>
                <ReactDragListView
                  nodeSelector=".ant-list-item.draggble"
                  onDragEnd={this.onDragEndsubcollectiondata}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.subcollectiondata}
                    renderItem={(item) => {
                      const draggble = item !== "";
                      return (
                        <List.Item
                          id={item.id}
                          onClick={() =>
                            this.onSelectChildCollection(`${item.id}`)
                          }
                          className={`${draggble ? "draggble" : ""}${
                            this.state.selectedChildCollectionId ===
                            `${item.id}`
                              ? " selected"
                              : ""
                          }`}
                          actions={[
                            <EditCollectionModal
                              collection={{ ...item }}
                              refreshCollections={() =>
                                this.refreshSubCollections(
                                  this.state.selectedParentCollectionId
                                )
                              }
                            />,
                            <AddProductsModal collectionId={item.id} />,
                            <DeleteCollectionModal
                              collectionName={item.name}
                              deleteCollection={() =>
                                this.onClickDeleteCollection(
                                  `${item.id}`,
                                  collectionType.CHILD
                                )
                              }
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            title={
                              <div className="d-flex align-items-center">
                                <div
                                  className="title-main d-flex align-items-center"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <SvgIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    name="move-dotes"
                                    viewbox="0 0 20 32"
                                  />{" "}
                                  <Switch
                                    onClick={(value, e) => {
                                      e.stopPropagation();
                                      this.onChange(
                                        value,
                                        item.id,
                                        collectionType.CHILD
                                      );
                                    }}
                                    size="small"
                                    checked={
                                      item.trans_flag === "A" ? true : false
                                    }
                                  />{" "}
                                </div>
                                {item.name}{" "}
                              </div>
                            }
                          />
                        </List.Item>
                      );
                    }}
                  />
                </ReactDragListView>
              </Col>
              <Col sm="4" className="itemcollection-card cards-list">
                <div className="collection-addbtn">
                  {isObjectNotEmpty(this.state.selectedChildCollectionId) && (
                    <CreateCollectionModal
                      type="collectionType3"
                      title={"Create Child Category"}
                      refreshCollections={this.refreshSubChildCollections.bind(
                        this
                      )}
                      parentId={this.state.selectedChildCollectionId}
                    />
                  )}
                </div>
                <ReactDragListView
                  nodeSelector=".ant-list-item.draggble"
                  onDragEnd={this.onDragEnditemcollectiondata}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.itemcollectiondata}
                    renderItem={(item) => {
                      const draggble = item !== "";
                      return (
                        <List.Item
                          id={item.key}
                          className={draggble ? "draggble" : ""}
                          actions={[
                            <EditCollectionModal
                              collection={{ ...item }}
                              refreshCollections={() =>
                                this.refreshSubChildCollections(
                                  this.state.selectedChildCollectionId
                                )
                              }
                            />,
                            <AddProductsModal collectionId={item.id} />,
                            <DeleteCollectionModal
                              collectionName={item.name}
                              deleteCollection={() =>
                                this.onClickDeleteCollection(
                                  `${item.id}`,
                                  collectionType.SUB_CHILD
                                )
                              }
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            title={
                              <div className="d-flex align-items-center">
                                <div
                                  className="title-main d-flex align-items-center"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <SvgIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    name="move-dotes"
                                    viewbox="0 0 20 32"
                                  />{" "}
                                  <Switch
                                    onClick={(value, e) => {
                                      e.stopPropagation();
                                      this.onChange(
                                        value,
                                        item.id,
                                        collectionType.SUB_CHILD
                                      );
                                    }}
                                    size="small"
                                    checked={
                                      item.trans_flag === "A" ? true : false
                                    }
                                  />{" "}
                                </div>
                                {item.name}{" "}
                              </div>
                            }
                          />
                        </List.Item>
                      );
                    }}
                  />
                </ReactDragListView>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.rootCollections.actionName,
  serverApis.getSubCollections.actionName,
  serverApis.getSubChildCollections.actionName,
  serverApis.deleteCollection.actionName,
  serverApis.saveCollectionState.actionName,
  serverApis.saveCollectionSequence.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  rootCollections: state.rootCollections,
  subCollections: state.subCollections,
  subChildCollections: state.subChildCollections,
  deleteCollectionSuccessMessage: state.deleteCollectionSuccessMessage,
  saveCollectionSequenceSuccessMessage:
    state.saveCollectionSequenceSuccessMessage,
  saveCollectionStateSuccessMessage: state.saveCollectionStateSuccessMessage,
});

export default connect(mapStateToProps, {
  deleteCollection,
  getSubChildCollections,
  getSubCollections,
  getRootCollections,
  resetStoreState,
  saveCollectionState,
  saveCollectionSequence,
})(CollectionView);
