import React from "react";
import { Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../common";
import "./Navbar.scss";
import { isUserLoggedIn, loggedInUserDetail } from "../../../common/methods";
import { USER_DETAIL_KEY } from "../../../constants";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getInitialsfromUserDeatils = () => {
    if (isUserLoggedIn()) {
      let first_name = loggedInUserDetail().first_name.charAt(0).toUpperCase();
      let last_name = loggedInUserDetail().last_name.charAt(0).toUpperCase();
      return first_name + last_name;
    } else {
      return "";
    }
  };

  render() {
    const menu = (
      <Menu>
        <Menu.Item danger>
          <Link to="/">Logout</Link>
        </Menu.Item>
      </Menu>
    );
    return (
      <header className="main-header">
        <div className="header-inner">
          <div className="logo">
            <SvgIcon name="logo" viewbox="0 0 235.976 25.128" width="240px" />
          </div>
          <div className="header-right">
            {/* <div className="bellicon">
              <span className="bell-count">4</span>
              <SvgIcon name="bell" viewbox="0 0 22 21" />
            </div> */}
            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                href="/#"
              >
                {/* <Button>
                  <span>{this.getInitialsfromUserDeatils()}</span>
                </Button> */}
                <div className="headeravatar">
                  {/* <img src={userAvatar} alt={userAvatar} /> */}
                </div>
                {loggedInUserDetail(USER_DETAIL_KEY).first_name}{" "}
                {loggedInUserDetail(USER_DETAIL_KEY).last_name}
              </a>
            </Dropdown>
          </div>
        </div>
      </header>
    );
  }
}

export default Navbar;
