import React, { Component } from "react";
import { Modal, Button, Form, Input, Spin } from "antd";
import { ImageLoader, RecommendedSize, SvgIcon } from "../../../../components/common";

import "./edit-slider-popup.scss";

import {
  createLoadingSelector,
  resetStoreState,
  editHomeSlider,
  getHomePageLists,
} from "../../../../redux/action";
import { serverApis } from "../../../../constants/server-apis";
import { connect } from "react-redux";
import { showAlert } from "../../../../common/alert-messages";
import { isObjectEmpty, isObjectNotEmpty } from "../../../../common/utils";
import { baseServerAdress } from "../../../../constants/server-address";

class EditSliderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      file: null,
      requestObject: this.initialRequestObject(),
    };
    this.onChangeImage = this.onChangeImage.bind(this);
  }
  formRef = React.createRef();

  initialRequestObject = () => {
    return {
      url: this.props.itemDetails.url,
      image_file: null,
    };
  };

  onChangeImage(event) {
    const requestObject = this.state.requestObject;
    requestObject.image_file = event.target.files[0];
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
      requestObject,
    });
    event.target.value = null;
  }

  showModal = (event) => {
    this.setState({
      visible: true,
      requestObject: this.initialRequestObject(),
      file: null, //URL.createObjectURL(
      //     baseServerAdress.COLLECTION_IMAGE + this.props.itemDetails.image
      //   ),
    });
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        url: this.initialRequestObject().url,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      requestObject: this.initialRequestObject(),
      file: null,
    });
  };

  editHomeSlider() {
    // if (this.state.requestObject.image_file === null) {
    //   showAlert.error("Please add an image.");
    // } else

    if (isObjectEmpty(this.state.requestObject.url)) {
      showAlert.error("Please enter an url.");
    } else {
      this.props.editHomeSlider(
        this.state.requestObject,
        this.props.itemDetails.id
      );
    }
  }

  onTextChange = (event) => {
    let requestObject = this.state.requestObject;
    requestObject["url"] = event.target.value;

    this.setState({ requestObject });
  };

  componentDidUpdate() {
    if (
      isObjectNotEmpty(this.props.editHomeSliderSuccessMessage) &&
      this.state.visible
    ) {
      showAlert.success(this.props.editHomeSliderSuccessMessage);
      this.props.resetStoreState(serverApis.editHomeSlider.actionName);

      this.props.getHomePageLists();

      this.handleCancel();
    }
  }

  render() {
    const { visible } = this.state;

    return (
      <React.Fragment>
        <Button
          title="Edit Slide"
          type="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            this.showModal();
          }}
          className="image-edit-button"
        >
          <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />
        </Button>
        <Modal
          visible={visible}
          title="Edit Slide"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <Button
              key={"1"}
              type="primary"
              htmlType="submit"
              form={"editSlider"}
              block
              onClick={this.editHomeSlider.bind(this)}
            >
              {" "}
              Save slide{" "}
            </Button>,
          ]}
          className="modal-cms addimageurl-modal"
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="create-collection-modal">
              <Form
                layout="vertical"
                ref={this.formRef}
                initialValues={{ url: this.state.requestObject.url }}
                id={"editSlider"}
              >
                <div className="d-flex">
                  <div className="imgthumbnail-big w-100 mr-0">
                    <div className="imgthumbnail-big-inner">
                      <Button
                        title="Edit Static Banner"
                        type="primary"
                        size="small"
                        className="image-edit-button live-edit"
                      >
                        <input type="file" onChange={this.onChangeImage} />
                        <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />
                      </Button>
                      {this.state.file && (
                        <div className="uploadedthumbimg-wrape">
                          <img
                            alt="uploadedthumbimg"
                            className="uploadedthumbimg-big w-100"
                            src={this.state.file}
                          />
                        </div>
                      )}
                      {!this.state.file &&
                        isObjectNotEmpty(this.props.itemDetails.image) && (
                          <div className="uploadedthumbimg-wrape">
                            <ImageLoader
                              alt="uploadedthumbimg"
                              className="uploadedthumbimg-big w-100"
                              src={
                                baseServerAdress.COLLECTION_IMAGE +
                                this.props.itemDetails.image +
                                `?${Date()}`
                              }
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <RecommendedSize width='1920' height='572' />

                <div className="mt-5">
                  <label htmlFor="slide_url"> URL</label>
                  <div className="d-flex custom-url align-items-center mb-3 w-100">
                    <div className="url">www.tekweld.com/</div>
                    <div className="sub-url w-100">
                      <Form.Item className="m-0" id="url" name="url">
                        <Input
                          name="url"
                          size="large"
                          placeholder="Enter Slide URL"
                          value={this.state.requestObject.url}
                          onChange={this.onTextChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Spin>{" "}
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.editHomeSlider.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  homePageLists: state.homePageLists,
  editHomeSliderSuccessMessage: state.editHomeSliderSuccessMessage,
});

export default connect(mapStateToProps, {
  resetStoreState,
  editHomeSlider,
  getHomePageLists,
})(EditSliderPopup);
