import React, { Component } from "react";
import { Modal, Button, Form, Input, Spin } from "antd";
import { RecommendedSize, SvgIcon } from "../../../components/common";

import "./create-collection.scss";
import { showAlert } from "../../../common/alert-messages";
import { isObjectNotEmpty } from "../../../common/utils";
import { connect } from "react-redux";
import {
  createLoadingSelector,
  createCollection,
  resetStoreState,
} from "../../../redux/action";
import { serverApis } from "../../../constants/server-apis";

const emptyRequestObject = {
  name: "",
  collection_url: "",
  cover_image_file: null,
  thumb_image_file: null,
};

class CreateCollectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      requestObject: { ...emptyRequestObject },
      file: null,
      file1: null,
    };
    this.onChangecover = this.onChangecover.bind(this);
    this.coverresetFile = this.coverresetFile.bind(this);

    this.onChangethumb = this.onChangethumb.bind(this);
    this.thumbresetFile = this.thumbresetFile.bind(this);
  }
  formRef = React.createRef();

  onChangecover(event) {
    let requestObject = this.state.requestObject;
    requestObject.cover_image_file = event.target.files[0];
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
      requestObject,
    });
    event.target.value = null;
  }

  coverresetFile(event) {
    event.preventDefault();
    let requestObject = this.state.requestObject;
    requestObject.cover_image_file = null;
    this.setState({ file: null, requestObject });
  }

  onChangethumb(event) {
    let requestObject = this.state.requestObject;
    requestObject.thumb_image_file = event.target.files[0];

    this.setState({
      file1: URL.createObjectURL(event.target.files[0]),
      requestObject,
    });
    event.target.value = null;
  }

  thumbresetFile(event) {
    event.preventDefault();
    let requestObject = this.state.requestObject;
    requestObject.thumb_image_file = null;
    this.setState({ file1: null, requestObject });
  }

  onTextChange = (event) => {
    let requestObject = this.state.requestObject;
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value; //field value

    requestObject[name] = value;
    this.setState({ requestObject });
  };

  showModal = () => {
    // if (this.props.type !== 1 && isObjectEmpty(this.props.parentId)) return;

    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      requestObject: { ...emptyRequestObject },
      file: null,
      file1: null,
    });

    this.formRef.current.setFieldsValue({
      name: "",
      collection_url: "",
    });
  };

  onFinish = () => {
    this.props.createCollection(this.state.requestObject, this.props.parentId);
  };

  componentDidUpdate() {
    if (
      this.state.visible &&
      isObjectNotEmpty(this.props.createCollectionSuccessMessage)
    ) {
      showAlert.success(this.props.createCollectionSuccessMessage);
      this.props.resetStoreState(serverApis.createCollection.actionName);
      this.handleCancel();

      this.props.refreshCollections(this.props.parentId);
    }
  }

  render() {
    const { visible } = this.state;
    // console.log(this.state);

    return (
      <React.Fragment>
        <Button
          className="btn-md plus-btn radius"
          type="primary"
          onClick={this.showModal}
        >
          <SvgIcon name="plus" viewbox="0 0 492 492" /> {this.props.title}
        </Button>
        <Modal
          visible={visible}
          title="Create Collection"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <Button
              key="1"
              form={this.props.type}
              htmlType="submit"
              type="primary"
              block
            >
              Create Collection
            </Button>,
          ]}
          className="modal-cms"
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="create-collection-modal">
              <Form
                id={this.props.type}
                initialValues={{ ...emptyRequestObject }}
                onFinish={this.onFinish}
                layout="vertical"
                ref={this.formRef}
              >
                <Form.Item
                  className="mb-3"
                  label="Collection Name"
                  id="name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter collection name!",
                    },
                  ]}
                >
                  <Input
                    name="name"
                    size="large"
                    placeholder="Enter Collection Name"
                    value={this.state.requestObject.name}
                    onChange={this.onTextChange}
                  />
                </Form.Item>
                <label htmlFor="collection_url">Collection URL</label>
                <div className="d-flex custom-url align-items-center mb-3 w-100">
                  <div className="url">www.tekweld.com/</div>
                  <div className="sub-url w-100">
                    <Form.Item
                      className="m-0"
                      id="collection_url"
                      name="collection_url"
                    >
                      <Input
                        name="collection_url"
                        size="large"
                        placeholder="Enter Collection URL"
                        value={this.state.requestObject.collection_url}
                        onChange={this.onTextChange.bind(this)}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="mt-4 d-flex">
                  <div className="imgthumbnail-big">
                    <div className="imgthumbnail-big-inner">
                      {this.state.file && (
                        <div className="removethumb-coverimg">
                          <div
                            className="close-icon"
                            onClick={this.coverresetFile}
                          >
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </div>
                      )}
                      <div className="uploadcoverthumbnail">
                        <div className="uploadcoverthumbnail-link">
                          <input type="file" onChange={this.onChangecover} />
                          <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Add
                          Cover Image
                        </div>
                      </div>
                      {this.state.file && (
                        <div>
                          <div className="editcover-img">
                            <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />{" "}
                            Change Cover Image
                            <input type="file" onChange={this.onChangecover} />
                          </div>
                        </div>
                      )}
                      {this.state.file && (
                        <div className="uploadedthumbimg-wrape">
                          <img
                            className="uploadedthumbimg-big"
                            src={this.state.file}
                            alt={this.state.file}
                          />
                        </div>
                      )}
                    </div>
                    <RecommendedSize width='800' height='800' />
                  </div>

                  <div className="imgthumbnail-small mb-3">
                    <div className="imgthumbnail-big-inner">
                      {this.state.file1 && (
                        <div className="removethumb-coverimg">
                          <div
                            className="close-icon"
                            onClick={this.thumbresetFile}
                          >
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </div>
                      )}
                      <div className="uploadcoverthumbnail">
                        <div className="uploadcoverthumbnail-link">
                          <input type="file" onChange={this.onChangethumb} />
                          <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Add
                          Thumbnail
                        </div>
                      </div>
                      {this.state.file1 && (
                        <div>
                          <div className="editcover-img">
                            <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />{" "}
                            Change Thumbnail
                            <input type="file" onChange={this.onChangethumb} />
                          </div>
                        </div>
                      )}
                      {this.state.file1 && (
                        <div className="uploadedthumbimg-wrape">
                          <img
                            className="uploadedthumbimg-big"
                            src={this.state.file1}
                            alt={this.state.file1}
                          />
                        </div>
                      )}
                    </div>
                    <RecommendedSize width='800' height='800' />
                  </div>
                </div>
              </Form>
            </div>
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.createCollection.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  createCollectionSuccessMessage: state.createCollectionSuccessMessage,
});

export default connect(mapStateToProps, {
  createCollection,
  resetStoreState,
})(CreateCollectionModal);
