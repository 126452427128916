import React, { Component } from "react";
import { Checkbox, Spin, Switch } from "antd";
import { SvgIcon, Row, Col, ImageLoader } from "../common";
import { Link } from "react-router-dom";
import EditFooterPopup from "./edit-footer-popup/edit-footer-popup";
import "./footer.scss";
import { connect } from "react-redux";

import {
  createLoadingSelector,
  deleteFooterLogo,
  getFooterDetails,
  getRootCollections,
  resetStoreState,
  deleteFooterLinks,
  updateFooterActiveInactiveDetails,
} from "../../redux/action";
import { serverApis } from "../../constants/server-apis";
import { isObjectNotEmpty } from "../../common/utils";
import { showAlert } from "../../common/alert-messages";

import FooterLogosPopup from "./footer-logos-popup/footer-logos-popup";
import { baseServerAdress } from "../../constants/server-address";
import confirm from "antd/lib/modal/confirm";

class FooterView extends Component {
  state = {
    isFooterDark: false,
  };
  componentDidMount() {
    this.apiCall();
    this.props.getRootCollections();
  }
  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.deleteFooterLinksSuccessMessage)) {
      showAlert.success(this.props.deleteFooterLinksSuccessMessage);
      this.props.resetStoreState(serverApis.deleteFooterLinks.actionName);
      this.props.resetStoreState(serverApis.getFooterDetails.actionName);
      this.apiCall();
    }
    if (isObjectNotEmpty(this.props.deleteFooterLogoSuccessMessage)) {
      showAlert.success(this.props.deleteFooterLogoSuccessMessage);
      this.props.resetStoreState(serverApis.deleteFooterLogo.actionName);
      this.props.resetStoreState(serverApis.getFooterDetails.actionName);
      this.apiCall();
    }

    if (
      isObjectNotEmpty(
        this.props.updateFooterActiveInactiveDetailsSuccessMessage
      )
    ) {
      showAlert.success(
        this.props.updateFooterActiveInactiveDetailsSuccessMessage
      );
      this.props.resetStoreState(
        serverApis.updateFooterActiveInactiveDetails.actionName
      );
      this.props.resetStoreState(serverApis.getFooterDetails.actionName);
      this.props.getFooterDetails();
    }
  }
  apiCall = () => {
    this.props.getFooterDetails();
  };
  handleDeleteFooterLinks = (id) => {
    confirm({
      title: "Do you really want to Delete?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        this.props.deleteFooterLinks(id);
      },
    });
  };

  footerSectionsStatusChange = (event) => {
    let footer = {};

    let name = event.target.name;
    footer[name] = event.target.checked;

    this.setState(footer);
    this.props.updateFooterActiveInactiveDetails(footer);
  };
  lightVersionChange = (checked) => {
    let reqObj = {
      footer_light_mode: checked,
    };
    this.props.updateFooterActiveInactiveDetails(reqObj);
  };

  toggleFooterClass = () => {
    this.setState((prevState) => ({ isFooterDark: !prevState.isFooterDark }));
  };
  handleDeleteFooterLogo = (id) => {
    confirm({
      title: "Do you really want to Delete?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        this.props.deleteFooterLogo(id);
      },
    });
  };
  render() {
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <React.Fragment>
          <div className="right-wrapper">
            <div className="right-wrapper-inner">
              <div className="card-box">
                <Row className="mb-3">
                  <Col>
                    Light Version
                    <Switch
                      className="switch-cms ml-3"
                      checked={this.props.footerDetails.footer_light_mode}
                      onChange={this.lightVersionChange}
                    />
                  </Col>
                </Row>
                {/* For the footer color theme :- add & remove class "footer-light" */}
                <div
                  className={
                    this.props.footerDetails.footer_light_mode
                      ? "footer-card footer-light"
                      : "footer-card"
                  }
                >
                  <Row>
                    <Col sm="4">
                      <div className="footer-rows">
                        <EditFooterPopup
                          formId={"1"}
                          editlinkmodal={true}
                          uploadImage={true}
                          modaltitle="Upload Logo"
                          modalButton="Save"
                        />
                        <ImageLoader
                          className="footerlogo"
                          alt={this.props.footerDetails.logo_image}
                          src={
                            isObjectNotEmpty(
                              this.props.footerDetails.logo_image
                            )
                              ? baseServerAdress.IMAGE +
                                this.props.footerDetails.logo_image
                              : ""
                          }
                        />
                      </div>
                      <div className="footer-rows">
                        <EditFooterPopup
                          formId={"2"}
                          editlinkmodal={true}
                          abouttext={true}
                          modaltitle="About Text"
                          modalButton="Save"
                          labelName="about_text"
                          values={this.props.footerDetails.about_text}
                        />
                        {this.props.footerDetails.about_text}
                      </div>
                      {/* <div className="footer-rows">
                        <EditFooterPopup
                          formId={"3"}
                          editlinkmodal={true}
                          singleinput={true}
                          modaltitle="Edit Address"
                          modalButton="Save"
                          label="Address"
                          labelName="address"
                          values={this.props.footerDetails.address}
                        />
                        <SvgIcon
                          width="14"
                          name="map-marker"
                          viewbox="0 0 9.931 13.71"
                        />
                        {this.props.footerDetails.address}
                      </div> */}
                      <div className="footer-rows">
                        <EditFooterPopup
                          formId={"4"}
                          editlinkmodal={true}
                          singleinput={true}
                          modaltitle="Edit Email"
                          modalButton="Save"
                          label="Email"
                          labelName="email"
                          values={this.props.footerDetails.email}
                        />
                        <SvgIcon name="mail" viewbox="0 0 13 11" />
                        {this.props.footerDetails.email}
                      </div>
                      {/* <div className="footer-rows">
                        <EditFooterPopup
                          formId={"5"}
                          editlinkmodal={true}
                          singleinput={true}
                          modaltitle="Edit Phone"
                          modalButton="Save"
                          label="Phone Number"
                          labelName="phone_number"
                          values={this.props.footerDetails.phone_number}
                        />
                        <SvgIcon name="phone" viewbox="0 0 13 13" />
                        {this.props.footerDetails.phone_number}{" "}
                      </div> */}
                      <div className="footer-rows footer-social-row">
                        <EditFooterPopup
                          formId={"6"}
                          editlinkmodal={true}
                          sociallinks={true}
                          modaltitle="Edit Social Links"
                          modalButton="Save Changes"
                        />
                        <Link to="">
                          <SvgIcon
                            name="facebook"
                            viewbox="0 0 23.101 23.101"
                          />
                        </Link>
                        <Link to="">
                          <SvgIcon name="twitter" viewbox="0 0 512 512" />
                        </Link>
                        <Link to="">
                          <SvgIcon name="linkedin" viewbox="0 0 24 24" />
                        </Link>
                        {/* <Link>
                          <SvgIcon name="instagram" viewbox="0 0 169.063 169.063" />
                        </Link> */}
                        <Link to="">
                          <SvgIcon name="tiktok" viewbox="-32 0 512 512" />
                        </Link>
                        <Link to="">
                          <SvgIcon name="youtube" viewbox="0 0 20 14" />
                        </Link>
                      </div>
                    </Col>
                    <Col sm="8">
                      <Row className="pl-4">
                        <Col sm="4">
                          <div className="footer-rows h-100">
                            <div className="footer-headings">
                              <EditFooterPopup
                                formId={"7"}
                                editlinkmodal={true}
                                singleinput={true}
                                modaltitle="Edit Heading"
                                modalButton="Save"
                                label="Heading"
                                labelName="products_heading"
                                values={
                                  this.props.footerDetails.products_heading
                                }
                              />
                              <Checkbox
                                name={"show_products"}
                                checked={this.props.footerDetails.show_products}
                                onChange={this.footerSectionsStatusChange}
                              ></Checkbox>{" "}
                              {this.props.footerDetails.products_heading}
                            </div>
                            <ul className="footerp-links">
                              {this.props.rootCollections.map(
                                (products, index) => (
                                  <li key={index}>{products.name}</li>
                                )
                              )}
                            </ul>
                          </div>
                        </Col>
                        <Col sm="4">
                          <div className="footer-rows pr-1 h-100">
                            <div className="footer-headings">
                              <EditFooterPopup
                                formId={"8"}
                                editlinkmodal={true}
                                singleinput={true}
                                modaltitle="Edit Heading"
                                modalButton="Save"
                                label="Heading"
                                labelName="about_info_heading"
                                values={
                                  this.props.footerDetails.about_info_heading
                                }
                              />
                              <Checkbox
                                name={"show_about_info"}
                                checked={
                                  this.props.footerDetails.show_about_info
                                }
                                onChange={this.footerSectionsStatusChange}
                              ></Checkbox>{" "}
                              {this.props.footerDetails.about_info_heading}
                            </div>
                            <ul className="footerp-links">
                              {isObjectNotEmpty(
                                this.props.footerDetails.about_info_links
                              )
                                ? this.props.footerDetails.about_info_links.map(
                                    (item, i) => {
                                      return (
                                        <li key={item.id}>
                                          {item.title}
                                          <Link
                                            to=""
                                            key={item.id}
                                            className="delete-icon"
                                          >
                                            <SvgIcon
                                              key={i}
                                              onClick={() =>
                                                this.handleDeleteFooterLinks(
                                                  item.id
                                                )
                                              }
                                              name="trash"
                                              viewbox="0 0 14.286 18.095"
                                            />
                                          </Link>
                                          <EditFooterPopup
                                            formId={"9"}
                                            link_parent_type="about_info"
                                            linkId={item.id}
                                            editlinkmodal={true}
                                            linkurl={true}
                                            modaltitle="Edit Link"
                                            modalButton="save link"
                                            values={item.title}
                                            url={item.url}
                                          />
                                        </li>
                                      );
                                    }
                                  )
                                : ""}
                            </ul>
                            <EditFooterPopup
                              formId={"10"}
                              addlinkmodal={true}
                              linkurl={true}
                              modaltitle="Create Link"
                              link_parent_type="about_info"
                              modalButton="Create link"
                            />
                          </div>
                        </Col>
                        <Col sm="4">
                          <div className="footer-rows pr-1 h-100">
                            <div className="footer-headings">
                              <EditFooterPopup
                                formId={"11"}
                                editlinkmodal={true}
                                singleinput={true}
                                modaltitle="Edit Heading"
                                label="Heading"
                                labelName="tools_heading"
                                modalButton="Save"
                                values={this.props.footerDetails.tools_heading}
                              />
                              <Checkbox
                                name={"show_tools"}
                                checked={this.props.footerDetails.show_tools}
                                onChange={this.footerSectionsStatusChange}
                              ></Checkbox>{" "}
                              {this.props.footerDetails.tools_heading}
                            </div>
                            <ul className="footerp-links">
                              {isObjectNotEmpty(
                                this.props.footerDetails.tools_links
                              )
                                ? this.props.footerDetails.tools_links.map(
                                    (item, i) => {
                                      return (
                                        <li key={i}>
                                          {item.title}
                                          <Link to="" className="delete-icon">
                                            <SvgIcon
                                              name="trash"
                                              viewbox="0 0 14.286 18.095"
                                              onClick={() =>
                                                this.handleDeleteFooterLinks(
                                                  item.id
                                                )
                                              }
                                            />
                                          </Link>
                                          <EditFooterPopup
                                            formId={"12"}
                                            editlinkmodal={true}
                                            link_parent_type="sales_tools"
                                            linkurl={true}
                                            linkId={item.id}
                                            modaltitle="Edit Link"
                                            modalButton="save link"
                                            values={item.title}
                                            url={item.url}
                                          />
                                          {/* <Link className="delete-icon">
                                            <SvgIcon
                                              name="trash"
                                              viewbox="0 0 14.286 18.095"
                                            />
                                          </Link> */}
                                        </li>
                                      );
                                    }
                                  )
                                : ""}
                            </ul>
                            <EditFooterPopup
                              formId={"13"}
                              addlinkmodal={true}
                              linkurl={true}
                              modaltitle="Create Link"
                              link_parent_type="sales_tools"
                              modalButton="Create link"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="footer-logo-card">
                      <ul>
                        {isObjectNotEmpty(this.props.footerDetails.memberships)
                          ? this.props.footerDetails.memberships.map((item) => {
                              return (
                                <li key={item.id}>
                                  <div className="footer-logo">
                                    <div className="close-icon">
                                      <SvgIcon
                                        name="close"
                                        viewbox="0 0 298.667 298.667"
                                        onClick={() =>
                                          this.handleDeleteFooterLogo(item.id)
                                        }
                                      />
                                    </div>
                                    <div className="footer-logo-inner">
                                      <FooterLogosPopup
                                        formId={`${item.id}`}
                                        logoDetails={item}
                                        modaltitle="Edit membership logo"
                                        modalButton="Update Logo"
                                      />
                                      <img
                                        className="logo-image"
                                        alt="footerlogo"
                                        src={
                                          baseServerAdress.IMAGE +
                                          item.membership_logo +
                                          `?${Date()}`
                                        }
                                      />
                                    </div>{" "}
                                  </div>
                                </li>
                              );
                            })
                          : ""}{" "}
                        <li>
                          <div className="footer-logo">
                            <div className="footer-logo-inner">
                              <FooterLogosPopup
                                formId="formid"
                                modaltitle="Add membership logo"
                                modalButton="Add Logo"
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <div className="footer-rows">
                        <EditFooterPopup
                          formId={"14"}
                          editlinkmodal={true}
                          singleinput={true}
                          modaltitle="Edit Copyright"
                          modalButton="Save"
                          labelName="copyright_text"
                          link_parent_type="copyright_text"
                          values={this.props.footerDetails.copyright_text}
                        />
                        {this.props.footerDetails.copyright_text}{" "}
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="footer-rows">
                          <EditFooterPopup
                            formId={"15"}
                            editlinkmodal={true}
                            linkurl={true}
                            modaltitle="Edit Link"
                            modalButton="save link"
                            labelName="privacy_policy_title"
                            link_parent_type="privacy_policy_title"
                            keyNameForUrl="privacy_policy_url"
                            values={
                              this.props.footerDetails.privacy_policy_title
                            }
                            urlPolicyOrTerm={
                              this.props.footerDetails.privacy_policy_url
                            }
                          />
                          {this.props.footerDetails.privacy_policy_title}
                        </div>
                        <div className="mx-3 vertical-divier">|</div>
                        <div className="footer-rows">
                          <EditFooterPopup
                            formId={"16"}
                            editlinkmodal={true}
                            linkurl={true}
                            modaltitle="Edit Link"
                            modalButton="save link"
                            labelName="terms_of_use_title"
                            link_parent_type="terms_of_use_title"
                            keyNameForUrl="terms_of_use_url"
                            values={this.props.footerDetails.terms_of_use_title}
                            urlPolicyOrTerm={
                              this.props.footerDetails.terms_of_use_url
                            }
                          />
                          {this.props.footerDetails.terms_of_use_title}{" "}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>{" "}
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getFooterDetails.actionName,
  serverApis.rootCollections.actionName,
  serverApis.deleteFooterLinks.actionName,
  serverApis.updateFooterActiveInactiveDetails.actionName,
  serverApis.deleteFooterLogo.actionName,
  serverApis.updateFooterDetails.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  footerDetails: state.footerDetails,
  rootCollections: state.rootCollections,
  deleteFooterLinksSuccessMessage: state.deleteFooterLinksSuccessMessage,
  updateFooterActiveInactiveDetailsSuccessMessage:
    state.updateFooterActiveInactiveDetailsSuccessMessage,
  deleteFooterLogoSuccessMessage: state.deleteFooterLogoSuccessMessage,
  updateFooterDetailsSuccessMessage: state.updateFooterDetailsSuccessMessage,
});

export default connect(mapStateToProps, {
  resetStoreState,
  getFooterDetails,
  deleteFooterLogo,
  getRootCollections,
  updateFooterActiveInactiveDetails,
  deleteFooterLinks,
})(FooterView);
