import { requestMethodType } from ".";
import { isObjectNotEmpty } from "../common/utils";

export const serverApis = {
  login: {
    actionName: "login",
    serverUrl: `/authenticate`,
    componentPath: "/login",
    apiMethodType: requestMethodType.POST,
  },
  getItemFilters: {
    actionName: "getItemFilters",
    serverUrl: `/collection_items/get_filters`,
    apiMethodType: requestMethodType.GET,
  },
  getCollectionItems: {
    actionName: "getCollectionItems",
    serverUrl: (collectionId) => `/collection_items/${collectionId}/get_items`,
    apiMethodType: requestMethodType.GET,
  },
  rootCollections: {
    actionName: "rootCollections",
    serverUrl: `/collection`,
    componentPath: "/collections",
    apiMethodType: requestMethodType.GET,
  },
  createCollection: {
    actionName: "createCollection",
    serverUrl: (parentId) =>
      isObjectNotEmpty(parentId)
        ? `/collection?parent_collection_id=${parentId}`
        : `/collection`,
    apiMethodType: requestMethodType.POST,
  },
  updateCollection: {
    actionName: "updateCollection",
    serverUrl: (collectionId) => `/collection/${collectionId}`,
    apiMethodType: requestMethodType.PATCH,
  },
  deleteCollection: {
    actionName: "deleteCollection",
    serverUrl: (collectionId) => `/collection/${collectionId}`,
    apiMethodType: requestMethodType.DELETE,
  },
  saveCollectionState: {
    actionName: "saveCollectionState",
    serverUrl: `/collection/save_collection_state`,
    apiMethodType: requestMethodType.POST,
  },
  saveCollectionSequence: {
    actionName: "saveCollectionSequence",
    serverUrl: `/collection/manage_sequence`,
    apiMethodType: requestMethodType.POST,
  },
  saveCollectionItems: {
    actionName: "saveCollectionItems",
    serverUrl: (collectionId) => `/collection_items/${collectionId}/save_items`,
    apiMethodType: requestMethodType.POST,
  },
  getItemSearchResult: {
    actionName: "getItemSearchResult",
    serverUrl: `/collection_items/search_items`,
    apiMethodType: requestMethodType.POST,
  },
  getSubCollections: {
    actionName: "getChildCollections",
    serverUrl: (parentId) =>
      `/collection/get_child_collections?parent_collection_id=${parentId}`,
    apiMethodType: requestMethodType.GET,
  },
  getSubChildCollections: {
    actionName: "getSubChildCollections",
    serverUrl: (parentId) =>
      `/collection/get_child_collections?parent_collection_id=${parentId}`,
    apiMethodType: requestMethodType.GET,
  },
  productBasicInfo: {
    actionName: "productBasicInfo",
    serverUrl: `/collection`,
    componentPath: "/product-info/basic-information",
  },
  productCustomFields: {
    actionName: "productCustomFields",
    serverUrl: `/collection`,
    componentPath: "/product-info/custom-fields",
  },
  productCategoryInfo: {
    actionName: "rootCollections",
    serverUrl: `/collection`,
    componentPath: "/product-info/categories",
  },
  getCollectionList: {
    actionName: "getCollectionList",
    serverUrl: `/cms_home/get_all_collections`,
    apiMethodType: requestMethodType.GET,
  },
  getHomePageLists: {
    actionName: "getHomePageLists",
    serverUrl: `/cms_home/get_all_sections`,
    apiMethodType: requestMethodType.GET,
  },
  getFooterDetails: {
    actionName: "getFooterDetails",
    serverUrl: `/footer`,
    apiMethodType: requestMethodType.GET,
    componentPath: "/footer",
  },
  updateFooterDetails: {
    actionName: "updateFooterDetails",
    serverUrl: `/footer/update_info`,
    apiMethodType: requestMethodType.POST,
  },
  updateFooterActiveInactiveDetails: {
    actionName: "updateFooterActiveInactiveDetails",
    serverUrl: `/footer/update_info`,
    apiMethodType: requestMethodType.POST,
  },
  updateFooterDetailsForImage: {
    actionName: "updateFooterDetailsForImage",
    serverUrl: `/footer/upload_logo_image`,
    apiMethodType: requestMethodType.POST,
  },
  addFooterLogo: {
    actionName: "addFooterLogo",
    serverUrl: `/membership`,
    apiMethodType: requestMethodType.POST,
  },
  deleteFooterLogo: {
    actionName: "deleteFooterLogo",
    serverUrl: (logoId) => `/membership/${logoId}`,
    apiMethodType: requestMethodType.DELETE,
  },
  updateFooterLogo: {
    actionName: "updateFooterLinks",
    serverUrl: (logoId) => `/membership/${logoId}`,
    apiMethodType: requestMethodType.PATCH,
  },
  deleteHeaderSubLink: {
    actionName: "deleteHeaderSubLink",
    serverUrl: (linkId) => `/header_links/${linkId}`,
    apiMethodType: requestMethodType.DELETE,
  },
  updateHeaderLink: {
    actionName: "updateHeaderLink",
    serverUrl: (linkId) => `/header_links/${linkId}`,
    apiMethodType: requestMethodType.PATCH,
  },
  createHeaderLinks: {
    actionName: "createHeaderLinks",
    serverUrl: `/header_links`,
    apiMethodType: requestMethodType.POST,
  },
  createFooterLinks: {
    actionName: "createFooterLinks",
    serverUrl: `/footer_link`,
    apiMethodType: requestMethodType.POST,
  },
  deleteFooterLinks: {
    actionName: "deleteFooterLinks",
    serverUrl: (collectionId) => `/footer_link/${collectionId}`,
    apiMethodType: requestMethodType.DELETE,
  },
  updateFooterLinks: {
    actionName: "updateFooterLinks",
    serverUrl: (collectionId) => `/footer_link/${collectionId}`,
    apiMethodType: requestMethodType.PATCH,
  },

  createHomeSlider: {
    actionName: "createHomeSlider",
    serverUrl: `/home_slider`,
    apiMethodType: requestMethodType.POST,
  },
  deleteHomeSlider: {
    actionName: "deleteHomeSlider",
    serverUrl: (id) => `/home_slider/${id}`,
    apiMethodType: requestMethodType.DELETE,
  },
  editHomeSlider: {
    actionName: "editHomeSlider",
    serverUrl: (id) => `/home_slider/${id}`,
    apiMethodType: requestMethodType.PATCH,
  },
  addHomeCollection: {
    actionName: "addHomeCollection",
    serverUrl: `/home_collection`,
    apiMethodType: requestMethodType.POST,
  },
  deleteHomeCollection: {
    actionName: "deleteHomeCollection",
    serverUrl: (id) => `/home_collection/${id}`,
    apiMethodType: requestMethodType.DELETE,
  },
  activateInActivateSection: {
    actionName: "activateInActivateSection",
    serverUrl: `/cms_home/manage_section_state`,
    apiMethodType: requestMethodType.POST,
  },
  saveHomePageSectionsSequence: {
    actionName: "saveHomePageSectionsSequence",
    serverUrl: `/cms_home/manage_section_sequence`,
    apiMethodType: requestMethodType.POST,
  },
  saveHomeSliderSequence: {
    actionName: "saveHomeSliderSequence",
    serverUrl: `/home_slider/save_slider_sequence`,
    apiMethodType: requestMethodType.POST,
  },
  saveHomeCollageSequence: {
    actionName: "saveHomeCollageSequence",
    serverUrl: `/home_collage/save_collage_sequence`,
    apiMethodType: requestMethodType.POST,
  },
  saveHomeCollectionSequence: {
    actionName: "saveHomeCollectionSequence",
    serverUrl: `/home_collection/save_collection_sequence`,
    apiMethodType: requestMethodType.POST,
  },
  addHomeCollage: {
    actionName: "addHomeCollage",
    serverUrl: `/home_collage`,
    apiMethodType: requestMethodType.POST,
  },
  deleteHomeCollage: {
    actionName: "deleteHomeCollage",
    serverUrl: (id) => `/home_collage/${id}`,
    apiMethodType: requestMethodType.DELETE,
  },
  getHeaderDetails: {
    actionName: "getHeaderDetails",
    serverUrl: `/header`,
    apiMethodType: requestMethodType.GET,
    componentPath: "/header",
  },
  uploadHeaderBrandlogo: {
    actionName: "uploadHeaderBrandlogo",
    serverUrl: `/header/upload_brand_image`,
    apiMethodType: requestMethodType.POST,
  },
  updateHeaderInfo: {
    actionName: "updateHeaderInfo",
    serverUrl: `/header/update_header_info`,
    apiMethodType: requestMethodType.POST,
  },
  manageSequenceHeaderLink: {
    actionName: "manageSequenceHeaderLink",
    serverUrl: `/header_links/save_link_sequence`,
    apiMethodType: requestMethodType.POST,
  },
  manageStateHeaderLink: {
    actionName: "manageStateHeaderLink",
    serverUrl: `/header_links/save_link_state`,
    apiMethodType: requestMethodType.POST,
  },
  deleteHeaderLink: {
    actionName: "deleteHeaderLink",
    serverUrl: (linkId) => `/header_links/${linkId}`,
    apiMethodType: requestMethodType.DELETE,
  },
  getProductDetails: {
    actionName: "getProductDetails",
    serverUrl: (productId) => `/products/cms_product_details?id=${productId}`,
    apiMethodType: requestMethodType.GET,
  },
  getSearchProducts: {
    actionName: "getSearchProducts",
    serverUrl: (productCode) =>
      `/products/search_product_list?product_code=${productCode}`,
    apiMethodType: requestMethodType.GET,
    componentPath: (id) => `/product-info/${id ? id : ":productid"}`,
  },
  getSearchProductsListAutocomplete: {
    actionName: "getSearchProductsListAutocomplete",
    serverUrl: (productCode) =>
      `/products/search_product_list?product_code=${productCode}`,
    apiMethodType: requestMethodType.GET,
    componentPath: "/product-info/basic-information",
  },
  lookupOptions: {
    actionName: "lookupOptions",
    serverUrl: `/products/get_lookup_data`,
    apiMethodType: requestMethodType.POST,
  },
  getSearchCategories: {
    actionName: "getSearchCategories",
    serverUrl: (id) => `/products/get_categories${id ? `?pid=${id} ` : ""}`,
    apiMethodType: requestMethodType.GET,
  },
  saveAndPublishProductDetails: {
    actionName: "saveAndPublishProductDetails",
    serverUrl: `/products/update_product_details`,
    apiMethodType: requestMethodType.POST,
  },
};
