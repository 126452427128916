import React, { Component } from "react";
import { Col, ImageLoader } from "../../../components/common";
import "./product-card.scss";
import history from "../../../common/history";
import LinesEllipsis from "react-lines-ellipsis";
import { serverApis } from "../../../constants/server-apis";

class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col sm="3" className="mb-4">
        {/* <Link
          to="/product-info/basic-information"
          className="w-100"
        > */}
        <div
          className="product-card-main"
          onClick={() => {
            history.push({
              pathname: serverApis.getSearchProducts.componentPath(
                this.props.productId
              ),
            });
          }}
        >
          <div className="product-card-img">
            <div className="img-wrape">
              <div className="image-thumb">
                <div className="imagethumb-inner">
                  <ImageLoader
                    alt={this.props.prdoductName}
                    title={this.props.prdoductName}
                    src={this.props.prdoductImg}
                    className=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="product-card-right">
            <div className="code">#{this.props.productCode}</div>
            <div className="product-name">
              {/* {this.props.prdoductName} */}
              <LinesEllipsis
                text={this.props.prdoductName}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
          </div>
        </div>
        {/* </Link> */}
      </Col>
    );
  }
}

export default ProductCard;
