

export function getActionNameWithStatus(actionName, statusType) {
    return `GET_${actionName}_${statusType}`;
}
  
export function getResetAction(actionName) {
      return `${actionName}RESET`;
}

export function dispatchWithResponseAndType(response, typeName) {
    return {
      type: typeName,
      json: response,
    };
  }
  