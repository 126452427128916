import React from "react";
import { Router, Route, Redirect } from "react-router-dom";
import SvgSprite from "./utility/SvgSpriteLoader";
import { SvgIcon } from "./components/common";
import { isIE } from "react-device-detect";
//App routes
import { privateRoutes, publicRoutes } from "./routes";

import "./ant.less";
import "./app.scss";

//Svg Sprite
import svgFile from "./assets/images/svg/svg-sprite.svg";
import BrowserLeftImg from "./assets/images/img-browser.png";
import BrowserChromeImg from "./assets/images/ico-chrome.png";
import BrowserMozillaImg from "./assets/images/ico-mozilla.png";
import BrowserSafariImg from "./assets/images/ico-safari.png";
import BrowserOperaImg from "./assets/images/ico-opera.png";
import BrowserMsEdgeImg from "./assets/images/ico-edge.png";
import history from "./common/history";
import { isOpenPath, isUserLoggedIn } from "./common/methods";
import { serverApis } from "./constants/server-apis";

class App extends React.Component {
  render() {
    if (isIE)
      return (
        <div>
          <SvgSprite url={svgFile} />
          <div className="unspportie-main">
            <div className="unspportie-main-inner">
              <div className="logo-section">
                <SvgIcon
                  name="text-logo"
                  viewbox="0 0 296.068 50.2"
                  width="200px"
                />
              </div>
              <div className="bottom-section">
                <div className="left">
                  <img src={BrowserLeftImg} alt={BrowserLeftImg} />
                  <div className="unspport-text">
                    <p>You seem to be using</p>
                    <h3>Unsupported Browser</h3>
                  </div>
                </div>
                <div className="right">
                  <p className="info-text">
                    We build our product using the latest techniques and
                    technology to make them faster and easier to use.
                    Unfortunately, your browser doesn't support those
                    technologies.
                  </p>
                  <p className="info-text">
                    Use one OF these great browsers and you'll be on your way
                  </p>
                  <ul className="broser-list">
                    <li>
                      <img alt={BrowserChromeImg} src={BrowserChromeImg} />
                      <h4>Google Chrome</h4>
                      <p>Ver 83+</p>
                    </li>
                    <li>
                      <img alt={BrowserMozillaImg} src={BrowserMozillaImg} />
                      <h4>Mozilla Firefox</h4>
                      <p>Ver 79+</p>
                    </li>
                    <li>
                      <img alt={BrowserSafariImg} src={BrowserSafariImg} />
                      <h4>Safari</h4>
                      <p>Ver 10.1+</p>
                    </li>
                    <li>
                      <img alt={BrowserOperaImg} src={BrowserOperaImg} />
                      <h4>Opera</h4>
                      <p>Ver 70+</p>
                    </li>
                    <li>
                      <img alt={BrowserMsEdgeImg} src={BrowserMsEdgeImg} />
                      <h4>Microsoft Edge</h4>
                      <p>Ver 84+</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    return (
      <React.Fragment>
        <SvgSprite url={svgFile} />
        <Router
          history={history}
          basename={process.env.REACT_APP_BASENAME || ""}
        >
          {publicRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                }}
              />
            );
          })}
          {privateRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => {
                  return isUserLoggedIn() || isOpenPath(route.path) ? (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  ) : (
                    <Redirect
                      to={{
                        pathname: serverApis.login.componentPath,
                        state: { NAVIGATION_REFERENCE: route.path },
                      }}
                    />
                  );
                }}
              />
            );
          })}
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
