import React, { Component } from "react";
import { Form } from "antd";
import "./manage-variants.scss";
import { Col } from "../../common";
import ManageVariantsPopupView from "./manage-variants-popup/manage-variants-popup";
import { getObjectDeepCopy, isObjectNotEmpty } from "../../../common/utils";

class ManageVariantsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openVariantIndex: -1,
    };
  }

  setOpenVariantIndex = (index) => {
    this.setState({ openVariantIndex: index });
  };

  getImprintOptionsArry = () => {
    if (
      this.props.productDetailsRequestObj.hasOwnProperty(
        this.props.fieldValue.api_key
      )
    ) {
      return this.props.productDetailsRequestObj[this.props.fieldValue.api_key];
    } else {
      return getObjectDeepCopy(this.props.fieldValue.value);
    }
  };

  render() {
    const dataSource = this.getImprintOptionsArry();
    return (
      <React.Fragment>
        <Col xs="12">
          <Form className="w-100">
            {" "}
            <div className="managae-variants-row">
              <h3>{this.props.fieldValue.title}</h3>
              <ul>
                {isObjectNotEmpty(dataSource)
                  ? dataSource.map((currentPopupValues, i) => {
                      return (
                        <ManageVariantsPopupView
                          setOpenVariantIndex={this.setOpenVariantIndex}
                          openVariantIndex={this.state.openVariantIndex}
                          key={i}
                          variantIndex={i}
                          allVarients={dataSource}
                          manageVariantsImageChange={
                            this.props.manageVariantsImageChange
                          }
                          currentPopupValues={currentPopupValues}
                          fieldValue={this.props.fieldValue}
                        />
                      );
                    })
                  : ""}
              </ul>
            </div>{" "}
          </Form>
        </Col>
      </React.Fragment>
    );
  }
}

export default ManageVariantsView;
