import React, { Component } from "react";
import { Button, Form, Input } from "antd";
import { SvgIcon, Row, Col } from "../../common";
import "./custom-fields.scss";

import { serverApis } from "../../../constants/server-apis";
import {
  createLoadingSelector,
  lookupOptions,
  resetStoreState,
} from "../../../redux/action";
import { connect } from "react-redux";
import { isObjectNotEmpty, isValueAnInteger } from "../../../common/utils";
import confirm from "antd/lib/modal/confirm";

class CustomFieldsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: "normal",
    };
  }
  onClickSelect = () => {
    this.props.lookupOptions({ type: 5 });
  };

  getCustomFieldsArry = () => {
    if (this.props.productDetailsRequestObj.hasOwnProperty(this.props.apiKey)) {
      return this.props.productDetailsRequestObj[this.props.apiKey];
    } else {
      return [...this.props.fieldValues.value];
    }
  };

  onChangeText = (value, index, key) => {
    let dataSource = this.getCustomFieldsArry();

    dataSource[index][key] = value;

    this.props.requestObjForCustomField(this.props.apiKey, dataSource);
    this.setState({
      dataSource,
    });
  };

  handleAdd = () => {
    let dataSource = this.getCustomFieldsArry();
    dataSource.push({
      value: "",
      title: "",
    });

    this.props.requestObjForCustomField(this.props.apiKey, dataSource);
    this.setState({
      dataSource,
    });
  };
  handleDelete = (index) => {
    let dataSource = this.getCustomFieldsArry();
    confirm({
      title: "Do you really want to Delete ?",
      onOk: () => {
        dataSource.splice(index, 1);
        this.props.requestObjForCustomField(this.props.apiKey, dataSource);
        this.setState({
          dataSource,
        });
      },
    });
  };

  handleScroll = () => {
    if (document.getElementById("root").scrollTop > 13) {
      this.setState({ activeClass: "fixTop" });
    } else {
      this.setState({ activeClass: "normal" });
    }
  };

  render() {
    const dataSource = this.getCustomFieldsArry();
    return (
      <React.Fragment>
        <div className="card-body">
          {isObjectNotEmpty(dataSource)
            ? dataSource.map((field, index) => {
                return (
                  <Col key={index}>
                    <Row className="mt-4 custom-fields-row">
                      <Col>
                        <Form.Item className="mb-2">
                          <label htmlFor="custom_field_name">
                            Custom Field Name
                          </label>
                          <Input
                            id="custom_field_value"
                            className="ant-input-xl"
                            suffix={
                              isValueAnInteger(field.id) ? (
                                <SvgIcon
                                  name="lock"
                                  viewbox="0 0 22.996 30.661"
                                />
                              ) : (
                                ""
                              )
                            }
                            disabled={isValueAnInteger(field.id)}
                            value={field.title}
                            onChange={(event) => {
                              this.onChangeText(
                                event.target.value,
                                index,
                                "title"
                              );
                            }}
                          />
                          {/* <Select
                            notFoundContent={
                              this.props.isFetchingDataSuggestion ? (
                                <Spin />
                              ) : (
                                ""
                              )
                            }
                            onBlur={() => {
                              this.props.resetStoreState(
                                serverApis.lookupOptions.actionName
                              );
                            }}
                            id="title"
                            value={field.title}
                            size="large"
                            className="ant-select-xl"
                            onClick={this.onClickSelect}
                            onSelect={(value, option) => {
                              this.onSelectCustomName(value, index, option);
                            }}
                          >
                            {this.props.lookupOptionsDetails.map((option) => (
                              <Option id={option.id} value={option.title}>
                                {option.title}
                              </Option>
                            ))}
                          </Select> */}
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item className="mb-2">
                          <label htmlFor="custom_field_value">
                            Custom Field Value(s)
                          </label>
                          <Input
                            id="custom_field_value"
                            className="ant-input-xl"
                            suffix={
                              isValueAnInteger(field.id) ? (
                                <SvgIcon
                                  name="lock"
                                  viewbox="0 0 22.996 30.661"
                                />
                              ) : (
                                ""
                              )
                            }
                            disabled={isValueAnInteger(field.id)}
                            onChange={(event) => {
                              this.onChangeText(
                                event.target.value,
                                index,
                                "value"
                              );
                            }}
                            value={field.value}
                          />
                        </Form.Item>
                      </Col>
                      <div
                        className="close-fields"
                        onClick={() => {
                          this.handleDelete(index);
                        }}
                      >
                        <SvgIcon name="close" viewbox="0 0 298.667 298.667" />
                      </div>
                    </Row>
                  </Col>
                );
              })
            : ""}

          <Col>
            <Row className="mt-2">
              <Col>
                <Button
                  onClick={this.handleAdd}
                  className="addfield-btn"
                  type="link"
                  icon={<SvgIcon name="plus-circle" viewbox="0 0 19 19" />}
                >
                  ADD CUSTOM FIELD
                </Button>
              </Col>
            </Row>
          </Col>
        </div>
      </React.Fragment>
    );
  }
}

const loadingSelectorSuggestion = createLoadingSelector([
  serverApis.lookupOptions.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingDataSuggestion: loadingSelectorSuggestion(state),
  lookupOptionsDetails: state.lookupOptionsDetails,
});

export default connect(mapStateToProps, {
  resetStoreState,
  lookupOptions,
})(CustomFieldsView);
