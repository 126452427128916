import React, { Component } from "react";
import { Modal, Form, Button } from "antd";
import { ImageLoader, RecommendedSize, SvgIcon } from "../../../common";
import "./manage-variants-popup.scss";

// import images
import { baseServerAdress } from "../../../../constants/server-address";
import { isObjectNotEmpty } from "../../../../common/utils";

class ManageVariantsPopupView extends Component {
  deleteImage = (event, object) => {
    object.file_data = null;

    object.file = null;
    object.delete_image = true;

    this.props.manageVariantsImageChange(
      this.props.allVarients,
      this.props.fieldValue.api_key
    );

    event.target.value = null;
  };

  onChangeImage = (event, object) => {
    object.file_data = event.target.files[0];
    object.delete_image = false;

    object.file = URL.createObjectURL(event.target.files[0]);

    this.props.manageVariantsImageChange(
      this.props.allVarients,
      this.props.fieldValue.api_key
    );

    event.target.value = null;
  };

  showModal = () => {
    this.props.setOpenVariantIndex(this.props.variantIndex);
  };

  hideModal = () => {
    this.props.setOpenVariantIndex(-1);
  };

  openPreviousVariant = () => {
    if (this.props.variantIndex > 0) {
      this.props.setOpenVariantIndex(this.props.variantIndex - 1);
    }
  };

  openNextVariant = () => {
    if (this.props.variantIndex < this.props.allVarients.length - 1) {
      this.props.setOpenVariantIndex(this.props.variantIndex + 1);
    }
  };

  render() {
    return (
      <React.Fragment>
        {" "}
        <li onClick={this.showModal}>
          <div className="colorname">{this.props.currentPopupValues.title}</div>
        </li>{" "}
        <Modal
          title={
            <React.Fragment>
              {/* {this.props.fieldValue.title} -{" "} */}
              {this.props.currentPopupValues.title}
            </React.Fragment>
          }
          visible={this.props.openVariantIndex === this.props.variantIndex}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          okText="ok"
          cancelText="Cancel"
          className="modal-cms addrelated-productcode"
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={false}
          width={600}
        >
          <Form>
            <div className="variant-slider">
              <Button
                disabled={this.props.variantIndex === 0}
                title="Previous"
                className="arrow-btn left-arrow"
                onClick={this.openPreviousVariant}
              >
                <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
                <div>Previous</div> Item
              </Button>
              <div className="variant-image-main justify-content-center">
                {isObjectNotEmpty(
                  this.props.currentPopupValues.imprint_images
                ) &&
                  this.props.currentPopupValues.imprint_images.map(
                    (imprintObject, index) => (
                      <div key={index} className="variant-image-change">
                        <div>{imprintObject.imprint_type}</div>

                        <div className="variant-image">
                          <div className="variant-image-inner">
                            <div
                              className="remove-imgicon"
                              onClick={(event) =>
                                this.deleteImage(event, imprintObject)
                              }
                            >
                              <div className="close-icon">
                                <SvgIcon
                                  name="close"
                                  viewbox="0 0 298.667 298.667"
                                />
                              </div>
                            </div>
                            <ImageLoader
                              className="thumb-image"
                              alt={this.props.currentPopupValues.default_image}
                              src={
                                imprintObject.delete_image
                                  ? ""
                                  : imprintObject.file
                                  ? imprintObject.file
                                  : baseServerAdress.ITEM_IMAGE +
                                    imprintObject.default_image
                              }
                            />
                          </div>
                        </div>

                        <Button className="changethumb-btn" type="primary">
                          <input
                            type="file"
                            onChange={(event) =>
                              this.onChangeImage(event, imprintObject)
                            }
                          />{" "}
                          Change Image
                        </Button>
                      </div>
                    )
                  )}
              </div>
              <Button
                disabled={
                  this.props.variantIndex === this.props.allVarients.length - 1
                }
                title="Next"
                className="arrow-btn right-arrow"
                onClick={this.openNextVariant}
              >
                <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />{" "}
                <div>Next</div> Item
              </Button>
            </div>
            <div className="text-center">
              <RecommendedSize width="800" height="800" />
            </div>
          </Form>
        </Modal>{" "}
      </React.Fragment>
    );
  }
}

export default ManageVariantsPopupView;
