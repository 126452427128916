import React, { Component } from "react";
import { Button, Modal, Checkbox, Select, Spin, Input } from "antd";
import { SvgIcon, Row, Col, ImageLoader } from "../../../common";

import "./add-homepage-collection.scss";
import { connect } from "react-redux";
import {
  createLoadingSelector,
  getCollectionsList,
  resetStoreState,
  addHomeCollection,
  getHomePageLists,
  addHomeCollage,
} from "../../../../redux/action/index";
import { serverApis } from "../../../../constants/server-apis";

import { isObjectEmpty, isObjectNotEmpty } from "../../../../common/utils";
import { showAlert } from "../../../../common/alert-messages";
import { baseServerAdress } from "../../../../constants/server-address";

const { Option } = Select;

class AddHomepageCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedParentIds: [],
      searchText: "",
      checkedCollectionIds: [],
    };
  }

  showModal = (event) => {
    const collectionIds = this.props.selectedHomeCollections.map(
      (collection) => collection.collection_id
    );

    this.setState({
      visible: true,
      checkedCollectionIds: collectionIds,
    });
    this.props.getCollectionsList();
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
    this.props.deleteCollection();
  };

  getFilteredCollections = () => {
    let filteredCollections = [];

    if (isObjectEmpty(this.state.selectedParentIds)) {
      filteredCollections = this.props.allCollectionsDetails.allCollections;
    } else {
      this.props.allCollectionsDetails.collections.forEach((collection) => {
        if (this.state.selectedParentIds.includes(collection.id)) {
          filteredCollections.push(collection);
          if (isObjectNotEmpty(collection.sub_collections)) {
            filteredCollections = filteredCollections.concat(
              collection.sub_collections
            );
          }
        }
      });
    }
    if (isObjectNotEmpty(this.state.searchText)) {
      let searchTextFilter = [];

      filteredCollections.forEach((element) => {
        if (
          element.name
            .toUpperCase()
            .includes(this.state.searchText.toUpperCase())
        ) {
          searchTextFilter.push(element);
        }
      });
      filteredCollections = searchTextFilter;
    }

    return filteredCollections;
  };

  handleChange = (value) => {
    this.setState({
      selectedParentIds: value,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
    this.resetPopup();
    this.props.resetStoreState(serverApis.getCollectionList.actionName);
  };
  handleChangeSearchText = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };
  resetPopup = () => {
    this.setState({
      selectedParentIds: [],
      searchText: "",
      checkedCollectionIds: [],
    });
  };
  componentDidMount() {}
  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.addHomeCollectionSuccessMessage)) {
      showAlert.success(this.props.addHomeCollectionSuccessMessage);
      this.props.resetStoreState(serverApis.addHomeCollection.actionName);

      this.props.getHomePageLists();

      this.handleCancel();
    }
  }

  checkButtonClicked = (e, id) => {
    if (this.props.isForCollage) {
      if (this.state.checkedCollectionIds.length === 6 && e.target.checked) {
        showAlert.error("Please select 2 to 6 items.");
        return;
      }
    }

    var checkedCollectionIds = this.state.checkedCollectionIds;
    if (e.target.checked) {
      checkedCollectionIds.push(id);
    } else {
      checkedCollectionIds.splice(checkedCollectionIds.indexOf(id), 1);
    }
    this.setState({ checkedCollectionIds });
  };

  render() {
    const { visible } = this.state;
    // console.log(this.props);
    // console.log(this.state);
    return (
      <React.Fragment>
        <div
          className="addCollection"
          style={{ minHeight: 50 }}
          onClick={(event) => {
            event.stopPropagation();
            this.showModal();
          }}
        >
          <div className="addCollection-link">
            <SvgIcon name="plus-circle" viewbox="0 0 19 19" />
            ADD COLLECTION
          </div>
        </div>

        <Modal
          visible={visible}
          title="Add Collection to home page"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          width={1040}
          footer={[
            <Button key={"cancel"} className="px-3" onClick={this.handleCancel}>
              {" "}
              Cancel{" "}
            </Button>,
            <Button
              key={"addHomeCollection"}
              type="primary"
              className="px-5 ml-3"
              onClick={() => {
                const arrySelectedCollections = this.state.checkedCollectionIds.map(
                  (id) => {
                    return {
                      collection_id: id,
                    };
                  }
                );

                if (this.props.isForCollage) {
                  if (this.state.checkedCollectionIds.length < 2) {
                    showAlert.error("Please select 2 to 6 items.");
                    return;
                  }
                  this.props.addHomeCollage(arrySelectedCollections);
                } else {
                  this.props.addHomeCollection(arrySelectedCollections);
                }
              }}
            >
              {" "}
              add{" "}
            </Button>,
          ]}
          className="modal-cms addcollection-modal"
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="addcollection-wrapper">
              <Row className="align-items-center mb-4">
                <Col sm="7">
                  <label>Search collection</label>
                  <div className="search-collection">
                    {/* <AutoComplete
                      className="w-100"
                      size="large"
                      options={options}
                      placeholder="Start typing"
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    /> */}
                    <Input
                      className="w-100"
                      size="large"
                      value={this.state.searchText}
                      onChange={this.handleChangeSearchText}
                    ></Input>
                    <SvgIcon
                      className="search-icon"
                      name="search"
                      viewbox="0 0 16 16"
                    />
                  </div>
                </Col>
                <Col sm="1" className="text-center">
                  in
                </Col>
                <Col sm="4">
                  <label>Search collection</label>
                  <div>
                    <Select
                      mode="multiple"
                      showArrow
                      allowClear
                      className="w-100"
                      size="large"
                      value={this.state.selectedParentIds}
                      placeholder="All Collections"
                      onChange={this.handleChange}
                    >
                      {this.props.allCollectionsDetails.collections.map(
                        (parent) => {
                          return (
                            <Option key={parent.id} value={parent.id}>
                              {parent.name}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </div>
                </Col>
              </Row>
              <Row className="homepage-colletionlist">
                {this.getFilteredCollections().map((collection, index) => (
                  <Col key={index} sm="4">
                    <div className="collection-card collection-card-modal">
                      <div className="check-collection">
                        {" "}
                        <Checkbox
                          onChange={(event) =>
                            this.checkButtonClicked(event, collection.id)
                          }
                          checked={this.state.checkedCollectionIds.includes(
                            collection.id
                          )}
                        ></Checkbox>{" "}
                      </div>
                      <div className="collection-cardinner">
                        <div className="image addcollection-cardimg">
                          <div className="image-thumb">
                            <div className="imagethumb-inner">
                              <ImageLoader
                                alt={"collection"}
                                src={
                                  baseServerAdress.COLLECTION_IMAGE +
                                  collection.thumb_image +
                                  `?${Date()}`
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="right">
                          <h3>{collection.name}</h3>
                          <p>
                            {(collection.total_item_count === null
                              ? 0
                              : collection.total_item_count) +
                              ` Item${
                                collection.total_item_count === 1 ? "" : "s"
                              }, ` +
                              (collection.total_child_count === null
                                ? 0
                                : collection.total_child_count) +
                              ` Collection${
                                collection.total_child_count === 1 ? "" : "s"
                              }`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}
const loadingSelector = createLoadingSelector([
  serverApis.getCollectionList.actionName,
  serverApis.addHomeCollection.actionName,
  serverApis.addHomeCollage.actionName,
]);

const mapStateToProps = (state) => ({
  allCollectionsDetails: state.allCollectionsDetails,
  isFetchingData: loadingSelector(state),
  addHomeCollectionSuccessMessage: state.addHomeCollectionSuccessMessage,
  homePageLists: state.homePageLists,
});

export default connect(mapStateToProps, {
  getCollectionsList,
  resetStoreState,
  addHomeCollection,
  getHomePageLists,
  addHomeCollage,
})(AddHomepageCollection);
