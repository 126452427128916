import React, { Component } from "react";
import { Button, Form, Input, AutoComplete, Spin } from "antd";
import { SvgIcon, Row, Col } from "../../common";
import "./categories.scss";

import ProductInfoMenu from "../product-info-menu/product-info-menu";
import ProductDetailsCard from "../product-details-card/product-details-card";
import { connect } from "react-redux";
import { createLoadingSelector } from "../../../redux/action";
import { serverApis } from "../../../constants/server-apis";

const Option = AutoComplete.Option;

const dataSource = [
  {
    key: 1,
    title: "Acetate Window",
  },
  {
    key: 2,
    title: "AntDesign UI",
  },
];

const options = dataSource.map((opt) => (
  <Option key={opt.title} value={opt.title}>
    {opt.title}
    <span className="certain-search-item-count">{opt.count}</span>
  </Option>
));

class CategoriesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: "normal",
    };
  }
  handleScroll = () => {
    if (document.getElementById("root").scrollTop > 13) {
      this.setState({ activeClass: "fixTop" });
    } else {
      this.setState({ activeClass: "normal" });
    }
  };

  componentDidMount() {
    document
      .getElementById("root")
      .addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document
      .getElementById("root")
      .removeEventListener("scroll", this.handleScroll);
  }
  render() {
    const onSearch = (value) => console.log(value);
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <React.Fragment>
          <Form className="w-100">
            <div className="right-wrapper">
              <div className="right-wrapper-inner">
                <div className="productinfo-wrapper">
                  <ProductInfoMenu />
                  <div className="productinfo-right">
                    <ProductDetailsCard
                      activeClass={this.state.activeClass}
                      productDetails={this.props.productDetails}
                    />

                    <div className="card-box">
                      <div className="card-header">Categories</div>
                      <div className="card-body">
                        <Row>
                          <Col>
                            <ul className="ctg-tree">
                              <li>
                                <Form.Item className="mb-2">
                                  <label htmlFor="custom_field_name">
                                    Custom Field Name
                                  </label>
                                  <AutoComplete
                                    className="search-withicon"
                                    dropdownClassName="search-feild-drop"
                                    dropdownMatchSelectWidth={false}
                                    dropdownStyle={{ width: 300 }}
                                    size="large"
                                    style={{ width: "100%" }}
                                    dataSource={options}
                                    onSearch={onSearch}
                                    defaultValue="Acetate Window"
                                  >
                                    <Input.Search
                                      className="ant-input-xl"
                                      placeholder=""
                                      enterButton
                                    />
                                  </AutoComplete>
                                </Form.Item>
                              </li>
                              <li>
                                <ul className="subctg-tree">
                                  <li>
                                    <Form.Item className="mb-2">
                                      <label htmlFor="custom_field_name">
                                        Custom Field Name
                                      </label>
                                      <AutoComplete
                                        className="search-withicon"
                                        dropdownClassName="search-feild-drop"
                                        dropdownMatchSelectWidth={false}
                                        dropdownStyle={{ width: 300 }}
                                        size="large"
                                        style={{ width: "100%" }}
                                        dataSource={options}
                                        onSearch={onSearch}
                                        defaultValue="Acetate Window"
                                      >
                                        <Input.Search
                                          className="ant-input-xl"
                                          placeholder=""
                                          enterButton
                                        />
                                      </AutoComplete>
                                    </Form.Item>
                                  </li>
                                  <li>
                                    <ul className="subsubctg-tree">
                                      <li>
                                        <Form.Item className="mb-2">
                                          <label htmlFor="custom_field_name">
                                            Custom Field Name
                                          </label>
                                          <AutoComplete
                                            className="search-withicon"
                                            dropdownClassName="search-feild-drop"
                                            dropdownMatchSelectWidth={false}
                                            dropdownStyle={{ width: 300 }}
                                            size="large"
                                            style={{ width: "100%" }}
                                            dataSource={options}
                                            onSearch={onSearch}
                                            defaultValue="Acetate Window"
                                          >
                                            <Input.Search
                                              className="ant-input-xl"
                                              placeholder=""
                                              enterButton
                                            />
                                          </AutoComplete>
                                        </Form.Item>
                                      </li>
                                      <li>
                                        <Form.Item className="mb-2">
                                          <label htmlFor="custom_field_name">
                                            Custom Field Name
                                          </label>
                                          <AutoComplete
                                            className="search-withicon"
                                            dropdownClassName="search-feild-drop"
                                            dropdownMatchSelectWidth={false}
                                            dropdownStyle={{ width: 300 }}
                                            size="large"
                                            style={{ width: "100%" }}
                                            dataSource={options}
                                            onSearch={onSearch}
                                            defaultValue="Acetate Window"
                                          >
                                            <Input.Search
                                              className="ant-input-xl"
                                              placeholder=""
                                              enterButton
                                            />
                                          </AutoComplete>
                                        </Form.Item>
                                      </li>
                                      <li>
                                        <Button
                                          className="addfield-btn"
                                          type="link"
                                          icon={
                                            <SvgIcon
                                              name="plus-circle"
                                              viewbox="0 0 19 19"
                                            />
                                          }
                                        >
                                          ADD SUB-SUB-CATEGORY
                                        </Button>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <Button
                                      className="addfield-btn"
                                      type="link"
                                      icon={
                                        <SvgIcon
                                          name="plus-circle"
                                          viewbox="0 0 19 19"
                                        />
                                      }
                                    >
                                      ADD SUB-CATEGORY
                                    </Button>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <Button
                                  className="addfield-btn"
                                  type="link"
                                  icon={
                                    <SvgIcon
                                      name="plus-circle"
                                      viewbox="0 0 19 19"
                                    />
                                  }
                                >
                                  ADD CATEGORY
                                </Button>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="card-box">
                      <div className="card-header">Inventory</div>
                      <div className="card-body">
                        <Row className="mt-3">
                          <Col>
                            <Form.Item className="mb-2">
                              <label htmlFor="lot_size"> Lot size </label>
                              <Input
                                id="lot_size"
                                className="ant-input-xl"
                                defaultValue="25"
                                placeholder=""
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item className="mb-2">
                              <label htmlFor="current_inventory">
                                {" "}
                                Current Inventory{" "}
                              </label>
                              <Input
                                id="current_inventory"
                                className="ant-input-xl"
                                suffix={
                                  <SvgIcon
                                    name="lock"
                                    viewbox="0 0 22.996 30.661"
                                  />
                                }
                                disabled
                                defaultValue="1,025,000"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Form.Item className="mb-2">
                              <label htmlFor="low_stock_level">
                                Low Stock Level
                              </label>
                              <Input
                                id="low_stock_level"
                                defaultValue="5,000"
                                className="ant-input-xl"
                                placeholder=""
                              />
                            </Form.Item>
                          </Col>
                          <Col></Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="productfooter-action">
              <Button danger className="mr-3">
                Discard Changes
              </Button>
              <Button type="primary" size="middle">
                Save & Publish
              </Button>
            </div>
          </Form>
        </React.Fragment>{" "}
      </Spin>
    );
  }
}
const loadingSelector = createLoadingSelector([
  serverApis.getProductDetails.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  productDetails: state.productDetails,
});

export default connect(mapStateToProps, {})(CategoriesView);
