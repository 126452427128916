import React, { Component } from "react";
import { AutoComplete, Input, Spin, Button, Table } from "antd";
import { Row, Col, SvgIcon, ImageLoader } from "../common";

import ProductCard from "./product-card/product-card";
import { connect } from "react-redux";
import "./product-list.scss";

import debounce from "lodash/debounce";

// product images

import {
  createLoadingSelector,
  resetStoreState,
  getSearchProducts,
  getSearchProductsListAutocomplete,
} from "../../redux/action";
import { serverApis } from "../../constants/server-apis";
import { isObjectNotEmpty } from "../../common/utils";
import { baseServerAdress } from "../../constants/server-address";
import { showAlert } from "../../common/alert-messages";

class ProductListView extends Component {
  state = {
    productCode: "",
    gridView: true,
  };

  onSelect = (value) => {
    this.setState({ productCode: value });
    this.props.getSearchProducts(value);
  };
  componentWillUnmount() {
    this.props.resetStoreState(serverApis.getSearchProducts.actionName);
    this.props.resetStoreState(
      serverApis.getSearchProductsListAutocomplete.actionName
    );
  }

  componentDidUpdate() {
    if (
      this.state.productCode.length < 3 &&
      isObjectNotEmpty(this.props.searchProductsListAutocomplete)
    ) {
      this.props.resetStoreState(
        serverApis.getSearchProductsListAutocomplete.actionName
      );
    }
  }

  handleMatchedSearch = (value, event) => {
    this.setState({ productCode: value });
    if (isObjectNotEmpty(value) && value.length > 2) {
      this.onSelect(value);
    } else {
      showAlert.error("Input atleast first 3 characters");
    }
  };

  handleSearch = (value) => {
    this.props.resetStoreState(serverApis.getSearchProducts.actionName);
    this.props.resetStoreState(
      serverApis.getSearchProductsListAutocomplete.actionName
    );
    this.setState({ productCode: value }, () => {
      if (isObjectNotEmpty(value) && value.length > 2) {
        this.debounced(value);
      }
    });
  };

  debounced = debounce(
    (value) => this.props.getSearchProductsListAutocomplete(value),
    750
  );

  viewToggleTrue = () => {
    this.setState({
      gridView: true,
    });
  };
  viewToggleFalse = () => {
    this.setState({
      gridView: false,
    });
  };
  render() {
    const data = this.props.searchProducts;
    const columns = [
      {
        title: "Image",
        dataIndex: "image_thumnail",
        key: "image_thumnail",
        className: "image",
        width: 80,
        render: (image_thumnail) => {
          return (
            <ImageLoader
              alt={image_thumnail}
              title={image_thumnail}
              src={baseServerAdress.ITEM_IMAGE + image_thumnail}
              className=""
            />
          );
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 250,
      },
      {
        title: "Product Code",
        dataIndex: "web_code",
        key: "web_code",
        width: 150,
      },
    ];

    const options = this.props.searchProductsListAutocomplete.map((product) => {
      return { value: product.web_code };
    });
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <React.Fragment>
          <div className="right-wrapper">
            <div className="right-wrapper-inner">
              <div className="card-box product-listing">
                <div className="card-body">
                  <div className="listing-search">
                    <AutoComplete
                      className="productauto-search"
                      style={{
                        width: "100%",
                      }}
                      value={this.state.productCode}
                      options={
                        this.props.isFetchingDataSuggestion ? [] : options
                      }
                      onSelect={this.onSelect}
                      onSearch={this.handleSearch}
                      notFoundContent={
                        this.props.isFetchingDataSuggestion ? <Spin /> : ""
                      }
                    >
                      <Input.Search
                        placeholder="Input first 3 characters"
                        onSearch={this.handleMatchedSearch}
                        size="large"
                        // prefix={
                        //   <SvgIcon
                        //     onclick={this.handleMatchedSearch}
                        //     name="search"
                        //     viewbox="0 0 16 16"
                        //   />
                        // }
                      />
                    </AutoComplete>
                  </div>
                  <Row>
                    <Col>
                      <div className="view-switch">
                        <Button
                          className={this.state.gridView ? "active" : ""}
                          onClick={this.viewToggleTrue}
                        >
                          <SvgIcon name="grid-view" viewbox="0 0 12 12" />
                        </Button>
                        <Button
                          className={!this.state.gridView ? "active" : ""}
                          onClick={this.viewToggleFalse}
                        >
                          <SvgIcon
                            name="list-view"
                            width="20px"
                            viewbox="0 0 15 12"
                          />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {this.state.gridView ? (
                    <div className="productgrid-table-wrapper">
                      <Row className="product-listing-row">
                        {isObjectNotEmpty(this.props.searchProducts)
                          ? this.props.searchProducts.map((product, i) => {
                              return (
                                <ProductCard
                                  key={i}
                                  productId={product.id}
                                  prdoductImg={
                                    baseServerAdress.ITEM_IMAGE +
                                    product.image_thumnail
                                  }
                                  prdoductName={product.name}
                                  productCode={product.web_code}
                                />
                              );
                            })
                          : ""}{" "}
                      </Row>
                    </div>
                  ) : (
                    <div className="productlist-table-wrapper">
                      <Row>
                        <Col className="productlist-table">
                          <Table
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            scroll={{ y: 240 }}
                            rowKey="id"
                            onRow={(record) => ({
                              onClick: () => {
                                this.props.history.push({
                                  pathname: serverApis.getSearchProducts.componentPath(
                                    record.id
                                  ),
                                });
                              },
                            })}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Spin>
    );
  }
}
const loadingSelector = createLoadingSelector([
  serverApis.getSearchProducts.actionName,
]);
const loadingSelectorSuggestion = createLoadingSelector([
  serverApis.getSearchProductsListAutocomplete.actionName,
]);
const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  isFetchingDataSuggestion: loadingSelectorSuggestion(state),

  searchProducts: state.searchProducts,
  searchProductsListAutocomplete: state.searchProductsListAutocomplete,
});

export default connect(mapStateToProps, {
  getSearchProductsListAutocomplete,
  resetStoreState,
  getSearchProducts,
})(ProductListView);
