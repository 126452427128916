import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { defaultLayout, authLayout } from "./layouts";

// Route Views
import LoginView from "./components/authentication/login/login";
import CollectionView from "./components/collections/collection";
import ProductBasicInformationView from "./components/product-info/basic-information/basic-information";
import CustomFieldsView from "./components/product-info/custom-fields/custom-fields";
import CategoriesView from "./components/product-info/categories/categories";
import HomePageView from "./components/homepage/homepage";
import FooterView from "./components/footer/footer";
import { serverApis } from "./constants/server-apis";
import ProductListView from "./components/product-list/product-list";
import UnsupportedBrowserView from "./components/unsupported-browser/unsupported-browser";
import HeaderView from "./components/header/header";
import ItemPricingView from "./components/product-info/item-pricing/item-pricing";
import ManageVariantsView from "./components/product-info/manage-variants/manage-variants";
import ImprintOptionsView from "./components/product-info/imprint-options/imprint-options";

export const publicRoutes = [
  {
    path: serverApis.login.componentPath,
    exact: true,
    layout: authLayout,
    component: LoginView,
  },
  {
    path: "/",
    exact: true,
    layout: authLayout,
    component: () => <Redirect to={serverApis.login.componentPath} />,
  },
];

export const privateRoutes = [
  {
    path: serverApis.rootCollections.componentPath,
    layout: defaultLayout,
    component: CollectionView,
  },
  {
    path: serverApis.getSearchProducts.componentPath(),
    layout: defaultLayout,
    component: ProductBasicInformationView,
  },
  {
    path: serverApis.productCustomFields.componentPath,
    layout: defaultLayout,
    component: CustomFieldsView,
  },
  {
    path: serverApis.productCategoryInfo.componentPath,
    layout: defaultLayout,
    component: CategoriesView,
  },
  {
    path: "/homepage",
    layout: defaultLayout,
    component: HomePageView,
  },
  {
    path: serverApis.getFooterDetails.componentPath,
    layout: defaultLayout,
    component: FooterView,
  },
  {
    path: serverApis.getHeaderDetails.componentPath,
    layout: defaultLayout,
    component: HeaderView,
  },
  {
    path: "/unsupported-browser",
    layout: authLayout,
    component: UnsupportedBrowserView,
  },
  {
    path: "/product-list",
    layout: defaultLayout,
    component: ProductListView,
  },
  {
    path: "/item-pricing",
    layout: defaultLayout,
    component: ItemPricingView,
  },
  {
    path: "/manage-variants",
    layout: defaultLayout,
    component: ManageVariantsView,
  },
  {
    path: "/imprint-options",
    layout: defaultLayout,
    component: ImprintOptionsView,
  },
  {
    path: "/custom-fields",
    layout: defaultLayout,
    component: CustomFieldsView,
  },
];
