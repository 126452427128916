import {
  LOGIN_TOKEN_KEY,
  USER_DETAIL_KEY,
  requestContentType,
} from "../constants";
import { buildType } from "../constants/build-types";

export const isFlagTrueY = (flag) => {
  return flag === "Y";
};

export const flagValueForBool = (bool) => {
  return bool ? "Y" : "N";
};

export const clearLoginData = () => {
  localStorage.removeItem(LOGIN_TOKEN_KEY);
  localStorage.removeItem(USER_DETAIL_KEY);
};

export const isOpenPath = (routerPath) => {
  switch (routerPath) {
    // case componentPaths.registration:
    case "componentPaths.USER_ACTIVATION":
      return true;

    default:
      return false;
  }
};

export const isUserLoggedIn = () => {
  if (localStorage.getItem(LOGIN_TOKEN_KEY)) return true;
  else return false;
};

export const saveLoginToken = (loginToken) => {
  localStorage.setItem(LOGIN_TOKEN_KEY, loginToken);
};

export const saveLoggedInUserDetails = (userDetails) => {
  localStorage.setItem(USER_DETAIL_KEY, JSON.stringify(userDetails));
};

export const getLoginToken = () => {
  return localStorage.getItem(LOGIN_TOKEN_KEY);
};

export const loggedInUserDetail = () => {
  return JSON.parse(localStorage.getItem(USER_DETAIL_KEY));
};

export const isBuildTypeDemo = () => {
  return process.env.REACT_APP_BUILD_TYPE === buildType.DEMO;
};

export const isLiveMode = () => {
  if (
    process.env.REACT_APP_LIVE_URL ===
    window.location.origin.replace("https://", "")
  ) {
    return true;
  } else if (
    process.env.REACT_APP_LIVE_URL ===
    window.location.origin.replace("http://", "")
  ) {
    return true;
  } else {
    return false;
  }
};

export const getSchemaName = () => {
  return isLiveMode()
    ? process.env.REACT_APP_SCHEMA_LIVE
    : process.env.REACT_APP_SCHEMA_TEST;
};

export const getBaseUrl = () => {
  return isLiveMode()
    ? process.env.REACT_APP_BASE_URL_LIVE
    : process.env.REACT_APP_BASE_URL_TEST;
};
export const getWebsiteUrl = () => {
  return isLiveMode()
    ? process.env.REACT_APP_WEBSITE_URL_LIVE
    : process.env.REACT_APP_WEBSITE_URL_TEST;
};

export const getImageBaseUrl = () => {
  return isLiveMode()
    ? process.env.REACT_APP_IMAGE_BASE_URL_LIVE
    : process.env.REACT_APP_IMAGE_BASE_URL_TEST;
};

export const getJsonRequestHeader = () => {
  return {
    Accept: requestContentType.APPLICATION_JSON,
    "Content-Type": requestContentType.APPLICATION_JSON,
  };
};

export const getJsonRequestHeaderWithAuthentication = () => {
  return {
    ...getJsonRequestHeader(),
    Authorization: `Bearer  ${getLoginToken()}`,
  };
};

export const getRequestHeaderFormData = () => {
  return {
    Authorization: `Bearer  ${getLoginToken()}`,
  };
};
