import React, { Component } from "react";
import { Form, Table, Input, Checkbox } from "antd";
import { SvgIcon, Row, Col } from "../../common";
import "./item-pricing.scss";
import { isObjectEmpty, isObjectNotEmpty } from "../../../common/utils";

class ItemPricingView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: "normal",
      dataSource: [],
      is_rush_service: this.props.fieldValue.is_rush_service,
      editedRowState: [],
    };
  }

  columns = (tableIndex) => {
    return [
      {
        title: "",
        dataIndex: "labels",
        key: "labels",
        fixed: "left",
        width: 120,
      },
      {
        title: "",
        dataIndex: "labelsBlank",
        key: "labelsBlank",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="labelsBlank"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //         onBlur={this.onBlurEditedField}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 1",
        dataIndex: "tier_1",
        key: "tier_1",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_1"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 2",
        dataIndex: "tier_2",
        key: "tier_2",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_2"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 3",
        dataIndex: "tier_3",
        key: "tier_3",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_3"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 4",
        dataIndex: "tier_4",
        key: "tier_4",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_4"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 5",
        dataIndex: "tier_5",
        key: "tier_5",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_5"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 6",
        dataIndex: "tier_6",
        key: "tier_6",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_6"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 7",
        dataIndex: "tier_7",
        key: "tier_7",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_7"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 8",
        dataIndex: "tier_8",
        key: "tier_8",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_8"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 9",
        dataIndex: "tier_9",
        key: "tier_9",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_9"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 10",
        dataIndex: "tier_10",
        key: "tier_10",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_10"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 11",
        dataIndex: "tier_11",
        key: "tier_11",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_11"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 12",
        dataIndex: "tier_12",
        key: "tier_12",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_12"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 13",
        dataIndex: "tier_13",
        key: "tier_13",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_13"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 14",
        dataIndex: "tier_14",
        key: "tier_14",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_14"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Tier 15",
        dataIndex: "tier_15",
        key: "tier_15 ",
        // render: (text, row, index) => {
        //   if (row.is_editable) {
        //     return (
        //       <Input
        //         id={row.key}
        //         name="tier_15"
        //         value={text}
        //         onChange={(e) => this.onChangeTextField(e, row, tableIndex)}
        //       />
        //     );
        //   } else {
        //     return text;
        //   }
        // },
      },
    ];
  };
  onChangeTextField = (event, row, tableIndex) => {
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value;
    let dataSource = this.state.dataSource;
    let filteredArray = dataSource.filter((_, i) => i === tableIndex);
    let editedRow = filteredArray[0].price_details.filter(
      (order) => order === row
    );
    editedRow[0][name] = value;
    this.props.requestObjectForItemPricing(this.props.apiKey, dataSource);
    this.setState({ dataSource });
  };
  handleScroll = () => {
    if (document.getElementById("root").scrollTop > 13) {
      this.setState({ activeClass: "fixTop" });
    } else {
      this.setState({ activeClass: "normal" });
    }
  };

  componentDidMount() {
    if (this.props.fieldValue.pricing && isObjectEmpty(this.state.dataSource)) {
      this.setState({
        dataSource: this.props.fieldValue.pricing,
      });
    }
    document
      .getElementById("root")
      .addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document
      .getElementById("root")
      .removeEventListener("scroll", this.handleScroll);
  }
  onChangeCheckbox = (e) => {
    this.props.requestObjectForItemPricing("is_rush_service", e.target.checked);
    this.setState({
      is_rush_service: e.target.checked,
    });
  };
  onChangeExtendedTier = (e, key) => {
    this.props.onChangeExtendedTier(key, e.target.value);
  };
  fieldView = () => {
    if (this.props.fieldValue.pricing) {
      return (
        <React.Fragment>
          <Row className="mt-4 item-pricing-row">
            {isObjectNotEmpty(this.props.fieldValue.pricing)
              ? this.state.dataSource.map((item, i) => {
                  return (
                    <Col key={i} className="pt-2 pb-4 px-4" sm="12">
                      <h4 className="">
                        {/* <Switch className="mt-n1 mr-1" defaultChecked /> */}
                        {item.title}
                      </h4>
                      <div className="w-100">
                        <Table
                          className="item-pricing-table"
                          columns={this.columns(i)}
                          dataSource={item.price_details}
                          bordered={true}
                          pagination={false}
                          scroll={{ x: "100%" }}
                        />
                      </div>
                    </Col>
                  );
                })
              : ""}
          </Row>
          <Row style={{ paddingLeft: 80 }} className="item-pricing-bottom">
            <Col md="2">
              <Form.Item className="mb-2">
                <label htmlFor="extended_tier">Extended Tier</label>
                <Input
                  className="ant-input-xl"
                  id="extended_tier"
                  value={
                    this.props.productDetailsRequestObj["extended_tier"] ||
                    this.props.productDetailsRequestObj["extended_tier"] === ""
                      ? this.props.productDetailsRequestObj["extended_tier"]
                      : this.props.fieldValue.extended_tier
                  }
                  onChange={(e) => {
                    this.onChangeExtendedTier(e, "extended_tier");
                  }}
                />
              </Form.Item>
            </Col>
            <Col md="2">
              <Form.Item className="mb-2">
                <label htmlFor="eqp_tier">EQP Tier</label>
                <Input
                  className="ant-input-xl"
                  id="eqp_tier"
                  value={this.props.fieldValue.eqp_tier}
                  suffix={<SvgIcon name="lock" viewbox="0 0 22.996 30.661" />}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col md="2">
              <Form.Item className="mb-2">
                <label htmlFor="nqp_tier">NQP Tier</label>
                <Input
                  className="ant-input-xl"
                  id="nqp_tier"
                  value={this.props.fieldValue.nqp_tier}
                  suffix={<SvgIcon name="lock" viewbox="0 0 22.996 30.661" />}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col md="6">
              <Checkbox
                disabled
                checked={this.props.fieldValue.eqp_qulified}
                className="mr-4 ml-2"
              >
                EQP Qualified ?
              </Checkbox>
              <Checkbox disabled checked={this.props.fieldValue.nqp_qualified}>
                NQP Qualified ?
              </Checkbox>
              <Checkbox
                disabled
                value={this.state.is_rush_service}
                checked={this.props.fieldValue.is_rush_service}
                onChange={this.onChangeCheckbox}
                className="mr-4 ml-2"
              >
                Rush Service
              </Checkbox>
            </Col>
          </Row>{" "}
        </React.Fragment>
      );
    } else {
      return (
        <Row className="mt-4 item-pricing-row">
          {this.props.fieldValue.map((item, index) => {
            return (
              <Col className="pt-2 pb-4 px-4" sm="12">
                <h4 className="">{item.title}</h4>
                <div className="w-100">
                  <Table
                    className="item-pricing-table"
                    columns={this.columns(index)}
                    dataSource={item.price_details}
                    bordered={true}
                    pagination={false}
                    scroll={{ x: "100%" }}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      );
    }
  };

  render() {
    return <React.Fragment>{this.fieldView()}</React.Fragment>;
  }
}

export default ItemPricingView;
