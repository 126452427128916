import React, { Component } from "react";
import { Modal, Button, Tooltip } from "antd";
import { SvgIcon } from "../../../components/common";

import "./delete-collection.scss";

class DeleteCollectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showModal = (event) => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
    this.props.deleteCollection();
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Tooltip
          overlayClassName="tooltip-cards"
          placement="bottomLeft"
          title="Delete Collection"
        >
          <span
            key="delete"
            onClick={(event) => {
              event.stopPropagation();
              this.showModal();
            }}
          >
            <SvgIcon name="trash" width="14" viewbox="0 0 14.286 18.095" />
          </span>
        </Tooltip>
        <Modal
          visible={visible}
          title="Delete Collection"
          onOk={(event) => {
            event.stopPropagation();
            this.handleOk();
          }}
          onCancel={(event) => {
            event.stopPropagation();
            this.handleCancel();
          }}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <Button
              key="back"
              className="px-4"
              onClick={(event) => {
                event.stopPropagation();
                this.handleCancel();
              }}
            >
              No
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="px-5"
              onClick={(event) => {
                event.stopPropagation();
                this.handleOk();
              }}
            >
              Yes
            </Button>,
          ]}
          className="modal-cms delete-collectionModal"
        >
          <div
            className="delete-collection-modal"
            onClick={(event) => event.stopPropagation()}
          >
            <SvgIcon
              className="closeicon mt-3"
              name="close-circle"
              viewbox="0 0 64 64"
            />
            <p className="mt-4 mb-2">
              Are you sure you want to delete the collection{" "}
              <b>{this.props.collectionName}</b> ?<br />
              This action cannot be undone
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DeleteCollectionModal;
