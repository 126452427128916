import React, { Component } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";

import "./Menu.scss";

class LeftMenu extends Component {
  render() {
    return (
      <div className="left-menu">
        <Menu className="left-menu-inner" defaultSelectedKeys={["2"]}>
          <Menu.Item key="1">Dashboard</Menu.Item>
          <Menu.Item key="2">
            {" "}
            <NavLink activeClassName="active" to="/collections">
              Categories
            </NavLink>{" "}
          </Menu.Item>
          <Menu.Item key="3">
            {" "}
            <NavLink
              activeClassName="active"
              to="/product-list"
              id="product_info_link"
            >
              Product Info{" "}
            </NavLink>{" "}
          </Menu.Item>
          <Menu.Item key="4">
            {" "}
            <NavLink activeClassName="active" to="/homepage">
              Home Page
            </NavLink>{" "}
          </Menu.Item>
          <Menu.Item key="6">
            {" "}
            <NavLink activeClassName="active" to="/header">
              Header
            </NavLink>{" "}
          </Menu.Item>
          <Menu.Item key="7">
            {" "}
            <NavLink activeClassName="active" to="/footer">
              Footer
            </NavLink>{" "}
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default LeftMenu;
