import React, { Component } from "react";
import { Form, Input } from "antd";
import "./imprint-options.scss";
import { Row, Col, SvgIcon } from "../../common";
import {
  getObjectDeepCopy,
  isObjectEmpty,
  isObjectNotEmpty,
} from "../../../common/utils";
import { connect } from "react-redux";
import {
  createLoadingSelector,
  lookupOptions,
  resetStoreState,
} from "../../../redux/action";
import { serverApis } from "../../../constants/server-apis";
import { showAlert } from "../../../common/alert-messages";
import confirm from "antd/lib/modal/confirm";

//const { Option } = Select;

class ImprintOptionsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imprintLocationValue: "",
    };
  }

  getImprintOptionsArry = () => {
    if (this.props.productDetailsRequestObj.hasOwnProperty(this.props.apiKey)) {
      return this.props.productDetailsRequestObj[this.props.apiKey];
    } else {
      return getObjectDeepCopy(this.props.fieldValues.value);
    }
  };

  onClickImprintLocation = () => {
    this.props.lookupOptions({ type: 4 });
  };
  handleInputChange = (event, index) => {
    let value = event.target.value;
    let name = event.target.getAttribute("name"); //field name
    let dataSource = this.getImprintOptionsArry();
    dataSource[index][name] = value;
    this.props.requestObjForImprintLocation(this.props.apiKey, dataSource);
    this.setState({
      dataSource,
    });
  };
  onselectImprintLocation = (value, index, option) => {
    let dataSource = this.getImprintOptionsArry();
    let matchedImprintLocation = dataSource.filter(
      (order) => order.imprint_location === value
    );

    if (isObjectEmpty(matchedImprintLocation)) {
      dataSource[index]["imprint_location"] = value;
      dataSource[index]["id"] = option.id;
    } else {
      showAlert.error(
        "Imprint location already exists, select a different imprint location."
      );
    }
    this.props.requestObjForImprintLocation(this.props.apiKey, dataSource);
  };
  handleAdd = () => {
    let dataSource = this.getImprintOptionsArry();
    dataSource.push({
      imprint_area: "",
      imprint_location: "",
      imprint_size: "",
      max_print_colors: "",
      title: `Imprint Location ${this.state.dataSource.length + 1}`,
    });
    this.props.requestObjForImprintLocation(this.props.apiKey, dataSource);
  };
  handleDelete = (index) => {
    let dataSource = this.getImprintOptionsArry();
    confirm({
      title: "Do you really want to Delete ?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        dataSource.splice(index, 1);
        this.props.requestObjForImprintLocation(this.props.apiKey, dataSource);
      },
    });
  };

  render() {
    const dataSource = this.getImprintOptionsArry();
    return (
      <React.Fragment>
        {isObjectNotEmpty(dataSource)
          ? dataSource.map((field, index) => {
              return (
                <Col key={index} xs="12">
                  <Row className="mt-3">
                    <Col>
                      <h3 className="infosub-heading">{field.title}</h3>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col xs="6" className="mt-3">
                      <Form.Item className="mb-2">
                        <label htmlFor="imprint_location">
                          {" "}
                          Imprint Location
                        </label>
                        <Input
                          value={field.imprint_location}
                          id="imprint_location"
                          className="ant-input-xl"
                          suffix={
                            <SvgIcon name="lock" viewbox="0 0 22.996 30.661" />
                          }
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col xs="6" className="mt-3">
                      <Form.Item className="mb-2">
                        <label htmlFor="imprint_area"> Imprint area</label>
                        <Input
                          onChange={(e) => {
                            this.handleInputChange(e, index);
                          }}
                          name="imprint_area"
                          value={field.imprint_area}
                          className="ant-input-xl"
                          // suffix={
                          //   <SvgIcon name="lock" viewbox="0 0 22.996 30.661" />
                          // }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs="6" className="mt-3">
                      <Form.Item className="mb-2">
                        <label htmlFor="imprint_size"> Imprint Size</label>
                        <Input
                          onChange={(e) => {
                            this.handleInputChange(e, index);
                          }}
                          value={field.imprint_size}
                          name="imprint_size"
                          className="ant-input-xl"
                          // suffix={
                          //   <SvgIcon name="lock" viewbox="0 0 22.996 30.661" />
                          // }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs="6" className="mt-3">
                      <Form.Item className="mb-2">
                        <label htmlFor="max_print_color">
                          {" "}
                          Max print color
                        </label>
                        <Input
                          value={field.max_print_colors}
                          id="max_print_color"
                          className="ant-input-xl"
                          suffix={
                            <SvgIcon name="lock" viewbox="0 0 22.996 30.661" />
                          }
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>{" "}
                </Col>
              );
            })
          : ""}
      </React.Fragment>
    );
  }
}

const loadingSelectorSuggestion = createLoadingSelector([
  serverApis.lookupOptions.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingDataSuggestion: loadingSelectorSuggestion(state),
  lookupOptionsDetails: state.lookupOptionsDetails,
});

export default connect(mapStateToProps, {
  resetStoreState,
  lookupOptions,
})(ImprintOptionsView);
