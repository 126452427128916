import React from "react";

//Footer Style
import './Footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">        
                
      </footer>
    );
  }
}

export default Footer;