import React, { Component } from "react";
import { Collapse, Switch, Button, List, Modal } from "antd";
import { sortableHandle } from "react-sortable-hoc";
import { SvgIcon, Row, Col, ImageLoader } from "../../common";
import ReactDragListView from "react-drag-listview";
import "./collections.scss";

import AddHomepageCollection from "./add-homepage-collection/add-homepage-collection";
import { baseServerAdress } from "../../../constants/server-address";
import { connect } from "react-redux";
import {
  deleteHomeCollection,
  getHomePageLists,
  resetStoreState,
  activateInActivateSection,
  saveHomeCollectionSequence,
} from "../../../redux/action";
import confirm from "antd/lib/modal/confirm";

const { Panel } = Collapse;
const DragHandle = sortableHandle(() => (
  <SvgIcon className="move-icon-card" name="move-dotes" viewbox="0 0 20 32" />
));

const panelHeader = (
  disableButton,
  activateInActivateSection,
  sectionDetails,
  saveSequence
) => (
  <React.Fragment>
    <React.Fragment>
      <div className="panel-header">
        <div className="title-left">
          <DragHandle /> Collection
        </div>
        <div
          className="title-switch"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Switch
            className="switch-cms"
            defaultChecked
            size="large"
            onChange={(checked) => {
              let requestObject = {
                trans_flag: checked ? "A" : "D",
                id: sectionDetails.id,
              };
              activateInActivateSection(requestObject);
            }}
            checked={sectionDetails.trans_flag === "A"}
          />
        </div>
        <div
          className="save-btn"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Button
            size="small"
            className="btn-success btn-md radius px-3"
            disabled={disableButton}
            onClick={saveSequence}
          >
            Save
          </Button>
        </div>
      </div>
    </React.Fragment>
  </React.Fragment>
);

class HomeCollection extends Component {
  state = {
    data: this.props.contentList,
    unUsedData: this.props.contentList,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.contentList !== state.unUsedData) {
      return { data: props.contentList, unUsedData: props.contentList };
    }
    return null;
  }

  onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area

    const items = [...this.state.data];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    this.setState({ data: items });
    this.props.setSectionsSequenceChanged(true);
  };

  onChangeCollapse = (value) => {
    this.props.onChangeCollapse(value);
  };

  handleOk = () => {
    this.props.setSectionsSequenceChanged(false);
    this.saveSequence();
  };

  handleCancel = () => {
    this.props.setShowSectionSequenceAlert(false);
  };

  resetSequence = () => {
    this.setState({
      data: this.props.contentList,
    });
    this.props.setShowSectionSequenceAlert(false);
    this.props.setSectionsSequenceChanged(false);
  };

  saveSequence = () => {
    let items = this.state.data.map((item) => {
      return { id: item.id };
    });
    this.props.saveHomeCollectionSequence(items);
  };
  handleDeleteHomeCollection = (id) => {
    confirm({
      title: "Do you really want to Delete?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        this.props.deleteHomeCollection(id);
      },
    });
  };
  render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        <Modal
          className="modal-cms alert-modal"
          visible={
            this.props.showSectionSequenceAlert &&
            this.props.activeKeys.toString() === ["3"].toString()
          }
          title="Alert"
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <Button key="back" size="small" onClick={this.resetSequence}>
              No
            </Button>,
            <Button
              key="submit1"
              size="small"
              type="primary"
              loading={loading}
              onClick={this.handleOk}
            >
              Yes
            </Button>,
            <Button
              key="submit2"
              size="small"
              type="primary"
              onClick={this.handleCancel}
            >
              keep editing
            </Button>,
          ]}
        >
          <p className="alret-modal-text">
            <SvgIcon
              className="mb-n1 mr-1"
              name="info-icon"
              viewbox="0 0 35.446 35.446"
            />{" "}
            Would you like to save your changes ?
          </p>
        </Modal>
        <Collapse
          accordion
          expandIconPosition="right"
          activeKey={this.props.activeKeys}
          onChange={this.onChangeCollapse}
        >
          <Panel
            header={panelHeader(
              !this.props.isSectionsSequenceChanged,
              this.props.activateInActivateSection,
              this.props.sectionDetails,
              this.saveSequence
            )}
            key="3"
          >
            <Row className="mt-2">
              <Col sm="12">
                <ReactDragListView
                  nodeSelector=".ant-list-item.draggble"
                  onDragEnd={this.onDragEnd}
                >
                  <List
                    size="small"
                    bordered
                    dataSource={this.state.data}
                    className="card-listslider-banner"
                    renderItem={(item) => {
                      const draggble = item !== " ";
                      return (
                        <List.Item className={draggble ? "draggble" : ""}>
                          <div className="collection-card cards-list">
                            <div className="remove-card">
                              <div
                                className="close-icon"
                                onClick={() =>
                                  this.handleDeleteHomeCollection(item.id)
                                }
                              >
                                <SvgIcon
                                  name="close"
                                  viewbox="0 0 298.667 298.667"
                                />
                              </div>
                            </div>
                            <div className="collection-cardinner">
                              <div className="image-thumb">
                                <div className="imagethumb-inner">
                                  <ImageLoader
                                    alt={"collectionimage"}
                                    src={
                                      baseServerAdress.COLLECTION_IMAGE +
                                      item.thumb_image +
                                      `?${Date()}`
                                    }
                                  />
                                </div>
                              </div>
                              <div className="right">
                                <h3>{item.name}</h3>
                                <p>
                                  {" "}
                                  {(item.collection_items === null
                                    ? 0
                                    : item.collection_items) +
                                    ` Item${
                                      item.collection_items === 1 ? "" : "s"
                                    }, ` +
                                    (item.collection_childs === null
                                      ? 0
                                      : item.collection_childs) +
                                    ` Collection${
                                      item.collection_childs === 1 ? "" : "s"
                                    }`}
                                </p>
                              </div>
                            </div>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </ReactDragListView>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <AddHomepageCollection
                  selectedHomeCollections={this.props.contentList}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  resetStoreState,
  deleteHomeCollection,
  getHomePageLists,
  activateInActivateSection,
  saveHomeCollectionSequence,
})(HomeCollection);
