import React, { Component } from "react";
import { Collapse, Switch, Button, Form, Input } from "antd";
import { SvgIcon, Row, Col, RecommendedSize } from "../../common";
import { sortableHandle } from "react-sortable-hoc";

import "./subscription.scss";

import SubscriptionImg from "../../../assets/images/subscription.png";

const { Panel } = Collapse;
const DragHandle = sortableHandle(() => (
  <SvgIcon className="move-icon-card" name="move-dotes" viewbox="0 0 20 32" />
));

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const panelHeader = (
  <React.Fragment>
    <React.Fragment>
      <div className="panel-header">
        <div className="title-left d-flex align-items-center">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DragHandle />
          </div>
          Subscription
        </div>
        <div
          className="title-switch"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Switch
            className="switch-cms"
            defaultChecked
            size="large"
            onChange={onChange}
          />
        </div>
        <div
          className="save-btn"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Button size="small" className="btn-success btn-md radius px-3">
            Save
          </Button>
        </div>
      </div>
    </React.Fragment>
  </React.Fragment>
);

class SubscriptionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: SubscriptionImg,
    };
    this.onChangeImage = this.onChangeImage.bind(this);
  }
  onChangeImage(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }

  render() {
    return (
      <React.Fragment>
        <Collapse
          accordion
          expandIconPosition="right"
          activeKey={this.props.activeKeys}
          onChange={this.props.onChangeCollapse}
        >
          <Panel header={panelHeader} key="4">
            <Row>
              <Col sm="6">
                <div className="position-relative">
                  {this.state.file && (
                    <div className="uploadedthumbimg-wrape">
                      <Button
                        title="Edit Static Banner"
                        type="primary"
                        size="small"
                        className="image-edit-button live-edit"
                      >
                        <input type="file" onChange={this.onChangeImage} />
                        <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />
                      </Button>
                      {/* <img className="uploadedthumbimg-big" src={this.state.file} /> */}
                      <img
                        className="uploadedthumbimg-big w-100"
                        src={this.state.file}
                        alt={this.state.file}
                      />
                    </div>
                  )}
                </div>
                <RecommendedSize width='470' height='254' />
              </Col>
              <Col sm="6">
                <Form layout="vertical">
                  <Form.Item className="mb-3" id="title_text">
                    <label>Title text</label>
                    <Input
                      size="large"
                      placeholder="Title text"
                      defaultValue="GET NOTIFICATIONS TO STAY UPDATED"
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default SubscriptionCard;
