export const getDateSeperatedByT = (dateString) => {
  if (dateString) {
    let arry = dateString.split("T");
    if (arry.length > 0) return arry[0];
  } else return "";
};

export const dataFormatter = (dateString) => {
  var d = dateString.slice(0, 10).split("-");
  return d[1] + "-" + d[2] + "-" + d[0];
};

export const isObjectNotEmpty = (obj) => {
  return !isObjectEmpty(obj);
};

export const isObjectEmpty = (obj) => {
  var hasOwnProperty = Object.prototype.hasOwnProperty;
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
};

export const getObjectValue = (obj, property) => {
  return isObjectEmpty(obj) ? "" : obj[property];
};

export const getObjectDeepCopy = (object) => {
  let newObject = JSON.stringify(object);
  return JSON.parse(newObject);
};

export const isValueAnInteger = (value) => {
  if (value > 0) {
    return true;
  } else {
    return false;
  }
};
