import {
  getBaseUrl,
  getImageBaseUrl,
  getSchemaName,
  getWebsiteUrl,
  isLiveMode,
} from "../common/methods";

const httpText = "https";

export const baseServerAdress = isLiveMode()
  ? {
      IMAGE: `${httpText}://${getImageBaseUrl()}/${getSchemaName()}/`,
      API: `${httpText}://${getBaseUrl()}/api/v1`,
      COLLECTION_IMAGE: `${httpText}://${getBaseUrl()}/images/${getSchemaName()}/collections/compress/`,
      ITEM_IMAGE: `${httpText}://${getBaseUrl()}/images/${getSchemaName()}/items/compress/`,
      WEBSITE_URL: `${httpText}://${getWebsiteUrl()}/`,
      TEMPLATE: `${httpText}://${getBaseUrl()}/templates/${getSchemaName()}/`,
      ITEM_VIDEO: `${httpText}://${getBaseUrl()}/videos/${getSchemaName()}/items/`,
    }
  : {
      IMAGE: `http://${getImageBaseUrl()}/${getSchemaName()}/`,
      API: `http://${getBaseUrl()}/api/v1`,
      COLLECTION_IMAGE: `http://${getBaseUrl()}/images/${getSchemaName()}/collections/compress/`,
      ITEM_IMAGE: `http://${getBaseUrl()}/images/${getSchemaName()}/items/compress/`,
      WEBSITE_URL: `http://${getWebsiteUrl()}/`,
      TEMPLATE: `http://${getBaseUrl()}/templates/${getSchemaName()}/`,
      ITEM_VIDEO: `http://${getBaseUrl()}/videos/${getSchemaName()}/items/`,
    };
